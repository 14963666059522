import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createSlots as _createSlots, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "program-block-1-inv__content content-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_program_item = _resolveComponent("program-item")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "program-block-1-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "program-block-1-inv__title" }, _ctx.computedOptions.titleStyles, { size: [60, 40] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.items, (item, itemIndex) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: itemIndex,
            class: "program-block-1-inv__item"
          }, [
            _createVNode(_component_svg_icon, {
              class: "program-block-1-inv__item-icon",
              name: item.icon,
              fill: _ctx.computedOptions.itemIconColor
            }, null, 8, ["name", "fill"]),
            _createVNode(_component_program_item, {
              align: "center",
              "time-styles": _ctx.computedOptions.itemTimeStyles,
              "title-styles": _ctx.computedOptions.itemTitleStyles,
              "address-styles": _ctx.computedOptions.itemAddressStyles
            }, _createSlots({
              time: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.time), 1)
              ]),
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, [
              (item.address)
                ? {
                    name: "address",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.address), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["time-styles", "title-styles", "address-styles"])
          ])), [
            [_directive_element_intersection_animation_directive, { tact: itemIndex + 2 }]
          ])
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive, { config: { threshold: 0.1 } }]
  ])
}