import HistoryBlockOptions from '@/data/e-invitations/block-options/history-block';

const defaultOptions: HistoryBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  textStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  periodNameStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  firstPersonAvatarImageUrls: {
    x1: 'https://i.imgur.com/UZIhaeR.jpg',
  },
  secondPersonAvatarImageUrls: {
    x1: 'https://i.imgur.com/r0cACbO.jpg',
  },
  separatorColor: 'rgba(31, 31, 31, 1)',
  titleText: 'Наша история любви',
  subtitleText: 'Вы верите в случайность встреч и любовь с первого взгляда?',
  periods: [
    {
      name: '2018',
      image: {
        desktopSize: 'full-width',
        urls: {
          x1: 'https://i.imgur.com/D0BsOE4.jpg',
        },
      },
      blocks: [
        {
          text: 'Кажется, что это обычная улица, но именно здесь всё и началось. Мы оба шли по совим делам, и тут мы случайно сталкиваемся. Слово за слово, и вот разговор нас так увлёк, что мы договорились о следующей встрече.',
          desktopWidth: '850px',
        },
        {
          text: 'А потом ещё об одной.',
        },
        {
          text: 'И ещё...',
        },
      ],
      firstPersonBlocks: [
        {
          text: 'Внезапно столкнувшись с человеком, я, как обычно, хотел просто продолжить свой путь, но, подняв голову и увидев с КЕМ я столкнулся, я просто не мог не начать разговор. В моей голове было что-то вроде “лишь бы узнать ее имя”, “лишь бы она не испугалась и поговорила со мной”.',
          desktopWidth: '840px',
        },
        {
          text: 'В итоге каким-то чудом мы оба совершенно забыли про свои дела и еще долго просто гуляли по городу и разговаривали обо всем на свете.',
          desktopWidth: '708px',
        },

        {
          text: 'Никогда и ни к кому я не испытывал такие теплые и искренние чувства, как к ней. Я понял, что она “та самая”.',
          desktopWidth: '580px',
        },
      ],
      secondPersonBlocks: [
        {
          text: 'Для меня вообще необычно с кем-то вот так познакомиться на улице. Но, увидев его и услышав его голос, у меня автоматически включилось доверие к нему, и захотелось узнать о нем как можно больше.',
          desktopWidth: '800px',
        },
        {
          text: 'Придя домой, после столь долгой прогулки с незнакомцем, который за несколько часов стал буквально самым близким человеком на свете, я сразу сказала маме, что с этим человеком я буду всю свою жизнь.',
          desktopWidth: '772px',
        },

        {
          text: 'Это никак иначе, как просто магия, или судьба. ',
          desktopWidth: '447px',
        },
      ],
    },
    {
      name: '2019',
      image: {
        desktopSize: { width: '453px', height: 'auto' },
        urls: {
          x1: 'https://i.imgur.com/vEg8qrZ.jpg',
        },
      },
      blocks: [
        {
          text: 'Наше первое совместное путешествие. Каждый наш день был наполнен приключениями, новыми эмоциями, романтичными вечерами и юношеской беспечностью. Именно в этой поездке мы полностью осознали глубину наших чувств и серьезность намерений.',
          desktopWidth: '766px',
        },
      ],
    },
    {
      name: '2020',
      image: {
        desktopSize: { width: '453px', height: 'auto' },
        urls: {
          x1: 'https://i.imgur.com/vEg8qrZ.jpg',
        },
      },
      blocks: [
        {
          text: 'Самый сложный для нас год, но в то же время и самый важный. Мы приняли решение о совместной жизни, очень сильно сблизились, узнали друг о друге и сами о себе много нового. И наша любовь вышла совсем на иной, уже полностью взрослый уровень.',
          desktopWidth: '643px',
        },
      ],
    },
    {
      name: '2021',
      image: {
        desktopSize: { width: '453px', height: 'auto' },
        urls: {
          x1: 'https://i.imgur.com/vEg8qrZ.jpg',
        },
      },
      blocks: [
        {
          text: 'Так же неожиданно, как мы встретились в 2018, мы решили соединить наши сердца узами брака.',
          desktopWidth: '564px',
        },
        {
          text: 'Мы любим друг друга, и будем любить всегда!',
          desktopWidth: '515px',
        },
      ],
    },
    {
      name: '2022',
      blocks: [
        {
          text: 'Год нашей свадьбы.',
        },
        {
          text: 'Мы будем очень рады, если Вы разделите с нами радость этого события!',
          desktopWidth: '531px',
        },
      ],
    },
  ],
};

export default defaultOptions;
