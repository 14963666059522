
import { defineComponent, PropType } from 'vue';
import PresenceBlockInterfaceOptions from '@/data/e-invitations/block-options/presence-block';
import defaultOptions from '@/data/e-invitations/block-default-options/presence-block';
import {
  Name,
  FormPublishMethods,
  FormResult,
  PRESENCE_STATUSES,
} from '@/data/e-invitations/types';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon';
import CheckboxBlock from '@/blocks/checkbox';
import ButtonBlock from '@/blocks/button';
// eslint-disable-next-line camelcase
import PresenceBlockInv__name from './presence-block-inv__name.vue';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'presence-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    SvgIcon,
    CheckboxBlock,
    ButtonBlock,
    // eslint-disable-next-line vue/no-unused-components
    PresenceBlockInv__name,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<PresenceBlockInterfaceOptions>,
      required: true,
    },
    presenceStatus: String as PropType<PRESENCE_STATUSES>,
    guests: Array as PropType<Name[]>,
  },
  emits: ['publish-methods', 'update:presence-status', 'add-guest', 'delete-guest', 'update-guest'],
  data: () => ({
    PRESENCE_STATUSES,
    nameBlocksMethods: [] as FormPublishMethods[],
  }),
  created() {
    this.$emit('publish-methods', {
      validateFields: this.validateFields,
      getFields: this.getFields,
      resetFields: this.resetFields,
    });
  },
  computed: {
    computedOptions(): PresenceBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
  methods: {
    addName() {
      this.$emit('add-guest');
    },
    deleteName(index: number) {
      this.$emit('delete-guest', index);
    },
    deleteBlockMethods({ id }: { id: string }) {
      const index = this.nameBlocksMethods.findIndex((block: { id: string }) => block.id === id);
      this.nameBlocksMethods.splice(index, 1);
    },
    editName(
      { index, isSurName = false, value }: { index: number, isSurName?: boolean, value: string },
    ) {
      this.$emit('update-guest', { index, isSurName, value });
    },
    validateFields(): boolean {
      let result = true;
      this.nameBlocksMethods.forEach((methods: FormPublishMethods) => {
        if (!methods.validateFields()) {
          result = false;
        }
      });
      return result;
    },
    getFields() {
      const result: { guests: FormResult['guests'] } = { guests: [] };
      this.nameBlocksMethods.forEach((methods: FormPublishMethods) => {
        result.guests.push(methods.getFields());
      });
      return result;
    },
    resetFields() {
      this.nameBlocksMethods.forEach((methods: FormPublishMethods) => {
        if (methods.resetFields) {
          methods.resetFields();
        }
      });
    },
    setPresenceStatus(status: PRESENCE_STATUSES) {
      this.$emit('update:presence-status', status);
    },
  },
});
