import FirstBlockBettaOptions from '@/data/e-invitations/block-options/first-block-betta';

const defaultOptions: FirstBlockBettaOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  block1TextStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  block2TextStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  withLoveTextStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  namesStyles: {
    font: 'gogol',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  background: {
    imageUrls: {
      x1: 'https://i.imgur.com/FiMNnER.jpg',
    },
  },
};

export default defaultOptions;
