
import { ComponentPublicInstance, defineComponent } from 'vue';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import { defaultPage, Page } from '@/data/e-invitations/page';
import {
  BLOCKS_CODES,
  FIRST_BLOCK_CODES,
  UNION_BLOCK_CODES,
} from '@/data/e-invitations/block-codes';
import ERRORS_CODES from '@/data/constants/error-codes';
import ROUTER_NAMES from '@/data/constants/routes-names';
import SelectOption from '@/data/types/select-option';
import { Column } from '@/data/types/table';
import { Block } from '@/data/e-invitations/blocks';
import { INPUT_TYPE } from '@/data/types/input';
import { clone, download } from '@/tools';
import getBlockEditorFields from '@/tools/block-editors-fields';
import getBlockDefaultOptions from '@/tools/block-default-options';
import { goToFirstError } from '@/tools/errors';
import { setDemoInvitation } from '@/tools/demo-invitation-storage';
import createJsonUrl from '@/tools/json-url';
import parseJsonFile from '@/tools/parse-json-file';
import InputHolder from '@/blocks/input-holder';
import TextareaBlock from '@/blocks/textarea';
import InputBlock from '@/blocks/input';
import CheckboxBlock from '@/blocks/checkbox';
import SelectBlock from '@/blocks/select';
import SideBackgroundEditor from '@/blocks/side-background-editor';
import ColorPicker from '@/blocks/color-picker';
import DynamicFieldsEditor from '@/blocks/dynamic-fields-editor';
import TableBlock from '@/blocks/table';
import SvgIcon from '@/blocks/svg-icon';
import ButtonBlock from '@/blocks/button';
import FileInput from '@/blocks/file-input';

interface PageErrors {
  metaTitle: Set<ERRORS_CODES>;
  metaDescription: Set<ERRORS_CODES>;
  metaOgImageUrl: Set<ERRORS_CODES>;
  firstNameFirstPerson: Set<ERRORS_CODES>;
  firstNameSecondPerson: Set<ERRORS_CODES>;
  date: Set<ERRORS_CODES>;
}

interface BlockRow {
  code: UNION_BLOCK_CODES;
  index: number;
}

interface BlockCodeOption extends SelectOption {
  value: UNION_BLOCK_CODES;
}

export default defineComponent({
  name: 'page-invitations-constructor',
  components: {
    InvitationsConstructorField,
    InputHolder,
    TextareaBlock,
    InputBlock,
    CheckboxBlock,
    SelectBlock,
    SideBackgroundEditor,
    ColorPicker,
    DynamicFieldsEditor,
    TableBlock,
    SvgIcon,
    ButtonBlock,
    FileInput,
  },
  data: () => ({
    INPUT_TYPE,
    page: clone(defaultPage) as Page,
    errors: {} as PageErrors,
    currentNewBlockCode: BLOCKS_CODES.TEXT_BLOCK as UNION_BLOCK_CODES,
    invitationLabel: '',
  }),
  computed: {
    timezonesOptions(): SelectOption[] {
      return Array.from(new Array(23), (_, i) => {
        const timezone = String(-11 + i);
        return {
          label: timezone,
          value: timezone,
        };
      });
    },
    hasErrors(): boolean {
      return Object.values(this.errors).some((errorsSet) => errorsSet.size > 0);
    },
    blockColumns(): Column[] {
      return [
        {
          code: 'code',
          cellType: 'content',
          label: 'Код блока',
        },
        {
          code: 'controls',
          cellType: 'content',
          label: '',
        },
      ];
    },
    blockRows(): BlockRow[] {
      return this.page.blocks.map((block, blockIndex) => ({
        code: block.code,
        index: blockIndex,
      }));
    },
    blockSelectOptions(): BlockCodeOption[] {
      const blocksCodesArray: UNION_BLOCK_CODES[] = [
        FIRST_BLOCK_CODES.ALPHA,
        FIRST_BLOCK_CODES.BETTA,
        FIRST_BLOCK_CODES.GAMMA,
        FIRST_BLOCK_CODES.DELTA,
        FIRST_BLOCK_CODES.EPSILON,
        FIRST_BLOCK_CODES.ZETA,
        FIRST_BLOCK_CODES.ETA,
        FIRST_BLOCK_CODES.TETA,
        FIRST_BLOCK_CODES.IOTA,
        FIRST_BLOCK_CODES.NU,
        BLOCKS_CODES.ACCEPT_BLOCK_1,
        BLOCKS_CODES.ACCEPT_BLOCK_2,
        BLOCKS_CODES.BUTTON_BLOCK,
        BLOCKS_CODES.CHOICE_BLOCK,
        BLOCKS_CODES.BEVERAGES_BLOCK,
        BLOCKS_CODES.COUNTDOWN_BLOCK,
        BLOCKS_CODES.DRESSCODE_BLOCK,
        BLOCKS_CODES.END_BLOCK,
        BLOCKS_CODES.HISTORY_BLOCK,
        BLOCKS_CODES.PHOTO_BLOCK,
        BLOCKS_CODES.PLACE_BLOCK,
        BLOCKS_CODES.PRESENCE_BLOCK,
        BLOCKS_CODES.PROGRAM_BLOCK_1,
        BLOCKS_CODES.PROGRAM_BLOCK_2,
        BLOCKS_CODES.PROGRAM_BLOCK_3,
        BLOCKS_CODES.TEXT_BLOCK,
      ];
      return blocksCodesArray.map((code: UNION_BLOCK_CODES) => ({ label: code, value: code }));
    },
  },
  methods: {
    getBlockEditorFields,
    isFieldsHasError(fieldCode: keyof PageErrors): boolean {
      return this.errors[fieldCode]?.size > 0;
    },
    onNamesInput(person: 'first' | 'second', value: string): void {
      type Person = typeof person;
      const indexesMap: Record<Person, number> = {
        first: 0,
        second: 1,
      };
      const errorsMap: Record<Person, keyof PageErrors> = {
        first: 'firstNameFirstPerson',
        second: 'firstNameSecondPerson',
      };
      this.page.names[indexesMap[person]].firstName = value;
      this.removeError(errorsMap[person], ERRORS_CODES.REQUIRED);
    },
    validateFields(): boolean {
      const { meta, names, date } = this.page;
      if (!meta.title) {
        this.setError('metaTitle', ERRORS_CODES.REQUIRED);
      }
      if (!meta.description) {
        this.setError('metaDescription', ERRORS_CODES.REQUIRED);
      }
      if (!meta.ogImageUrl) {
        this.setError('metaOgImageUrl', ERRORS_CODES.REQUIRED);
      }
      if (!names[0].firstName) {
        this.setError('firstNameFirstPerson', ERRORS_CODES.REQUIRED);
      }
      if (!names[1].firstName) {
        this.setError('firstNameSecondPerson', ERRORS_CODES.REQUIRED);
      }
      if (!date) {
        this.setError('date', ERRORS_CODES.REQUIRED);
      }
      type DynamicFieldsEditorType = InstanceType<typeof DynamicFieldsEditor>;
      if (!this.$refs.blocksDynamicFields) {
        return true;
      }
      const blocksHaveErrors = (this.$refs.blocksDynamicFields as DynamicFieldsEditorType[]).some(
        (block) => !block.validateFields(),
      );
      return !(this.hasErrors || blocksHaveErrors);
    },
    setError(fieldCode: keyof PageErrors, errorCode: ERRORS_CODES): void {
      if (!this.errors[fieldCode]) {
        this.errors[fieldCode] = new Set<ERRORS_CODES>();
      }
      this.errors[fieldCode].add(errorCode);
    },
    removeError(fieldCode: keyof PageErrors, errorCode: ERRORS_CODES): void {
      if (!this.errors[fieldCode]) {
        return;
      }
      this.errors[fieldCode].delete(errorCode);
    },
    scrollToBlock(index: number) {
      const top = (this.$refs.blocks as ComponentPublicInstance[])[index].$el.offsetTop;
      window.scrollTo({ top, behavior: 'smooth' });
    },
    deleteBlock(index: number) {
      this.page.blocks.splice(index, 1);
    },
    moveBlock(index: number, direction: 'up' | 'down'): void {
      if (
        (direction === 'up' && index === 0)
        || (direction === 'down' && index === this.page.blocks.length - 1)
      ) {
        return;
      }
      const secondBlockIndex = direction === 'up' ? index - 1 : index + 1;
      const lowerIndex = Math.min(index, secondBlockIndex);
      const largerIndex = Math.max(index, secondBlockIndex);
      const firstBlock = this.page.blocks[lowerIndex];
      const secondBlock = this.page.blocks[largerIndex];
      this.page.blocks.splice(lowerIndex, 2, secondBlock, firstBlock);
    },
    addNewBlock() {
      const newBlock: Block = {
        code: this.currentNewBlockCode,
        options: getBlockDefaultOptions(this.currentNewBlockCode),
      };
      this.page.blocks.push(newBlock);
      this.currentNewBlockCode = BLOCKS_CODES.TEXT_BLOCK;
    },
    async openInvitation() {
      if (!this.validateFields()) {
        await this.$nextTick();
        goToFirstError(this.$el);
        return;
      }
      setDemoInvitation(this.page);
      const routeData = this.$router.resolve({ name: ROUTER_NAMES.DEMO_E_INVITATIONS });
      window.open(routeData.href, '_blank');
    },
    downloadInvitation() {
      const url = createJsonUrl(this.page);
      const name = this.invitationLabel || 'Приглашение';
      download({ url, name, ext: 'json' });
    },
    async uploadInvitation(file: File) {
      this.page = await parseJsonFile(file);
    },
  },
  watch: {
    'page.meta.title': function handler() {
      this.removeError('metaTitle', ERRORS_CODES.REQUIRED);
    },
    'page.meta.description': function handler() {
      this.removeError('metaDescription', ERRORS_CODES.REQUIRED);
    },
    'page.meta.ogImageUrl': function handler() {
      this.removeError('metaOgImageUrl', ERRORS_CODES.REQUIRED);
    },
    'page.names[0].firstName': function handler() {
      this.removeError('firstNameFirstPerson', ERRORS_CODES.REQUIRED);
    },
    'page.names[1].firstName': function handler() {
      this.removeError('firstNameSecondPerson', ERRORS_CODES.REQUIRED);
    },
    'page.date': function handler() {
      this.removeError('date', ERRORS_CODES.REQUIRED);
    },
  },
});
