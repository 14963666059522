import ChoiceBlockOptions, { Block, Field } from '@/data/e-invitations/block-options/choice-block';
import { CHOICE_BLOCK_TYPES } from '@/data/e-invitations/types';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';
import { inputTypeEditorConfig } from '@/data/e-invitations/common-editors-configs';

interface LocalEditorConfigType extends EditorConfigType<ChoiceBlockOptions> {
  blocks: EditorConfigFieldType & {
    contentFields: EditorConfigType<Block> & {
      fields: EditorConfigFieldType & {
        contentFieldsDefaultValue: Field;
      };
    };
    contentFieldsDefaultValue: Required<Block>;
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  checkboxesStyles: {
    editorElement: EDITOR_ELEMENTS.CHECKBOX_STYLES,
    label: 'Стили чекбоксов',
  },
  inputType: inputTypeEditorConfig,
  inputStyles: {
    editorElement: EDITOR_ELEMENTS.INPUT_STYLES,
    label: 'Стили инпутов',
  },
  blocks: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Блоки',
    contentFields: {
      title: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст выбора',
      },
      resultsTableLabel: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст выбора в таблице результатов',
        optional: true,
      },
      fields: {
        editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
        label: 'Поля для ответов',
        contentComponent: EDITOR_ELEMENTS.CHOICE_FIELDS,
        contentFieldsDefaultValue: {
          required: false,
          type: CHOICE_BLOCK_TYPES.CHECKBOX,
          multiple: false,
          checkboxes: [],
          inputPlaceholder: '',
        },
      },
    },
    contentFieldsDefaultValue: {
      title: '',
      resultsTableLabel: '',
      fields: [
        {
          required: false,
          type: CHOICE_BLOCK_TYPES.INPUT,
          inputPlaceholder: '',
        },
      ],
    },
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
