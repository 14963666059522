/* eslint-disable no-param-reassign,no-underscore-dangle */
import { DirectiveBinding, Directive } from 'vue';
import { sleep } from '@/tools';

const defaultConfig = {
  staggerDelay: 0.20,
  threshold: 0.3,
};
const ANIMATIONS_CLASSES = {
  FROM: 'animation-from',
  ACTIVE: 'animation-active',
};

interface HTMLAnimatedElement extends HTMLElement {
  __intersectionEnterAnimation__?: {
    tact: number;
  };
}

interface HTMLAnimationContainerElement extends HTMLElement {
  __observer__?: IntersectionObserver;
}

export const ContainerDirective: Directive = {
  async mounted(
    container: HTMLAnimationContainerElement,
    binding: DirectiveBinding,
  ): Promise<void> {
    await sleep(0);
    const params = binding.value || {};
    const customConfig = params?.config || {};
    const config = { ...defaultConfig, ...customConfig };
    const animatedElements: HTMLAnimatedElement[] = Array.from(
      container.querySelectorAll(`.${ANIMATIONS_CLASSES.FROM}`),
    );
    container.__observer__ = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        animatedElements.forEach(async (element: HTMLAnimatedElement) => {
          const removeAnimationActiveClass = () => {
            element.classList.remove(ANIMATIONS_CLASSES.ACTIVE);
            element.removeEventListener('transitionend', removeAnimationActiveClass);
          };

          element.classList.add(ANIMATIONS_CLASSES.ACTIVE);
          if (element.__intersectionEnterAnimation__?.tact) {
            await sleep(config.staggerDelay * element.__intersectionEnterAnimation__?.tact);
          }
          element.classList.remove(ANIMATIONS_CLASSES.FROM);
          element.addEventListener('transitionend', removeAnimationActiveClass);
        });
        if (container.__observer__) {
          container.__observer__.unobserve(container);
        }
      }
    }, {
      threshold: config.threshold,
    });
    container.__observer__.observe(container);
  },
  beforeUnmount(el: HTMLAnimationContainerElement) {
    if (el.__observer__) {
      el.__observer__.unobserve(el);
      delete el.__observer__;
    }
  },
};

export const ElementDirective: Directive = {
  mounted(element: HTMLAnimatedElement, binding: DirectiveBinding): void {
    const { tact } = binding.value || {};
    element.classList.add(ANIMATIONS_CLASSES.FROM);
    element.__intersectionEnterAnimation__ = {
      tact,
    };
  },
  beforeUnmount(element: HTMLAnimatedElement): void {
    element.classList.remove(ANIMATIONS_CLASSES.FROM, ANIMATIONS_CLASSES.ACTIVE);
    delete element.__intersectionEnterAnimation__;
  },
};
