import AcceptBlock1Options from '@/data/e-invitations/block-options/accept-block-1';

const defaultOptions: AcceptBlock1Options = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  textBlocksStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  dateStyles: {
    font: 'montserrat',
    weight: 700,
    color: 'rgba(31, 31, 31, 1)',
  },
  titleText: 'Подтверждение',
  blocksText: [
    {
      text: 'Пожалуйста подтвердите Ваше присутствие на свадьбе, заполнив дополнительные параметры',
      desktopWidth: '389px',
    },
    {
      text: 'Мы будем ждать ответ \nдо',
      highlightedTextInTheEnd: '31 марта 2022',
    },
  ],
  imageUrls: {
    x1: '/e-invitations/anastasiya-vladimir/images/accept-block-1.jpg',
  },
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
