
import { defineComponent, PropType } from 'vue';
import CheckboxStyles from '@/data/types/checkbox-styles';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import IconStylesEditor from '@/blocks/icon-styles-editor';
import ColorPicker from '@/blocks/color-picker';
import TextStylesEditor from '@/blocks/text-styles-editor';
import CheckboxBlock from '@/blocks/checkbox';

export default defineComponent({
  name: 'checkbox-styles-editor',
  components: {
    InvitationsConstructorField,
    IconStylesEditor,
    ColorPicker,
    TextStylesEditor,
    CheckboxBlock,
  },
  props: {
    value: Object as PropType<CheckboxStyles>,
  },
  emits: ['update:value'],
  data: () => ({
    localCheckboxValue: true,
  }),
  methods: {
    onInput<T extends keyof CheckboxStyles>(code: T, value: CheckboxStyles[T]): void {
      this.$emit('update:value', {
        ...this.value,
        [code]: value,
      });
    },
  },
});
