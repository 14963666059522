
import { defineComponent } from 'vue';
import { ColorPicker as VueColorPicker } from 'vue-color-kit';

require('vue-color-kit/dist/vue-color-kit.css');

export default defineComponent({
  name: 'color-picker',
  components: {
    VueColorPicker,
  },
  props: {
    value: String,
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:value'],
  methods: {
    onInput(event: { rgba: { r: string, g: string, b: string, a: string } }) {
      const {
        r,
        g,
        b,
        a,
      } = event.rgba;
      this.$emit('update:value', `rgba(${r}, ${g}, ${b}, ${a})`);
    },
  },
});
