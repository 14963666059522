
import { defineComponent, PropType } from 'vue';
import Font from '@/data/types/font';
import FontWeight from '@/data/types/font-weight';
import getFontFamily from '@/tools/font-family';

export default defineComponent({
  name: 'text-block1',
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    weight: {
      type: Number as PropType<FontWeight>,
      default: 400,
    },
    font: {
      type: String as PropType<Font>,
      default: 'century-gothic',
    },
    size: [String, Array] as PropType<'m' | [number, number]>,
    color: String,
    lineHeight: Number,
  },
  computed: {
    sizeClass(): string | null {
      const { size } = this;
      return typeof size === 'string' ? size as string : null;
    },
    sizeStyle(): Record<string, string> | null {
      const { size } = this;
      return Array.isArray(size)
        ? {
          '--font-size-desktop': `${size[0]}px`,
          '--font-size-mobile': `${size[1]}px`,
        }
        : null;
    },
  },
  methods: {
    getFontFamily,
  },
});
