import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: _normalizeClass(_ctx.bem('select', { error: _ctx.hasError })),
    value: _ctx.value,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option.value,
        value: option.value
      }, _toDisplayString(option.label), 9, _hoisted_2))
    }), 128))
  ], 42, _hoisted_1))
}