import { HttpService } from '@/api/http-service';

// DTO
import type { InvResponseCreateRequestDto } from './dto/create/request.dto';
import type { InvResponseCreateResponseDto } from './dto/create/response.dto';

import type { InvResponseGetByEInvIdResponseDto } from './dto/get-by-e-inv-id/response.dto';

class InvResponseService extends HttpService {
  getByEInvId(eInvId: string) {
    return this.get<InvResponseGetByEInvIdResponseDto>(`/inv-response/e-inv-id/${eInvId}`);
  }

  create(payload: InvResponseCreateRequestDto) {
    return this.post<InvResponseCreateResponseDto>('/inv-response', payload);
  }
}

export const invResponseService = new InvResponseService();
