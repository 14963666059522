
import { defineComponent, PropType } from 'vue';
import SelectOption from '@/data/types/select-option';

export default defineComponent({
  name: 'select-block',
  props: {
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    value: String,
    hasError: Boolean,
  },
});
