// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { clone } from '@/tools/index';

function isObject(a: unknown) {
  return a !== null && typeof a === 'object';
}

export default function getDeepDiff<T>(prev: Partial<T>, next: Partial<T>): Partial<T> | null {
  const a = clone(prev);
  const b = clone(next);
  const result = {};
  if (!a || !b) return a || b || result;
  const keys = Array.from(
    new Set(Object.keys(a).concat(Object.keys(b))).values(),
  ) as unknown as (keyof T)[];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const isObjectA = isObject(a[key]);
    const isObjectB = isObject(b[key]);
    if (isObjectA !== isObjectB) {
      result[key] = b[key];
    } else if (isObjectA) {
      const diff = getDeepDiff(a[key], b[key]);
      if (diff) result[key] = diff;
    } else if (a[key] !== b[key]) result[key] = b[key];
  }
  return Object.keys(result).length ? result : null;
}
