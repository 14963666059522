
import { defineComponent, PropType } from 'vue';
import EndBlockInterfaceOptions from '@/data/e-invitations/block-options/end-block';
import defaultOptions from '@/data/e-invitations/block-default-options/end-block';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'end-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    SvgIcon,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<EndBlockInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): EndBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
});
