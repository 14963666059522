import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "invitations-constructor-field" }
const _hoisted_2 = {
  key: 0,
  class: "invitations-constructor-field__label-holder"
}
const _hoisted_3 = { class: "invitations-constructor-field__label" }
const _hoisted_4 = {
  key: 0,
  class: "invitations-constructor-field__note"
}
const _hoisted_5 = { class: "invitations-constructor-field__description" }
const _hoisted_6 = { class: "invitations-constructor-field__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.label || _ctx.$slots.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _renderSlot(_ctx.$slots, "label")
          ]),
          (_ctx.optional)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Необязательный параметр"))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_5, [
            _renderSlot(_ctx.$slots, "description")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "value")
    ])
  ]))
}