import EndBlockOptions from '@/data/e-invitations/block-options/end-block';

const defaultOptions: EndBlockOptions = {
  fullScreen: false,
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  iconStyles: {
    name: 'heart-fat-filled',
    fill: 'none',
    stroke: 'rgba(31, 31, 31, 1)',
    strokeWidth: 3,
  },
  title: 'Мы ждем Вас с \nнетерпением!',
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
