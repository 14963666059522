import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block-background-editor" }
const _hoisted_2 = { class: "block-background-editor__item" }
const _hoisted_3 = { class: "block-background-editor__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_image_urls_editor = _resolveComponent("image-urls-editor")!
  const _component_image_settings_editor = _resolveComponent("image-settings-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_color_picker, {
      class: "block-background-editor__item",
      value: _ctx.value.color,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('color', $event)))
    }, null, 8, ["value"]),
    _createVNode(_component_image_urls_editor, {
      class: "block-background-editor__item",
      value: _ctx.value.imageUrls,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('imageUrls', $event)))
    }, null, 8, ["value"]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(" desktop "),
      _createVNode(_component_image_settings_editor, {
        value: _ctx.getSettings('desktop'),
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSettingsInput('desktop', $event)))
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(" mobile "),
      _createVNode(_component_image_settings_editor, {
        value: _ctx.getSettings('mobile'),
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSettingsInput('mobile', $event)))
      }, null, 8, ["value"])
    ])
  ]))
}