
import { defineComponent, PropType } from 'vue';
import TextBlock from '@/blocks/text';
import TextStyles from '@/data/types/text-styles';

export default defineComponent({
  name: 'program-item',
  components: {
    TextBlock,
  },
  props: {
    timeStyles: Object as PropType<TextStyles>,
    titleStyles: Object as PropType<TextStyles>,
    addressStyles: Object as PropType<TextStyles>,
    bigMarginFromTitle: Boolean,
    marginFromAddress: Boolean,
    align: {
      type: String as PropType<'left' | 'center'>,
      default: 'left',
    },
  },
});
