
import { defineComponent, PropType } from 'vue';
import SvgStyles from '@/data/types/svg-styles';
import { clone } from '@/tools';
import IconPickerEditor from '@/blocks/icon-picker-editor';
import ColorPicker from '@/blocks/color-picker';
import InputBlock from '@/blocks/input';
import SvgIcon from '@/blocks/svg-icon';

export default defineComponent({
  name: 'icon-styles-editor',
  components: {
    IconPickerEditor,
    ColorPicker,
    InputBlock,
    SvgIcon,
  },
  props: {
    value: {
      type: Object as PropType<SvgStyles>,
      required: true,
    },
    required: Boolean,
  },
  emits: ['update:value'],
  methods: {
    onInput<T extends keyof SvgStyles>(code: T, value: SvgStyles[T]): void {
      if (code === 'name' && !value) {
        this.$emit('update:value', null);
        return;
      }
      const oldValue = clone(this.value || {});
      const newValue: SvgStyles = {
        ...oldValue,
        strokeWidth: oldValue.stroke ? oldValue.strokeWidth : undefined,
        [code]: value,
      };
      this.$emit('update:value', newValue);
    },
  },
});
