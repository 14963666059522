import CountdownBlockOptions from '@/data/e-invitations/block-options/countdown-block';

const defaultOptions: CountdownBlockOptions = {
  titleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  countdownUnitStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(203, 203, 203, 1)',
  },
  countdownUnitLabelsStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  titleText: 'До торжества осталось',
  separatorIconStyles: null,
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
