import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_img_block = _resolveComponent("img-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: "block-with-background",
    style: _normalizeStyle({ backgroundColor: _ctx.background.color })
  }, [
    (_ctx.background.imageUrls)
      ? (_openBlock(), _createBlock(_component_img_block, {
          key: 0,
          class: _normalizeClass(_ctx.bem('block-with-background__img', { position: _ctx.position, size: _ctx.size })),
          src: _ctx.background.imageUrls.x1,
          src2x: _ctx.background.imageUrls.x2,
          "mobile-src": _ctx.background.imageUrls.x1Mobile,
          "mobile-src2x": _ctx.background.imageUrls.x2Mobile
        }, null, 8, ["class", "src", "src2x", "mobile-src", "mobile-src2x"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}