import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-styles-editor" }
const _hoisted_2 = { class: "icon-styles-editor__settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_picker_editor = _resolveComponent("icon-picker-editor")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_icon_picker_editor, {
        class: "icon-styles-editor__item",
        required: _ctx.required,
        value: _ctx.value?.name,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('name', $event)))
      }, null, 8, ["required", "value"]),
      _createVNode(_component_color_picker, {
        class: "icon-styles-editor__item",
        value: _ctx.value?.fill,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('fill', $event)))
      }, null, 8, ["value"]),
      _createVNode(_component_color_picker, {
        class: "icon-styles-editor__item",
        value: _ctx.value?.stroke,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('stroke', $event)))
      }, null, 8, ["value"]),
      (_ctx.value?.stroke)
        ? (_openBlock(), _createBlock(_component_input_block, {
            key: 0,
            class: "icon-styles-editor__item",
            number: "",
            placeholder: "Толщина обводки",
            value: String(_ctx.value.strokeWidth || ''),
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('strokeWidth', Number($event))))
          }, null, 8, ["value"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          class: "icon-styles-editor__icon-preview",
          name: _ctx.value.name,
          fill: _ctx.value.fill,
          stroke: _ctx.value.stroke,
          "stroke-width": _ctx.value.strokeWidth
        }, null, 8, ["name", "fill", "stroke", "stroke-width"]))
      : _createCommentVNode("", true)
  ]))
}