import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(_ctx.bem('text', { tag: _ctx.tag, weight: _ctx.weight, size: _ctx.sizeClass, sizeWithVars: !_ctx.sizeClass })),
    style: _normalizeStyle({ ..._ctx.sizeStyle, color: _ctx.color, lineHeight: _ctx.lineHeight, fontFamily: _ctx.getFontFamily(_ctx.font) })
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "style"]))
}