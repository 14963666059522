
import { defineComponent, PropType } from 'vue';
import { Names } from '@/data/e-invitations/types';
import FirstBlockEtaInterfaceOptions from '@/data/e-invitations/block-options/first-block-eta';
import defaultOptions from '@/data/e-invitations/block-default-options/first-block-eta';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import { formatDate } from '@/tools/datetime';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'first-block-eta',
  components: {
    BlockWithBackground,
    TextBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    names: {
      type: Array as unknown as PropType<Names>,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<FirstBlockEtaInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): FirstBlockEtaInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
    splittedDate(): string[] {
      return formatDate(this.date, 'DD.MM.YY').split('.');
    },
    namesItems(): string[] {
      return [this.names[0].firstName, 'и', this.names[1].firstName];
    },
  },
});
