import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "map-settings-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_block = _resolveComponent("input-block")!
  const _component_input_holder = _resolveComponent("input-holder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_holder, {
      class: "map-settings-editor__item",
      "error-codes": _ctx.errors.latitude
    }, {
      default: _withCtx(() => [
        _createVNode(_component_input_block, {
          placeholder: "Широта",
          value: String(_ctx.value?.coords[0] || ''),
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput(0, $event)))
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["error-codes"]),
    _createVNode(_component_input_holder, {
      class: "map-settings-editor__item",
      "error-codes": _ctx.errors.longitude
    }, {
      default: _withCtx(() => [
        _createVNode(_component_input_block, {
          placeholder: "Долгота",
          value: String(_ctx.value?.coords[1] || ''),
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput(1, $event)))
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["error-codes"])
  ]))
}