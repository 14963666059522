import BeveragesBlockOptions from '@/data/e-invitations/block-options/beverages-block';
import { EDITOR_ELEMENTS, EditorConfigType } from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<BeveragesBlockOptions> = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  textStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста',
  },
  beverageNameStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили названий напитков',
  },
  activeIconColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет активной иконки',
  },
  disabledIconColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет неактивной иконки',
  },
  beverageCountStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста счетчика количества напитка',
  },
  title: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  text: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст текста',
  },
  beverages: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Список напитков',
    contentComponent: EDITOR_ELEMENTS.INPUT,
    contentFieldsDefaultValue: '',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
