
import { defineComponent, PropType } from 'vue';
import SvgIcon from '@/blocks/svg-icon';
import TextBlock from '@/blocks/text';
import SvgStyles from '@/data/types/svg-styles';
import { Color } from '@/data/e-invitations/types';
import TextStyles from '@/data/types/text-styles';

export default defineComponent({
  name: 'checkbox-block',
  components: {
    SvgIcon,
    TextBlock,
  },
  props: {
    circleColor: {
      type: String as PropType<Color>,
      default: 'rgba(0, 0, 0, 1)',
    },
    iconStyles: {
      type: Object as PropType<SvgStyles>,
      default: () => ({ name: 'circle-filled' }),
    },
    labelStyles: {
      type: Object as PropType<TextStyles>,
      default: () => ({
        font: 'century-gothic',
        weight: 400,
        color: 'rgba(31, 31, 31, 1)',
      }),
    },
    value: Boolean,
    hasError: Boolean,
  },
  emits: ['update:value'],
});
