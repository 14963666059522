import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "history-period-image-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_block = _resolveComponent("select-block")!
  const _component_invitations_constructor_field = _resolveComponent("invitations-constructor-field")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_image_urls_editor = _resolveComponent("image-urls-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_invitations_constructor_field, { class: "history-period-image-editor__item" }, {
      label: _withCtx(() => [
        _createTextVNode("Размеры картинки")
      ]),
      value: _withCtx(() => [
        _createVNode(_component_select_block, {
          options: _ctx.sizeTypesOptions,
          value: _ctx.sizeType,
          "onUpdate:value": _ctx.onSizeTypeInput
        }, null, 8, ["options", "value", "onUpdate:value"])
      ]),
      _: 1
    }),
    (_ctx.sizeType === _ctx.SIZE_TYPES.FILLED_SIZES)
      ? (_openBlock(), _createBlock(_component_invitations_constructor_field, {
          key: 0,
          class: "history-period-image-editor__item"
        }, {
          label: _withCtx(() => [
            _createTextVNode("Размеры картинки")
          ]),
          description: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.SIZE_STRING_DESCRIPTION), 1)
          ]),
          value: _withCtx(() => [
            _createVNode(_component_input_block, {
              class: "history-period-image-editor__item",
              placeholder: "Ширина",
              value: _ctx.value.desktopSize?.width,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSizeInput('width', $event)))
            }, null, 8, ["value"]),
            _createVNode(_component_input_block, {
              class: "history-period-image-editor__item",
              placeholder: "Высота",
              value: _ctx.value.desktopSize?.height,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSizeInput('height', $event)))
            }, null, 8, ["value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_invitations_constructor_field, { class: "history-period-image-editor__item" }, {
      label: _withCtx(() => [
        _createTextVNode("Ссылки на картинки")
      ]),
      value: _withCtx(() => [
        _createVNode(_component_image_urls_editor, {
          value: _ctx.value.urls,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('urls', $event)))
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    _createVNode(_component_invitations_constructor_field, { class: "history-period-image-editor__item" }, {
      label: _withCtx(() => [
        _createTextVNode(" Альт текст ")
      ]),
      value: _withCtx(() => [
        _createVNode(_component_input_block, {
          value: _ctx.value.alt,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('alt', $event)))
        }, null, 8, ["value"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("template", null, [
          _createTextVNode(_toDisplayString(_ctx.ALT_DESCRIPTION), 1)
        ])
      ]),
      _: 1
    })
  ]))
}