import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "names-with-ampersand" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_svg_icon, {
      class: "names-with-ampersand__ampersand",
      name: "ampersand",
      style: _normalizeStyle({ fill: _ctx.ampersandColor })
    }, null, 8, ["style"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.names, ({ firstName }) => {
      return (_openBlock(), _createBlock(_component_text_block, _mergeProps({
        key: firstName,
        class: _ctx.bem('names-with-ampersand__name', { size: _ctx.size })
      }, _ctx.namesStyles, { size: _ctx.namesSize }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(firstName), 1)
        ]),
        _: 2
      }, 1040, ["class", "size"]))
    }), 128))
  ]))
}