// eslint-disable-next-line import/prefer-default-export
export function formatDate(
  date: string | null,
  format: 'DD.MM.YYYY' | 'DD.month.YYYY' | 'DD.MM.YYYY, HH:mm tz' | 'DD.MM.YY' = 'DD.MM.YYYY',
): string {
  let options: Intl.DateTimeFormatOptions = {};
  if (format === 'DD.month.YYYY') {
    options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
  } else if (format === 'DD.MM.YYYY, HH:mm tz') {
    options = {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };
  } else if (format === 'DD.MM.YY') {
    options = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    };
  }
  return (date ? new Date(date) : new Date()).toLocaleDateString('ru', options);
}
