import ProgramBlock2Options from '@/data/e-invitations/block-options/program-block-2';

const defaultOptions: ProgramBlock2Options = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  itemIconStyles: {
    fill: 'rgba(196, 196, 196, 1)',
    name: 'heart-filled',
  },
  itemSeparatorColor: 'rgba(196, 196, 196, 1)',
  itemTimeStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(31, 31, 31, 1)',
  },
  itemTitleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  itemAddressStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(173, 173, 173, 1)',
  },
  titleText: 'Программа дня',
  items: [
    {
      time: '12:00',
      title: 'Регистрация брака',
      address: 'ул. Ленина, 300',
    },
    {
      time: '14:00',
      title: 'Фотосессия и прогулка',
      address: 'Центральный сквер',
    },
    {
      time: '17:00',
      title: 'Начало банкета',
      address: 'ул. Васильева, 150',
    },
    {
      time: '00:00',
      title: 'Окончание банкета',
      address: 'ул. Васильева, 150',
    },
  ],
  background: {
    color: 'rgba(255, 255, 255, 1)',
  },
};

export default defaultOptions;
