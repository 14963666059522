import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_color_picker = _resolveComponent("vue-color-picker")!

  return (_openBlock(), _createBlock(_component_vue_color_picker, {
    class: "color-picker",
    color: _ctx.value,
    onChangeColor: _ctx.onInput
  }, null, 8, ["color", "onChangeColor"]))
}