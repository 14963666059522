
import { defineComponent, PropType } from 'vue';
import { Names, Color } from '@/data/e-invitations/types';
import TextStyles from '@/data/types/text-styles';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon';

type Sizes = 'm' | 'l';

export default defineComponent({
  name: 'names-with-ampersand',
  components: {
    TextBlock,
    SvgIcon,
  },
  props: {
    names: {
      type: Array as unknown as PropType<Names>,
      required: true,
    },
    namesStyles: Object as PropType<TextStyles>,
    ampersandColor: String as PropType<Color>,
    size: {
      type: String as PropType<'m' | 'l'>,
      default: 'm',
    },
  },
  computed: {
    namesSize(): [number, number] {
      const sizeMap: Record<Sizes, [number, number]> = {
        m: [80, 36],
        l: [90, 36],
      };
      return sizeMap[this.size];
    },
  },
});
