
import { defineComponent, PropType } from 'vue';
import { PeriodImage, SizeParameter } from '@/data/e-invitations/block-options/history-block';
import SelectOption from '@/data/types/select-option';
import { SIZE_STRING_DESCRIPTION, ALT_DESCRIPTION } from '@/data/constants/editor-fields-texts';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import SelectBlock from '@/blocks/select';
import InputBlock from '@/blocks/input';
import ImageUrlsEditor from '@/blocks/image-urls-editor';

enum SIZE_TYPES {
  FULL_WIDTH = 'full-width',
  FILLED_SIZES = 'filled-sizes',
}

export default defineComponent({
  name: 'history-period-image-editor',
  components: {
    InvitationsConstructorField,
    SelectBlock,
    InputBlock,
    ImageUrlsEditor,
  },
  props: {
    value: {
      type: Object as PropType<PeriodImage>,
      default: () => ({}),
    },
  },
  emits: ['update:value'],
  data: () => ({
    SIZE_TYPES,
    SIZE_STRING_DESCRIPTION,
    ALT_DESCRIPTION,
  }),
  computed: {
    sizeTypesOptions(): SelectOption[] {
      return [
        {
          label: 'На всю ширину',
          value: SIZE_TYPES.FULL_WIDTH,
        },
        {
          label: 'Заданные размеры',
          value: SIZE_TYPES.FILLED_SIZES,
        },
      ];
    },
    sizeType(): SIZE_TYPES {
      const { FULL_WIDTH, FILLED_SIZES } = SIZE_TYPES;
      return this.value.desktopSize === 'full-width' ? FULL_WIDTH : FILLED_SIZES;
    },
  },
  methods: {
    onSizeTypeInput(type: SIZE_TYPES): void {
      if (type === 'full-width') {
        this.onInput('desktopSize', type);
        return;
      }
      this.onInput('desktopSize', {
        width: '',
        height: '',
      });
    },
    onSizeInput(parameter: keyof SizeParameter, value: string) {
      this.onInput('desktopSize', {
        ...(this.value.desktopSize as SizeParameter),
        [parameter]: value,
      });
    },
    onInput<T extends keyof PeriodImage>(code: T, value: PeriodImage[T]): void {
      const newValue: PeriodImage = {
        ...this.value,
        [code]: value,
      };
      this.$emit('update:value', newValue);
    },
  },
});
