import BeveragesBlockOptions from '@/data/e-invitations/block-options/beverages-block';

const defaultOptions: BeveragesBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  textStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  beverageNameStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  activeIconColor: 'rgba(235, 193, 193, 1)',
  disabledIconColor: 'rgba(173, 173, 173, 1)',
  beverageCountStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  title: 'Предпочтения по напиткам',
  text: 'Пожалуйста, отметьте предпочтительные напитки с учетом (людей)',
  beverages: [
    'Коньяк',
    'Виски',
    'Вино белое',
    'Вино красное',
    'Шампанское',
    'Водка',
    'Безалкогольные напитки',
  ],
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
