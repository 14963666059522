import { PersonalizationDto } from '@/api/modules/personalization/dto/personalization.dto';
import { MakeFieldsOptional } from '@/tools/types';

export default function personalize({ value, personalization }: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  personalization?: MakeFieldsOptional<PersonalizationDto, 'eInvId' | 'slug'>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  const pronounceMap: Record<PersonalizationDto['pronounce'], number> = {
    he: 0,
    she: 1,
    they: 2,
    'he-formal': 3,
    'she-formal': 4,
  };
  const defaultPronounce = 5;
  const inflectiveFunctionName = '$&$';
  const appealFunctionName = '$^$';
  const inflectiveOptionsSeparator = '//';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasProperty = (object: Record<string, any>, key: string) => {
    if (object.hasOwn) {
      return object.hasOwn(key);
    }
    // eslint-disable-next-line no-prototype-builtins
    return object.hasOwnProperty(key);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const personalizeRecursively = (value: any): any => {
    if (['number', 'undefined'].includes(typeof value) || value === null) {
      return value;
    }
    if (typeof value === 'string') {
      let result = value;
      let startOfInflectiveFuncIndex = value.indexOf(inflectiveFunctionName);
      let startOfAppealFuncIndex = value.indexOf(appealFunctionName);
      while ([startOfInflectiveFuncIndex, startOfAppealFuncIndex].some((index) => index !== -1)) {
        if (startOfInflectiveFuncIndex !== -1) {
          const endOfInflectiveFuncIndex = result.indexOf(')', startOfInflectiveFuncIndex);
          const inflectiveWordsArray = result.slice(
            startOfInflectiveFuncIndex + inflectiveFunctionName.length + 1,
            endOfInflectiveFuncIndex,
          ).split(inflectiveOptionsSeparator);
          const lastIndexInflectiveWordsArray = inflectiveWordsArray.length - 1;
          const pronounceIndex = personalization
            ? pronounceMap[personalization.pronounce]
            : defaultPronounce;
          let rightWord = inflectiveWordsArray[pronounceIndex];
          if (!rightWord && personalization) {
            if (personalization.pronounce === 'he-formal') {
              rightWord = inflectiveWordsArray[pronounceMap.he];
            } else if (personalization.pronounce === 'she-formal') {
              rightWord = inflectiveWordsArray[pronounceMap.she];
            }
            if (!rightWord) {
              rightWord = inflectiveWordsArray[lastIndexInflectiveWordsArray];
            }
          }
          result = result.slice(0, startOfInflectiveFuncIndex)
            + rightWord
            + result.slice(endOfInflectiveFuncIndex + 1);
          if (
            startOfAppealFuncIndex > startOfInflectiveFuncIndex
            && startOfAppealFuncIndex !== -1
          ) {
            startOfAppealFuncIndex = result.indexOf(appealFunctionName);
          }
        }
        if (startOfAppealFuncIndex !== -1) {
          const endOfAppealFuncIndex = result.indexOf(')', startOfAppealFuncIndex);
          const word = personalization?.displayedName || result.slice(
            startOfAppealFuncIndex + appealFunctionName.length + 1,
            endOfAppealFuncIndex,
          );
          result = result.slice(0, startOfAppealFuncIndex)
            + word
            + result.slice(endOfAppealFuncIndex + 1);
        }
        startOfInflectiveFuncIndex = result.indexOf(inflectiveFunctionName);
        startOfAppealFuncIndex = result.indexOf(appealFunctionName);
      }
      return result;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const field in value) {
      if (hasProperty(value, field)) {
        // eslint-disable-next-line no-param-reassign
        value[field] = personalizeRecursively(value[field]);
      }
    }
    return value;
  };
  return personalizeRecursively(value);
}
