
import { defineComponent, PropType } from 'vue';
import AcceptBlock2InterfaceOptions from '@/data/e-invitations/block-options/accept-block-2';
import defaultOptions from '@/data/e-invitations/block-default-options/accept-block-2';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'accept-block-2-inv',
  components: {
    BlockWithBackground,
    TextBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<AcceptBlock2InterfaceOptions>,
      required: true,
    },
  },
  data: () => ({
    textSize: [30, 18],
  }),
  computed: {
    computedOptions(): AcceptBlock2InterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
});
