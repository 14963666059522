
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';
import useMediaQueriesStore from '@/store/media-queries';
import {
  Background,
  ImageSettings,
  ImagePosition,
  ImageSize,
} from '@/data/e-invitations/types';
import ImgBlock from '@/blocks/img';

export default defineComponent({
  name: 'block-with-background',
  components: {
    ImgBlock,
  },
  props: {
    background: {
      type: Object as PropType<Background>,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(useMediaQueriesStore, ['isMobile']),
    settings(): ImageSettings {
      const { mobile, desktop } = this.background?.imageSettings || {};
      const settings = this.isMobile ? mobile : desktop;
      return settings || {};
    },
    position(): ImagePosition {
      return this.settings.position || 'center';
    },
    size(): ImageSize {
      return this.settings.size || 'cover';
    },
  },
});
