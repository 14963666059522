import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "accept-block-1-inv__blocks-container content-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_img_block = _resolveComponent("img-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "accept-block-1-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.bem('accept-block-1-inv__content', { noPaddingBottom: !!_ctx.computedOptions.imageUrls }))
      }, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "accept-block-1-inv__title" }, _ctx.computedOptions.titleStyles, { size: [60, 40] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.blocksText, (block, blockIndex) => {
            return _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
              key: blockIndex,
              class: "accept-block-1-inv__text-block",
              style: { '--desktop-width': block.desktopWidth }
            }, _ctx.computedOptions.textBlocksStyles, { size: [30, 18] }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(block.text) + " ", 1),
                (block.highlightedTextInTheEnd)
                  ? (_openBlock(), _createBlock(_component_text_block, _mergeProps({
                      key: 0,
                      class: "font-size-inherit",
                      tag: "span"
                    }, _ctx.computedOptions.dateStyles), {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(block.highlightedTextInTheEnd), 1)
                      ]),
                      _: 2
                    }, 1040))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1040, ["style"])), [
              [_directive_element_intersection_animation_directive, { tact: blockIndex + 2 }]
            ])
          }), 128))
        ]),
        (_ctx.computedOptions.imageUrls)
          ? _withDirectives((_openBlock(), _createBlock(_component_img_block, {
              key: 0,
              class: "accept-block-1-inv__image",
              src: _ctx.computedOptions.imageUrls.x1,
              src2x: _ctx.computedOptions.imageUrls.x2,
              "mobile-src": _ctx.computedOptions.imageUrls.x1Mobile,
              "mobile-src2x": _ctx.computedOptions.imageUrls.x2Mobile
            }, null, 8, ["src", "src2x", "mobile-src", "mobile-src2x"])), [
              [_directive_element_intersection_animation_directive, { tact: _ctx.computedOptions.blocksText.length + 2 }]
            ])
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}