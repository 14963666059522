import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-gamma__content content-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_names_with_ampersand = _resolveComponent("names-with-ampersand")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_countdown_block = _resolveComponent("countdown-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-gamma",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_names_with_ampersand, {
          class: "first-block-gamma__names",
          names: _ctx.names,
          "names-styles": _ctx.computedOptions.namesStyles,
          "ampersand-color": _ctx.computedOptions.ampersandColor
        }, null, 8, ["names", "names-styles", "ampersand-color"]), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-gamma__text-block-1 text-align-center" }, _ctx.computedOptions.textBlock1Styles), {
          default: _withCtx(() => [
            _createTextVNode(" С радостью сообщаем, что мы собираемся пожениться. И этот важный для нас день мы хотим разделить с Вами. ")
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-gamma__date" }, _ctx.computedOptions.dateStyles), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 3 }]
        ]),
        _withDirectives(_createVNode(_component_countdown_block, {
          class: "first-block-gamma__countdown",
          "title-text": _ctx.computedOptions.timeoutTitleText,
          "title-styles": _ctx.computedOptions.timeoutTitleStyles,
          "countdown-units-styles": _ctx.computedOptions.timeoutUnitsStyles,
          "countdown-units-labels-styles": _ctx.computedOptions.timeoutUnitsLabelsStyles,
          date: _ctx.date,
          timezone: _ctx.timezone
        }, null, 8, ["title-text", "title-styles", "countdown-units-styles", "countdown-units-labels-styles", "date", "timezone"]), [
          [_directive_element_intersection_animation_directive, { tact: 4 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}