
import { defineComponent, PropType } from 'vue';
import { BackgroundInterface, ImageSettings } from '@/data/e-invitations/types';
import ColorPicker from '@/blocks/color-picker/color-picker.vue';
import ImageUrlsEditor from '@/blocks/image-urls-editor';
import ImageSettingsEditor from '@/blocks/image-settings-editor';

export default defineComponent({
  name: 'block-background-editor',
  components: {
    ColorPicker,
    ImageUrlsEditor,
    ImageSettingsEditor,
  },
  props: {
    value: {
      type: Object as PropType<BackgroundInterface>,
      default: () => ({}),
    },
  },
  emits: ['update:value'],
  methods: {
    onInput<T extends keyof BackgroundInterface>(code: T, value: BackgroundInterface[T]): void {
      if (!this.value && value) {
        this.emitValue({ [code]: value });
      }
      const newValue: BackgroundInterface = {
        ...(this.value || {}),
        [code]: value,
      };
      this.emitValue(newValue);
    },
    emitValue(value: BackgroundInterface | null) {
      this.$emit('update:value', value);
    },
    onSettingsInput(
      resolution: keyof Required<BackgroundInterface>['imageSettings'],
      value: ImageSettings,
    ) {
      this.onInput('imageSettings', {
        ...(this.value?.imageSettings || {}),
        [resolution]: value,
      });
    },
    getSettings(resolution: keyof Required<BackgroundInterface>['imageSettings']): ImageSettings {
      if (this.value?.imageSettings?.[resolution]) {
        return this.value.imageSettings[resolution] || {};
      }
      return {};
    },
  },
});
