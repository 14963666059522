import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (n) => {
      return _createElementVNode("span", {
        key: n,
        class: "loader__line"
      })
    }), 64))
  ]))
}