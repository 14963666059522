
import { defineComponent, PropType } from 'vue';
import { ImagePosition, ImageSize, ImageSettings } from '@/data/e-invitations/types';
import SelectOption from '@/data/types/select-option';
import SelectBlock from '@/blocks/select';

interface PositionOption extends SelectOption {
  value: ImagePosition;
}

interface SizeOption extends SelectOption {
  value: ImageSize;
}

export default defineComponent({
  name: 'image-settings-editor',
  components: {
    SelectBlock,
  },
  props: {
    value: {
      type: Object as PropType<ImageSettings>,
      required: true,
    },
  },
  emits: ['update:value'],
  computed: {
    positionOptions(): PositionOption[] {
      return [
        { label: 'Центр', value: 'center' },
        { label: 'Верх лево', value: 'top-left' },
        { label: 'Верх центр', value: 'top-center' },
        { label: 'Верх право', value: 'top-right' },
        { label: 'Центр лево', value: 'center-left' },
        { label: 'Центр право', value: 'center-right' },
        { label: 'Низ лево', value: 'bottom-left' },
        { label: 'Низ центр', value: 'bottom-center' },
        { label: 'Низ право', value: 'bottom-right' },
      ];
    },
    sizeOptions(): SizeOption[] {
      return [
        { label: 'Растянуть по меньшему краю', value: 'cover' },
        { label: 'Растянуть по большему краю', value: 'contain' },
      ];
    },
  },
  methods: {
    onInput<T extends keyof ImageSettings>(code: T, value: ImageSettings[T]) {
      const newValue: ImageSettings = {
        ...this.value,
        [code]: value,
      };
      this.$emit('update:value', newValue);
    },
  },
});
