import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_countdown_block = _resolveComponent("countdown-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "countdown-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_countdown_block, {
        timezone: _ctx.timezone,
        date: _ctx.date,
        "title-text": _ctx.computedOptions.titleText,
        "title-styles": _ctx.computedOptions.titleStyles,
        "countdown-units-styles": _ctx.computedOptions.countdownUnitStyles,
        "countdown-units-labels-styles": _ctx.computedOptions.countdownUnitLabelsStyles,
        "separator-icon-styles": _ctx.computedOptions.separatorIconStyles
      }, null, 8, ["timezone", "date", "title-text", "title-styles", "countdown-units-styles", "countdown-units-labels-styles", "separator-icon-styles"]), [
        [_directive_element_intersection_animation_directive, { tact: 1 }]
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}