import ProgramBlock3Options, { Item } from '@/data/e-invitations/block-options/program-block-3';
import {
  EDITOR_ELEMENTS,
  EditorConfigFieldType,
  EditorConfigType,
} from '@/data/e-invitations/editor-types';

interface LocalEditorConfigType extends EditorConfigType<ProgramBlock3Options> {
  items: EditorConfigFieldType & {
    contentFields: EditorConfigType<Item>;
    contentFieldsDefaultValue: Required<Item>;
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  itemIconColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет иконок',
  },
  itemTimeStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста элемента расписания в строке с временем',
  },
  itemTitleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста элемента расписания в строке с заголовком',
  },
  itemAddressStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста элемента расписания в строке с адресом',
  },
  separatorColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет разделительной линии',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  items: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Элементы расписания',
    contentFields: {
      icon: {
        editorElement: EDITOR_ELEMENTS.ICON,
        label: 'Настройки иконки',
      },
      time: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст строки "Время"',
        optional: true,
      },
      title: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст строки "Заголовок"',
        optional: true,
      },
      address: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст строки "Адрес"',
        optional: true,
      },
    },
    contentFieldsDefaultValue: {
      icon: 'rings',
      time: '',
      title: '',
      address: '',
    },
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
