import ProgramBlock2Options, { Item } from '@/data/e-invitations/block-options/program-block-2';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

interface LocalEditorConfigType extends EditorConfigType<ProgramBlock2Options> {
  items: EditorConfigFieldType & {
    contentFields: EditorConfigType<Item>;
    contentFieldsDefaultValue: Required<Item>;
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  itemIconStyles: {
    editorElement: EDITOR_ELEMENTS.ICON_STYLES,
    label: 'Стили иконки разделителя элементов программы расписания',
  },
  itemSeparatorColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет линии разделителя элементов программы расписания',
  },
  itemTimeStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста элемента расписания в строке с временем',
  },
  itemTitleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста элемента расписания в строке с заголовком',
  },
  itemAddressStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста элемента расписания в строке с адресом',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  items: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Элементы расписания',
    contentFields: {
      time: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст строки "Время"',
        optional: true,
      },
      title: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст строки "Заголовок"',
        optional: true,
      },
      address: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст строки "Адрес"',
        optional: true,
      },
    },
    contentFieldsDefaultValue: {
      time: '',
      title: '',
      address: '',
    },
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
