import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-invitations-feedback" }
const _hoisted_2 = { class: "page-invitations-feedback__head" }
const _hoisted_3 = {
  key: 1,
  class: "page-invitations-feedback__summary"
}
const _hoisted_4 = {
  key: 0,
  class: "page-invitations-feedback__summary-item"
}
const _hoisted_5 = {
  key: 1,
  class: "page-invitations-feedback__summary-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_button_block = _resolveComponent("button-block")!
  const _component_table_block = _resolveComponent("table-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_block, {
        class: "text-align-center",
        font: "cormorant",
        weight: 700,
        color: "rgba(28, 28, 28, 1)",
        size: [50, 24]
      }, {
        default: _withCtx(() => [
          _createTextVNode("РЕЗУЛЬТАТЫ ОПРОСА")
        ]),
        _: 1
      }),
      _createVNode(_component_button_block, {
        class: "page-invitations-feedback__head-button",
        icon: { name: 'download', size: [18, 18] },
        loading: _ctx.isExcelDownloading,
        onClick: _ctx.downloadExcelResults
      }, {
        default: _withCtx(() => [
          _createTextVNode("Excel")
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    (_ctx.rows.length > 0)
      ? (_openBlock(), _createBlock(_component_table_block, {
          key: 0,
          class: "page-invitations-feedback__table",
          columns: _ctx.columns,
          rows: _ctx.rows
        }, {
          [`cell:${_ctx.PRESENCE_STATUS_CODE}`]: _withCtx(({ row }) => [
            _createTextVNode(_toDisplayString(_ctx.getPresenceStatus(row.presenceStatus)), 1)
          ]),
          _: 2
        }, 1032, ["columns", "rows"]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.hasPresenceStatusBlock)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_text_block, {
                  class: "page-invitations-feedback__summary-item-title",
                  font: "cormorant",
                  weight: 700,
                  color: "rgba(28, 28, 28, 1)",
                  size: [40, 18]
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Присутствие")
                  ]),
                  _: 1
                }),
                (_ctx.presenceStatusRows.length > 0)
                  ? (_openBlock(), _createBlock(_component_table_block, {
                      key: 0,
                      columns: _ctx.presenceStatusColumns,
                      rows: _ctx.presenceStatusRows
                    }, null, 8, ["columns", "rows"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasBeveragesBlock)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_text_block, {
                  class: "page-invitations-feedback__summary-item-title",
                  font: "cormorant",
                  weight: 700,
                  color: "rgba(28, 28, 28, 1)",
                  size: [40, 18]
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Предпочтения по напиткам")
                  ]),
                  _: 1
                }),
                (_ctx.beveragesRows.length > 0)
                  ? (_openBlock(), _createBlock(_component_table_block, {
                      key: 0,
                      columns: _ctx.beveragesColumns,
                      rows: _ctx.beveragesRows
                    }, null, 8, ["columns", "rows"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}