import { UNION_BLOCK_CODES } from '@/data/e-invitations/block-codes';
import { UnionOptionsType } from '@/data/e-invitations/blocks';
import AcceptBlock1DefaultOptions from '@/data/e-invitations/block-default-options/accept-block-1';
import AcceptBlock2DefaultOptions from '@/data/e-invitations/block-default-options/accept-block-2';
import ButtonBlockDefaultOptions from '@/data/e-invitations/block-default-options/button-block';
import ChoiceBlockDefaultOptions from '@/data/e-invitations/block-default-options/choice-block';
import BeveragesBlockDefaultOptions from '@/data/e-invitations/block-default-options/beverages-block';
import CountdownBlockDefaultOptions from '@/data/e-invitations/block-default-options/countdown-block';
import DresscodeBlockDefaultOptions from '@/data/e-invitations/block-default-options/dresscode-block';
import EndBlockDefaultOptions from '@/data/e-invitations/block-default-options/end-block';
import FirstBlockAlphaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-alpha';
import FirstBlockBettaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-betta';
import FirstBlockDeltaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-delta';
import FirstBlockEpsilonDefaultOptions from '@/data/e-invitations/block-default-options/first-block-epsilon';
import FirstBlockGammaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-gamma';
import FirstBlockZetaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-zeta';
import FirstBlockEtaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-eta';
import FirstBlockTetaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-teta';
import FirstBlockIotaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-iota';
import FirstBlockNuDefaultOptions from '@/data/e-invitations/block-default-options/first-block-nu';
import HistoryBlockDefaultOptions from '@/data/e-invitations/block-default-options/history-block';
import PhotoBlockDefaultOptions from '@/data/e-invitations/block-default-options/photo-block';
import PlaceBlockDefaultOptions from '@/data/e-invitations/block-default-options/place-block';
import PresenceBlockDefaultOptions from '@/data/e-invitations/block-default-options/presence-block';
import ProgramBlock1DefaultOptions from '@/data/e-invitations/block-default-options/program-block-1';
import ProgramBlock2DefaultOptions from '@/data/e-invitations/block-default-options/program-block-2';
import ProgramBlock3DefaultOptions from '@/data/e-invitations/block-default-options/program-block-3';
import TextBlockDefaultOptions from '@/data/e-invitations/block-default-options/text-block';

export default function getBlockDefaultOptions(code: UNION_BLOCK_CODES): UnionOptionsType {
  const componentMap: Record<UNION_BLOCK_CODES, UnionOptionsType> = {
    FIRST_BLOCK_ALPHA: FirstBlockAlphaDefaultOptions,
    FIRST_BLOCK_BETTA: FirstBlockBettaDefaultOptions,
    FIRST_BLOCK_GAMMA: FirstBlockGammaDefaultOptions,
    FIRST_BLOCK_DELTA: FirstBlockDeltaDefaultOptions,
    FIRST_BLOCK_EPSILON: FirstBlockEpsilonDefaultOptions,
    FIRST_BLOCK_ZETA: FirstBlockZetaDefaultOptions,
    FIRST_BLOCK_ETA: FirstBlockEtaDefaultOptions,
    FIRST_BLOCK_TETA: FirstBlockTetaDefaultOptions,
    FIRST_BLOCK_IOTA: FirstBlockIotaDefaultOptions,
    FIRST_BLOCK_NU: FirstBlockNuDefaultOptions,
    ACCEPT_BLOCK_1: AcceptBlock1DefaultOptions,
    ACCEPT_BLOCK_2: AcceptBlock2DefaultOptions,
    BUTTON_BLOCK: ButtonBlockDefaultOptions,
    CHOICE_BLOCK: ChoiceBlockDefaultOptions,
    BEVERAGES_BLOCK: BeveragesBlockDefaultOptions,
    TIMER_BLOCK: CountdownBlockDefaultOptions,
    DRESSCODE_BLOCK: DresscodeBlockDefaultOptions,
    END_BLOCK: EndBlockDefaultOptions,
    HISTORY_BLOCK: HistoryBlockDefaultOptions,
    PHOTO_BLOCK: PhotoBlockDefaultOptions,
    PLACE_BLOCK: PlaceBlockDefaultOptions,
    PRESENCE_BLOCK: PresenceBlockDefaultOptions,
    PROGRAM_BLOCK_1: ProgramBlock1DefaultOptions,
    PROGRAM_BLOCK_2: ProgramBlock2DefaultOptions,
    PROGRAM_BLOCK_3: ProgramBlock3DefaultOptions,
    TEXT_BLOCK: TextBlockDefaultOptions,
  };
  return componentMap[code];
}
