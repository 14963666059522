import PresenceBlockOptions, {
  AddButtonType,
  NameDeleteButtonWeight,
} from '@/data/e-invitations/block-options/presence-block';
import {
  EDITOR_ELEMENTS,
  EditorConfigFieldType,
  EditorConfigType,
} from '@/data/e-invitations/editor-types';
import { inputTypeEditorConfig } from '@/data/e-invitations/common-editors-configs';
import SelectOption from '@/data/types/select-option';

interface TypedOptionType<T extends string> extends SelectOption {
  value: T;
}

interface LocalEditorConfigType extends EditorConfigType<PresenceBlockOptions> {
  nameDeleteButtonWeight: EditorConfigFieldType & {
    options: TypedOptionType<NameDeleteButtonWeight>[];
  };
  addButtonType: EditorConfigFieldType & {
    options: TypedOptionType<AddButtonType>[];
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  checkboxStyles: {
    editorElement: EDITOR_ELEMENTS.CHECKBOX_STYLES,
    label: 'Стили чекбоксов',
  },
  subtitleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили подзаголовка',
  },
  inputType: inputTypeEditorConfig,
  inputStyles: {
    editorElement: EDITOR_ELEMENTS.INPUT_STYLES,
    label: 'Стили инпута',
  },
  nameDeleteButtonWeight: {
    editorElement: EDITOR_ELEMENTS.SELECT,
    label: 'Жирность кнопки удалить имя',
    options: [
      {
        label: 'regular',
        value: 'regular',
      },
      {
        label: 'medium',
        value: 'medium',
      },
    ],
  },
  nameDeleteButtonColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет кнопки удалить имя',
  },
  addButtonType: {
    editorElement: EDITOR_ELEMENTS.SELECT,
    label: 'Тип кнопки добавить имя',
    options: [
      {
        label: 'Плюс',
        value: 'plus',
      },
      {
        label: 'Кнопка',
        value: 'button',
      },
    ],
  },
  plusColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет плюса "Добавить имя"',
    isVisible: (block: PresenceBlockOptions) => block.addButtonType === 'plus',
    optional: true,
  },
  buttonStyles: {
    editorElement: EDITOR_ELEMENTS.BUTTON_STYLES,
    label: 'Стили кнопки',
    isVisible: (block: PresenceBlockOptions) => block.addButtonType === 'button',
    optional: true,
  },
  noteStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили сообщения под кнопкой',
    isVisible: (block: PresenceBlockOptions) => block.addButtonType === 'button',
    optional: true,
  },
  allowPostponeStatus: {
    editorElement: EDITOR_ELEMENTS.CHECKBOX,
    label: 'Отображать чекбокс "отвечу позже"',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  acceptCheckboxLabel: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст чекбокса "Приду"',
  },
  declineCheckboxLabel: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст чекбокса "Не приду"',
  },
  postponeCheckboxLabel: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст чекбокса "Отвечу позже"',
    isVisible: (block: PresenceBlockOptions) => block.allowPostponeStatus,
    optional: true,
  },
  subtitleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст подзаголовка',
  },
  firstNamePlaceholder: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Плейсхолдер инпута с именем',
  },
  surNamePlaceholder: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Плейсхолдер инпута с фамилией',
  },
  noteText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст сообщения под кнопкой',
  },
  addButtonText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст на кнопке "Добавить человека"',
    isVisible: (block: PresenceBlockOptions) => block.addButtonType === 'button',
    optional: true,
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
