import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: "names-with-and",
    style: _normalizeStyle({ width: `${_ctx.fullStringWidth}px` })
  }, [
    _createVNode(_component_text_block, _mergeProps({
      class: "names-with-and__full-string",
      ref: "fullString"
    }, _ctx.textBind), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.fullString), 1)
      ]),
      _: 1
    }, 16),
    _createVNode(_component_text_block, _mergeProps({ class: "align-self-start" }, _ctx.textBind), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.names[0].firstName), 1)
      ]),
      _: 1
    }, 16),
    _createVNode(_component_text_block, _mergeProps({ class: "align-self-center" }, _ctx.textBind), {
      default: _withCtx(() => [
        _createTextVNode(" и ")
      ]),
      _: 1
    }, 16),
    _createVNode(_component_text_block, _mergeProps({ class: "align-self-end" }, _ctx.textBind), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.names[1].firstName), 1)
      ]),
      _: 1
    }, 16)
  ], 4))
}