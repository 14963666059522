import FirstBlockAlphaOptions from '@/data/e-invitations/block-options/first-block-alpha';

const defaultOptions: FirstBlockAlphaOptions = {
  namesStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  ampersandColor: 'rgba(255, 255, 255, 0.3)',
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  arrowColor: 'rgba(255, 255, 255, 0.5)',
  background: {
    imageUrls: {
      x1: 'https://i.imgur.com/FiMNnER.jpg',
    },
  },
};

export default defaultOptions;
