import ButtonBlockOptions from '@/data/e-invitations/block-options/button-block';

const defaultOptions: ButtonBlockOptions = {
  type: 'submit',
  link: null,
  buttonStyles: {
    textStyles: {
      font: 'century-gothic',
      weight: 700,
      color: 'rgba(31, 31, 31, 1)',
    },
    borderColor: 'rgba(0, 0, 0, 1)',
    hoverBackgroundColor: 'rgba(0, 0, 0, 1)',
    hoverTextColor: 'rgba(255, 255, 255, 1)',
  },
  buttonText: 'Отправить',
  buttonSuccessText: 'Отправлено',
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
