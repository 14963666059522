
import { defineComponent, PropType } from 'vue';
import PlaceBlockInterfaceOptions from '@/data/e-invitations/block-options/place-block';
import defaultOptions from '@/data/e-invitations/block-default-options/place-block';
import BlockWithBackground from '@/blocks/block-with-background';
import ImgBlock from '@/blocks/img';
import TextBlock from '@/blocks/text';
import MapBlock from '@/blocks/map';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';
import { ImageUrls } from '@/data/e-invitations/types';

export default defineComponent({
  inheritAttrs: false,
  name: 'place-block-inv',
  components: {
    BlockWithBackground,
    ImgBlock,
    TextBlock,
    MapBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<PlaceBlockInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): PlaceBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
    imageUrls(): ImageUrls | undefined {
      return this.computedOptions.mapImage || this.computedOptions.placeImage;
    },
  },
});
