import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dresscode-colors-icons-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_styles_editor = _resolveComponent("icon-styles-editor")!
  const _component_list_editor = _resolveComponent("list-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_styles_editor, {
      class: "dresscode-colors-icons-editor__item",
      value: _ctx.value?.iconStyles,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('iconStyles', $event)))
    }, null, 8, ["value"]),
    _createVNode(_component_list_editor, {
      class: "dresscode-colors-icons-editor__item",
      "row-component": "color-picker",
      value: _ctx.value?.colors,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('colors', $event)))
    }, null, 8, ["value"])
  ]))
}