import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["multiple", "accept"]
const _hoisted_2 = {
  class: "file-input__content",
  tabindex: "-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-input",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.$refs.input.click && _ctx.$refs.input.click(...args)))
  }, [
    _createElementVNode("input", {
      class: "file-input__input",
      type: "file",
      ref: "input",
      multiple: _ctx.multiple,
      accept: _ctx.accept,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}