import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "image-settings-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_block = _resolveComponent("select-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_select_block, {
      class: "image-settings-editor__item",
      options: _ctx.positionOptions,
      value: _ctx.value.position,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('position', $event)))
    }, null, 8, ["options", "value"]),
    _createVNode(_component_select_block, {
      class: "image-settings-editor__item",
      options: _ctx.sizeOptions,
      value: _ctx.value.size,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('size', $event)))
    }, null, 8, ["options", "value"])
  ]))
}