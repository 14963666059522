
import { defineComponent, PropType } from 'vue';
import { SideBackground, FullSize } from '@/data/e-invitations/page';
import SelectOption from '@/data/types/select-option';
import ImagesUrlsEditor from '@/blocks/image-urls-editor';
import SelectBlock from '@/blocks/select';
import InputBlock from '@/blocks/input';

interface FullSizeOption extends SelectOption {
  value: FullSize;
}

export default defineComponent({
  name: 'side-background-editor',
  components: {
    ImagesUrlsEditor,
    SelectBlock,
    InputBlock,
  },
  props: {
    value: Object as PropType<SideBackground>,
  },
  emits: ['update:value'],
  computed: {
    fullSizeOptions(): FullSizeOption[] {
      return [
        {
          label: 'Растянуть по высоте',
          value: 'height',
        },
        {
          label: 'Растянуть по ширине',
          value: 'width',
        },
      ];
    },
    offset(): SideBackground['offset'] | Record<string, never> {
      if (!this.value?.imageUrls) {
        return {};
      }
      return this.value.offset || {};
    },
  },
  methods: {
    onInput<T extends keyof SideBackground>(code: T, value: SideBackground[T]) {
      if (code === 'imageUrls' && !value) {
        this.$emit('update:value', null);
        return;
      }
      const newValue = {
        ...this.value,
        [code]: value,
      };
      this.$emit('update:value', newValue);
    },
  },
});
