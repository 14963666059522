import FirstBlockTetaOptions from '@/data/e-invitations/block-options/first-block-teta';
import { EDITOR_ELEMENTS, EditorConfigType } from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<FirstBlockTetaOptions> = {
  dayStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили "дня"',
  },
  monthStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили "месяца"',
  },
  yearStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили "года"',
  },
  iconStyles: {
    editorElement: EDITOR_ELEMENTS.ICON_STYLES,
    label: 'Стили иконки',
  },
  imageUrls: {
    editorElement: EDITOR_ELEMENTS.IMAGE_URLS,
    label: 'Ссылки на фото',
  },
  movingOverlayColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет двигающейся плашки за именами',
  },
  staticOverlayColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет статичной плашки за именами',
  },
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
