import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_img_block = _resolveComponent("img-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!

  return (_openBlock(), _createBlock(_component_block_with_background, {
    class: "photo-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createVNode(_component_img_block, {
        class: "photo-block-inv__img",
        src: _ctx.computedOptions.imageUrls.x1,
        src2x: _ctx.computedOptions.imageUrls.x2,
        "mobile-src": _ctx.computedOptions.imageUrls.x1Mobile,
        "mobile-src2x": _ctx.computedOptions.imageUrls.x2Mobile
      }, null, 8, ["src", "src2x", "mobile-src", "mobile-src2x"])
    ]),
    _: 1
  }, 8, ["background"]))
}