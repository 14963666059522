import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_block = _resolveComponent("input-block")!
  const _component_input_holder = _resolveComponent("input-holder")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.bem('presence-block-inv__name', { type: _ctx.inputType }))
  }, [
    _createVNode(_component_input_holder, {
      class: _normalizeClass(_ctx.bem('presence-block-inv__name-item', { type: _ctx.inputType })),
      "error-codes": _ctx.firstNameErrorsCodes
    }, {
      default: _withCtx(() => [
        _createVNode(_component_input_block, {
          class: "width-100",
          styles: _ctx.inputStyles,
          ref: "firstName",
          type: _ctx.inputType,
          placeholder: _ctx.firstNamePlaceholder,
          "has-error": _ctx.firstNameErrorsCodes.size !== 0,
          value: _ctx.firstName,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitNameEditing({ value: $event })))
        }, null, 8, ["styles", "type", "placeholder", "has-error", "value"])
      ]),
      _: 1
    }, 8, ["class", "error-codes"]),
    _createVNode(_component_input_holder, {
      class: _normalizeClass(_ctx.bem('presence-block-inv__name-item', { type: _ctx.inputType })),
      "error-codes": _ctx.surNameErrorsCodes
    }, {
      default: _withCtx(() => [
        _createVNode(_component_input_block, {
          class: "width-100",
          styles: _ctx.inputStyles,
          ref: "surName",
          type: _ctx.inputType,
          placeholder: _ctx.surNamePlaceholder,
          "has-error": _ctx.surNameErrorsCodes.size !== 0,
          value: _ctx.surName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitNameEditing({ isSurName: true, value: $event })))
        }, null, 8, ["styles", "type", "placeholder", "has-error", "value"])
      ]),
      _: 1
    }, 8, ["class", "error-codes"]),
    (_ctx.canDelete)
      ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          class: _normalizeClass(_ctx.bem('presence-block-inv__name-delete-button', { weight: _ctx.deleteButtonWeight })),
          stroke: _ctx.deleteButtonColor,
          name: "cross",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete')))
        }, null, 8, ["class", "stroke"]))
      : _createCommentVNode("", true)
  ], 2))
}