import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-betta__content content-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_names_with_end = _resolveComponent("names-with-end")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-betta",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-betta__title" }, _ctx.computedOptions.titleStyles, { size: [90, 48] }), {
          default: _withCtx(() => [
            _createTextVNode("Дорогие гости!")
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
          class: _ctx.bem('first-block-betta__text-block', { '1': true })
        }, _ctx.computedOptions.block1TextStyles), {
          default: _withCtx(() => [
            _createTextVNode(" С радостью сообщаем, что мы собираемся пожениться. И этот важный для нас день мы хотим разделить с Вами. ")
          ]),
          _: 1
        }, 16, ["class"])), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-betta__date" }, _ctx.computedOptions.dateStyles, { size: [60, 36] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 3 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
          class: _ctx.bem('first-block-betta__text-block', { '2': true })
        }, _ctx.computedOptions.block2TextStyles), {
          default: _withCtx(() => [
            _createTextVNode(" С радостью сообщаем, что мы собираемся пожениться. И этот важный для нас день мы хотим разделить с Вами. ")
          ]),
          _: 1
        }, 16, ["class"])), [
          [_directive_element_intersection_animation_directive, { tact: 4 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-betta__with-love text-align-center" }, _ctx.computedOptions.withLoveTextStyles, { size: [30, 18] }), {
          default: _withCtx(() => [
            _createTextVNode("С любовью, ваши")
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 5 }]
        ]),
        _withDirectives(_createVNode(_component_names_with_end, {
          class: "first-block-betta__names",
          names: _ctx.names,
          styles: _ctx.computedOptions.namesStyles
        }, null, 8, ["names", "styles"]), [
          [_directive_element_intersection_animation_directive, { tact: 6 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}