import FirstBlockTetaOptions from '@/data/e-invitations/block-options/first-block-teta';

const defaultOptions: FirstBlockTetaOptions = {
  dayStyles: {
    font: 'felixty',
    weight: 400,
    color: 'rgba(51, 46, 43, 1)',
  },
  monthStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(51, 46, 43, 1)',
  },
  yearStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(51, 46, 43, 1)',
  },
  iconStyles: {
    name: 'heart-filled',
    fill: 'rgba(153, 96, 81, 1)',
  },
  imageUrls: {
    x1: '',
  },
  movingOverlayColor: 'rgba(90, 106, 96, 0.65)',
  staticOverlayColor: 'rgba(90, 106, 96, 1)',
  namesStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
