import PhotoBlockOptions from '@/data/e-invitations/block-options/photo-block';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<PhotoBlockOptions> = {
  imageUrls: {
    editorElement: EDITOR_ELEMENTS.IMAGE_URLS,
    label: 'Ссылки на картинку',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
