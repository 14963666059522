import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-styles-editor" }
const _hoisted_2 = { class: "button-styles-editor__settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_styles_editor = _resolveComponent("text-styles-editor")!
  const _component_invitations_constructor_field = _resolveComponent("invitations-constructor-field")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_button_block = _resolveComponent("button-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_invitations_constructor_field, { class: "button-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Стили текста")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_text_styles_editor, {
            value: _ctx.value.textStyles,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('textStyles', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "button-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Цвет заднего фона")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_color_picker, {
            value: _ctx.value.backgroundColor,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('backgroundColor', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "button-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Цвет бордера")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_color_picker, {
            value: _ctx.value.borderColor,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('borderColor', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "button-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Цвет текста при наведении")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_color_picker, {
            value: _ctx.value.hoverTextColor,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('hoverTextColor', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "button-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Цвет заднего фона при наведении")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_color_picker, {
            value: _ctx.value.hoverBackgroundColor,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInput('hoverBackgroundColor', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "button-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Цвет бордера при наведении")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_color_picker, {
            value: _ctx.value.hoverBorderColor,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.onInput('hoverBorderColor', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_button_block, {
      class: "button-styles-editor__preview",
      styles: _ctx.value
    }, {
      default: _withCtx(() => [
        _createTextVNode("Превью")
      ]),
      _: 1
    }, 8, ["styles"])
  ]))
}