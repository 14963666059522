
import { defineComponent, PropType } from 'vue';
import TextBlockInterfaceOptions, { ContactType } from '@/data/e-invitations/block-options/text-block';
import defaultOptions from '@/data/e-invitations/block-default-options/text-block';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon/svg-icon.vue';
import { formatDate } from '@/tools/datetime';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';
import SpriteSvgIcon from '@/data/types/sprite-svg-icon';

export default defineComponent({
  inheritAttrs: false,
  name: 'text-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    SvgIcon,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<TextBlockInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): TextBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
  methods: {
    formatDate,
    getContactIcon(type: ContactType): SpriteSvgIcon {
      const iconsMap: Record<ContactType, SpriteSvgIcon> = {
        inst: 'inst-filled',
        phone: 'phone-filled',
        tg: 'tg',
        email: 'mail-filled',
      };
      return iconsMap[type];
    },
    getContactHref({ type, value }: { type: ContactType, value: string }): string {
      if (type === 'inst') {
        return `https://www.instagram.com/${value}/`;
      }
      if (type === 'phone') {
        return `tel:${value}`;
      }
      if (type === 'tg') {
        return `https://t.me/${value}`;
      }
      return `mailto:${value}`;
    },
    getContactTarget(type: ContactType): string {
      if ((['phone', 'email'] as ContactType[]).includes(type)) {
        return '_self';
      }
      return '_blank';
    },
  },
});
