
import { defineComponent, PropType } from 'vue';
import { Color } from '@/data/e-invitations/types';
import InputBlock from '@/blocks/input';
import ColorPicker from '@/blocks/color-picker';
import SvgIcon from '@/blocks/svg-icon';

type Rows = (string | Color)[];
type RowComponent = 'input' | 'color-picker';

export default defineComponent({
  name: 'list-editor',
  components: {
    InputBlock,
    ColorPicker,
    SvgIcon,
  },
  props: {
    value: {
      type: Array as PropType<Rows>,
      default: () => [],
    },
    rowComponent: {
      type: String as PropType<RowComponent>,
      default: 'input',
    },
  },
  emits: ['update:value'],
  computed: {
    rows(): Rows {
      if (this.value.length > 0) {
        return this.value;
      }
      return [''];
    },
    component(): string {
      const componentsMap: Record<RowComponent, string> = {
        input: 'InputBlock',
        'color-picker': 'ColorPicker',
      };
      return componentsMap[this.rowComponent];
    },
  },
  methods: {
    editRow(index: number, value: string) {
      const valueCopy = [...this.rows];
      valueCopy.splice(index, 1, value);
      this.emitValue(valueCopy);
    },
    deleteRow(index: number) {
      this.emitValue(this.rows.filter((row, rowIndex) => rowIndex !== index));
    },
    addRow() {
      this.emitValue([...this.rows, '']);
    },
    emitValue(value: Rows) {
      this.$emit('update:value', value);
    },
  },
});
