
import { defineComponent, PropType } from 'vue';
// eslint-disable-next-line
// @ts-ignore
import { yandexMap, ymapMarker } from 'vue-yandex-maps'; // docs - https://vue-yandex-maps.github.io/guide/Map.html#events
import Coords from '@/data/types/coords';

export default defineComponent({
  name: 'map-block',
  components: {
    yandexMap,
    ymapMarker,
  },
  props: {
    coords: Array as unknown as PropType<Coords>,
  },
});
