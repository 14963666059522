import SpriteSvgIcon from '@/data/types/sprite-svg-icon';
import { getStaticUrl } from '@/tools/index';

class SpriteManager {
  private addedIcons: SpriteSvgIcon[] = [];

  private svg: SVGSVGElement;

  private svgSpec = 'http://www.w3.org/2000/svg';

  constructor() {
    const app = document.getElementById('app');
    const svg = document.createElementNS(this.svgSpec, 'svg');
    svg.setAttribute(
      'style',
      'width: 0; height: 0; overflow: hidden; position: absolute',
    );
    document.body.insertBefore(svg, app);
    this.svg = svg as SVGSVGElement;
  }

  async addIcon(name: SpriteSvgIcon): Promise<void> {
    if (this.addedIcons.includes(name)) {
      return;
    }
    this.addedIcons.push(name);
    const symbolElement = await this.loadSvgCode(name);
    this.svg.appendChild(symbolElement);
  }

  private async loadSvgCode(name: string): Promise<Element> {
    const svgResponse: Response = await fetch(getStaticUrl(`images/sprite-svg-icons/${name}.svg`));
    const uint8ArraySvg = await svgResponse.body?.getReader().read();
    const svgString = new TextDecoder().decode(uint8ArraySvg?.value);
    const div = document.createElementNS(this.svgSpec, 'div');
    div.innerHTML = svgString.trim();
    const svgElement = div.firstChild as HTMLElement;
    const symbolElement = document.createElementNS(this.svgSpec, 'symbol');
    symbolElement.setAttribute('xmlns', this.svgSpec);
    const viewBox = svgElement.getAttribute('viewBox') as string;
    symbolElement.setAttribute('viewBox', viewBox);
    symbolElement.setAttribute('id', `icon-${name}`);
    symbolElement.innerHTML = svgElement.innerHTML;
    return symbolElement;
  }
}

const spriteManager = new SpriteManager();

export default spriteManager;
