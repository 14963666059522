import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(_ctx.bem('input', { type: _ctx.type, error: _ctx.hasError })),
    type: "text",
    style: _normalizeStyle(_ctx.style),
    placeholder: _ctx.placeholder,
    value: _ctx.value,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event.target.value)))
  }, null, 46, _hoisted_1))
}