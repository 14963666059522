import AcceptBlock2Options from '@/data/e-invitations/block-options/accept-block-2';

const defaultOptions: AcceptBlock2Options = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  textBlocksStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  dateStyles: {
    font: 'montserrat',
    weight: 700,
    color: 'rgba(220, 173, 152, 1)',
  },
  titleText: 'Подтверждение',
  blocksText: [
    {
      text: 'Пожалуйста подтвердите \nВаше присутствие, отправив \n ответное сообщение',
    },
    {
      text: 'Не забудьте указать в сообщении своих близких, с кем Вы планируете посетить нашу свадьбу',
      desktopWidth: '470px',
    },
    {
      text: 'Так же мы будем благодарны, \nесли Вы поделитесь предпочтениями в напитках',
      desktopWidth: '647px',
    },
  ],
  textBeforeDate: 'Мы будем ждать ответ \nдо ',
  dateText: '31 марта 2022',
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
