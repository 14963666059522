import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "validation-errors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.errorCodes)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.errorCodes, (code) => {
          return (_openBlock(), _createBlock(_component_text_block, _mergeProps({
            key: code,
            class: "validation-errors__error"
          }, _ctx.errorBind), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getErrorText(code)), 1)
            ]),
            _: 2
          }, 1040))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.customErrors.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.customErrors, (error) => {
          return (_openBlock(), _createBlock(_component_text_block, _mergeProps({
            key: error,
            class: "validation-errors__error"
          }, _ctx.errorBind), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(error), 1)
            ]),
            _: 2
          }, 1040))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}