import EndBlockOptions from '@/data/e-invitations/block-options/end-block';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<EndBlockOptions> = {
  fullScreen: {
    editorElement: EDITOR_ELEMENTS.CHECKBOX,
    label: 'Растянуть на весь экран',
  },
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  iconStyles: {
    editorElement: EDITOR_ELEMENTS.ICON_STYLES,
    label: 'Стили иконки',
  },
  title: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
