
import { defineComponent } from 'vue';
import { Page } from '@/data/e-invitations/page';
import { Blocks } from '@/data/e-invitations/blocks';
import { FIRST_BLOCK_CODES, BLOCKS_CODES, UNION_BLOCK_CODES } from '@/data/e-invitations/block-codes';
import {
  FormPublishMethods,
  FormWidthdrowMethods,
  Name,
  PRESENCE_STATUSES,
} from '@/data/e-invitations/types';
import { getDemoInvitation } from '@/tools/demo-invitation-storage';
import ImgBlock from '@/blocks/img';
import FirstBlockAlpha from '@/blocks/first-block-alpha';
import FirstBlockBetta from '@/blocks/first-block-betta';
import FirstBlockGamma from '@/blocks/first-block-gamma';
import FirstBlockDelta from '@/blocks/first-block-delta';
import FirstBlockEpsilon from '@/blocks/first-block-epsilon';
import FirstBlockZeta from '@/blocks/first-block-zeta';
import FirstBlockEta from '@/blocks/first-block-eta';
import FirstBlockTeta from '@/blocks/first-block-teta';
import FirstBlockIota from '@/blocks/first-block-iota';
import FirstBlockNu from '@/blocks/first-block-nu';
import TextBlockInv from '@/blocks/text-block-inv';
import CountdownBlockInv from '@/blocks/countdown-block-inv';
import ProgramBlock1Inv from '@/blocks/program-block-1-inv';
import ProgramBlock2Inv from '@/blocks/program-block-2-inv';
import ProgramBlock3Inv from '@/blocks/program-block-3-inv';
import PlaceBlockInv from '@/blocks/place-block-inv';
import DresscodeBlockInv from '@/blocks/dresscode-block-inv';
import PresenceBlockInv from '@/blocks/presence-block-inv';
import ChoiceBlockInv from '@/blocks/choice-block-inv';
import BeveragesBlockInv from '@/blocks/beverages-block-inv';
import PhotoBlockInv from '@/blocks/photo-block-inv';
import ButtonBlockInv from '@/blocks/button-block-inv';
import AcceptBlock1Inv from '@/blocks/accept-block-1-inv';
import AcceptBlock2Inv from '@/blocks/accept-block-2-inv';
import EndBlockInv from '@/blocks/end-block-inv';
import HistoryBlockInv from '@/blocks/history-block-inv';

interface BackgroundOffsetStyleVars {
  '--background-offset-desktop'?: string | undefined;
  '--background-offset-mobile'?: string | undefined;
}

export default defineComponent({
  name: 'page-demo-e-invitations',
  components: {
    ImgBlock,
    FirstBlockAlpha,
    FirstBlockBetta,
    FirstBlockGamma,
    FirstBlockDelta,
    FirstBlockEpsilon,
    FirstBlockZeta,
    FirstBlockEta,
    FirstBlockTeta,
    FirstBlockIota,
    FirstBlockNu,
    TextBlockInv,
    CountdownBlockInv,
    ProgramBlock1Inv,
    ProgramBlock2Inv,
    ProgramBlock3Inv,
    PlaceBlockInv,
    DresscodeBlockInv,
    PresenceBlockInv,
    ChoiceBlockInv,
    BeveragesBlockInv,
    PhotoBlockInv,
    ButtonBlockInv,
    AcceptBlock1Inv,
    AcceptBlock2Inv,
    EndBlockInv,
    HistoryBlockInv,
  },
  data: () => ({
    page: {} as Page,
    blocksMethods: [] as FormPublishMethods[],
    isSending: false,
    successfullySent: false,
    guests: [{ firstName: '', surName: '' }] as Name[],
    presenceStatus: PRESENCE_STATUSES.ACCEPT,
  }),
  async created() {
    this.page = getDemoInvitation();
  },
  computed: {
    pageFieldsWithoutBlocks(): Omit<Page, 'blocks'> {
      interface pageWithOptionalBlocks extends Omit<Page, 'blocks'> {
        blocks?: Blocks;
      }
      const pageCopy: pageWithOptionalBlocks = { ...this.page };
      delete pageCopy.blocks;
      return pageCopy;
    },
    background() {
      return this.page.background;
    },
    filteredBlocks(): Blocks {
      const { blocks } = this.page;
      if (!blocks) {
        return [];
      }
      return blocks.filter(({ code }) => !(
        code === BLOCKS_CODES.CHOICE_BLOCK
        && this.presenceStatus === PRESENCE_STATUSES.POSTPONE
      ));
    },
  },
  methods: {
    getComponentName(code: UNION_BLOCK_CODES): string {
      const codeComponentMapping: { [key in UNION_BLOCK_CODES]: string } = {
        [FIRST_BLOCK_CODES.ALPHA]: 'FirstBlockAlpha',
        [FIRST_BLOCK_CODES.BETTA]: 'FirstBlockBetta',
        [FIRST_BLOCK_CODES.GAMMA]: 'FirstBlockGamma',
        [FIRST_BLOCK_CODES.DELTA]: 'FirstBlockDelta',
        [FIRST_BLOCK_CODES.EPSILON]: 'FirstBlockEpsilon',
        [FIRST_BLOCK_CODES.ZETA]: 'FirstBlockZeta',
        [FIRST_BLOCK_CODES.ETA]: 'FirstBlockEta',
        [FIRST_BLOCK_CODES.TETA]: 'FirstBlockTeta',
        [FIRST_BLOCK_CODES.IOTA]: 'FirstBlockIota',
        [FIRST_BLOCK_CODES.NU]: 'FirstBlockNu',
        [BLOCKS_CODES.TEXT_BLOCK]: 'TextBlockInv',
        [BLOCKS_CODES.COUNTDOWN_BLOCK]: 'CountdownBlockInv',
        [BLOCKS_CODES.PROGRAM_BLOCK_1]: 'ProgramBlock1Inv',
        [BLOCKS_CODES.PROGRAM_BLOCK_2]: 'ProgramBlock2Inv',
        [BLOCKS_CODES.PROGRAM_BLOCK_3]: 'ProgramBlock3Inv',
        [BLOCKS_CODES.PLACE_BLOCK]: 'PlaceBlockInv',
        [BLOCKS_CODES.DRESSCODE_BLOCK]: 'DresscodeBlockInv',
        [BLOCKS_CODES.PRESENCE_BLOCK]: 'PresenceBlockInv',
        [BLOCKS_CODES.CHOICE_BLOCK]: 'ChoiceBlockInv',
        [BLOCKS_CODES.BEVERAGES_BLOCK]: 'BeveragesBlockInv',
        [BLOCKS_CODES.PHOTO_BLOCK]: 'PhotoBlockInv',
        [BLOCKS_CODES.BUTTON_BLOCK]: 'ButtonBlockInv',
        [BLOCKS_CODES.ACCEPT_BLOCK_1]: 'AcceptBlock1Inv',
        [BLOCKS_CODES.ACCEPT_BLOCK_2]: 'AcceptBlock2Inv',
        [BLOCKS_CODES.END_BLOCK]: 'EndBlockInv',
        [BLOCKS_CODES.HISTORY_BLOCK]: 'HistoryBlockInv',
      };
      return codeComponentMapping[code];
    },
    getStyleVarsBackgroundOffsets(
      params: { mobile: number, desktop: number },
    ): BackgroundOffsetStyleVars {
      const result: BackgroundOffsetStyleVars = {};
      if (!params) {
        return result;
      }
      const { desktop, mobile } = params;
      if (desktop) {
        result['--background-offset-desktop'] = `${desktop}px`;
      }
      if (mobile) {
        result['--background-offset-mobile'] = `${mobile}px`;
      }
      return result;
    },
    deleteBlockMethods({ id }: FormWidthdrowMethods): void {
      const index = this.blocksMethods.findIndex((block) => block.id === id);
      this.blocksMethods.splice(index, 1);
    },
    addGuest() {
      this.guests.push({ firstName: '', surName: '' });
    },
    updateGuest(
      { index, isSurName = false, value }: { index: number, isSurName?: boolean, value: string },
    ) {
      this.guests[index][isSurName ? 'surName' : 'firstName'] = value;
    },
    deleteGuest(index: number) {
      this.guests.splice(index, 1);
    },
  },
});
