import { PropType, ComponentOptionsMixin } from 'vue';
import { Color } from '@/data/e-invitations/types';
import FontWeight from '@/data/types/font-weight';
import { INPUT_TYPE, InputStyles } from '@/data/types/input';
import getFontFamily from '@/tools/font-family';

interface StylesVars {
  '--border-color'?: Color;
  '--background-color'?: Color;
  '--font'?: string;
  '--weight'?: FontWeight;
  '--color'?: Color;
  '--placeholder-font'?: string;
  '--placeholder-weight'?: FontWeight;
  '--placeholder-color'?: Color;
}

const inputMixin: ComponentOptionsMixin = {
  props: {
    value: String,
    placeholder: String,
    type: String as PropType<INPUT_TYPE>,
    styles: Object as PropType<InputStyles>,
    hasError: Boolean,
  },
  emits: ['update:value'],
  computed: {
    style(): StylesVars {
      const {
        borderColor,
        backgroundColor,
        textStyles,
        placeholderStyles,
      } = this.styles || {};
      const result: StylesVars = {};
      if (borderColor) {
        result['--border-color'] = borderColor;
      }
      if (backgroundColor) {
        result['--background-color'] = backgroundColor;
      }
      if (textStyles) {
        const { font, weight, color } = textStyles;
        if (font) {
          result['--font'] = getFontFamily(font);
        }
        if (weight) {
          result['--weight'] = weight;
        }
        if (color) {
          result['--color'] = color;
        }
      }
      if (placeholderStyles) {
        const { font, weight, color } = placeholderStyles;
        if (font) {
          result['--placeholder-font'] = getFontFamily(font);
        }
        if (weight) {
          result['--placeholder-weight'] = weight;
        }
        if (color) {
          result['--placeholder-color'] = color;
        }
      }
      return result;
    },
  },
  methods: {
    getFontFamily,
    /**
     * @public
     */
    focus() {
      this.$el.focus();
    },
  },
};

export default inputMixin;
