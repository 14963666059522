import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "choice-fields-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_block = _resolveComponent("checkbox-block")!
  const _component_invitations_constructor_field = _resolveComponent("invitations-constructor-field")!
  const _component_select_block = _resolveComponent("select-block")!
  const _component_list_editor = _resolveComponent("list-editor")!
  const _component_input_holder = _resolveComponent("input-holder")!
  const _component_input_block = _resolveComponent("input-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_invitations_constructor_field, { class: "choice-fields-editor__item" }, {
      label: _withCtx(() => [
        _createTextVNode("Обязательное поле")
      ]),
      value: _withCtx(() => [
        _createVNode(_component_checkbox_block, {
          value: _ctx.value.required,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('required', $event)))
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    _createVNode(_component_invitations_constructor_field, { class: "choice-fields-editor__item" }, {
      label: _withCtx(() => [
        _createTextVNode("Тип ответа")
      ]),
      value: _withCtx(() => [
        _createVNode(_component_select_block, {
          options: _ctx.typeOptions,
          value: _ctx.value.type,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('type', $event)))
        }, null, 8, ["options", "value"])
      ]),
      _: 1
    }),
    (_ctx.value.type === _ctx.CHOICE_BLOCK_TYPES.CHECKBOX)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_invitations_constructor_field, { class: "choice-fields-editor__item" }, {
            label: _withCtx(() => [
              _createTextVNode("Выбор нескольких вариантов")
            ]),
            value: _withCtx(() => [
              _createVNode(_component_checkbox_block, {
                value: _ctx.value.multiple,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('multiple', $event)))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_invitations_constructor_field, { class: "choice-fields-editor__item" }, {
            label: _withCtx(() => [
              _createTextVNode("Список чекбоксов")
            ]),
            value: _withCtx(() => [
              _createVNode(_component_input_holder, {
                "error-codes": _ctx.errors.checkboxes
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_list_editor, {
                    value: _ctx.value.checkboxes,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('checkboxes', $event)))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["error-codes"])
            ]),
            _: 1
          })
        ], 64))
      : (_ctx.value.type === _ctx.CHOICE_BLOCK_TYPES.INPUT)
        ? (_openBlock(), _createBlock(_component_invitations_constructor_field, {
            key: 1,
            class: "choice-fields-editor__item"
          }, {
            label: _withCtx(() => [
              _createTextVNode("Плейсхолдер инпута")
            ]),
            value: _withCtx(() => [
              _createVNode(_component_input_block, {
                value: _ctx.value.inputPlaceholder,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInput('inputPlaceholder', $event)))
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
  ]))
}