
import { defineComponent, PropType } from 'vue';
import { MapSettings } from '@/data/e-invitations/block-options/place-block';
import Coords from '@/data/types/coords';
import ERRORS_CODES from '@/data/constants/error-codes';
import { commaToPoint } from '@/tools';
import InputHolder from '@/blocks/input-holder';
import InputBlock from '@/blocks/input';

interface MapSettingsErrors {
  latitude: Set<ERRORS_CODES>,
  longitude: Set<ERRORS_CODES>,
}

export default defineComponent({
  name: 'map-settings-editor',
  components: {
    InputHolder,
    InputBlock,
  },
  props: {
    value: {
      type: Object as PropType<MapSettings>,
      required: true,
    },
  },
  emits: ['update:value'],
  expose: ['validateFields'],
  data: () => ({
    errors: {} as MapSettingsErrors,
  }),
  methods: {
    onInput(index: number, value: string) {
      const coordsCopy: Coords = [...(this.value?.coords || [])];
      coordsCopy.splice(index, 1, Number(commaToPoint(value)));
      const newValue: MapSettings = {
        coords: coordsCopy,
      };
      this.$emit('update:value', newValue);
    },
    setError(fieldCode: keyof MapSettingsErrors, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        this.errors[fieldCode] = new Set<ERRORS_CODES>();
      }
      this.errors[fieldCode].add(errorCode);
    },
    removeError(fieldCode: keyof MapSettingsErrors, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        return;
      }
      this.errors[fieldCode].delete(errorCode);
    },
    validateFields(): boolean {
      const { coords } = this.value;
      if (!coords) {
        return true;
      }
      if (!coords[0] && coords[1]) {
        this.setError('latitude', ERRORS_CODES.REQUIRED);
      }
      if (coords[0] && !coords[1]) {
        this.setError('longitude', ERRORS_CODES.REQUIRED);
      }
      return coords.every(Boolean) || coords.every((coord) => !coord);
    },
  },
  watch: {
    'value.coords[0]': function handler() {
      this.removeError('latitude', ERRORS_CODES.REQUIRED);
    },
    'value.coords[1]': function handler() {
      this.removeError('longitude', ERRORS_CODES.REQUIRED);
    },
  },
});
