import { Color } from '@/data/e-invitations/types';
import TextStyles from '@/data/types/text-styles';

export enum INPUT_TYPE {
  TYPE_1 = 'type-1',
  TYPE_2 = 'type-2',
}

export interface InputStyles {
  backgroundColor?: Color;
  borderColor?: Color;
  textStyles: TextStyles;
  placeholderStyles: TextStyles;
}
