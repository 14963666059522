export enum FIRST_BLOCK_CODES { // Greek alphabet https://en.wikipedia.org/wiki/Greek_alphabet
  ALPHA = 'FIRST_BLOCK_ALPHA',
  BETTA = 'FIRST_BLOCK_BETTA',
  GAMMA = 'FIRST_BLOCK_GAMMA',
  DELTA = 'FIRST_BLOCK_DELTA',
  EPSILON = 'FIRST_BLOCK_EPSILON',
  ZETA = 'FIRST_BLOCK_ZETA',
  ETA = 'FIRST_BLOCK_ETA',
  TETA = 'FIRST_BLOCK_TETA',
  IOTA = 'FIRST_BLOCK_IOTA',
  NU = 'FIRST_BLOCK_NU',
}

export enum BLOCKS_CODES {
  TEXT_BLOCK = 'TEXT_BLOCK',
  COUNTDOWN_BLOCK = 'TIMER_BLOCK',
  PROGRAM_BLOCK_1 = 'PROGRAM_BLOCK_1',
  PROGRAM_BLOCK_2 = 'PROGRAM_BLOCK_2',
  PROGRAM_BLOCK_3 = 'PROGRAM_BLOCK_3',
  PLACE_BLOCK = 'PLACE_BLOCK',
  DRESSCODE_BLOCK = 'DRESSCODE_BLOCK',
  PRESENCE_BLOCK = 'PRESENCE_BLOCK',
  CHOICE_BLOCK = 'CHOICE_BLOCK',
  BEVERAGES_BLOCK = 'BEVERAGES_BLOCK',
  PHOTO_BLOCK = 'PHOTO_BLOCK',
  BUTTON_BLOCK = 'BUTTON_BLOCK',
  ACCEPT_BLOCK_1 = 'ACCEPT_BLOCK_1',
  ACCEPT_BLOCK_2 = 'ACCEPT_BLOCK_2',
  END_BLOCK = 'END_BLOCK',
  HISTORY_BLOCK = 'HISTORY_BLOCK',
}

export type UNION_BLOCK_CODES = FIRST_BLOCK_CODES | BLOCKS_CODES;
