import FirstBlockNuOptions from '@/data/e-invitations/block-options/first-block-nu';

const defaultOptions: FirstBlockNuOptions = {
  namesStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  titleText: '&',
  topText: 'Это по любви...',
  topTextStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  blockPosition: 'center',
  lineColor: null,
  background: {
    imageUrls: {
      x1: 'https://i.imgur.com/FiMNnER.jpg',
    },
  },
};

export default defaultOptions;
