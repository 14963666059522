
import { defineComponent } from 'vue';
import TextBlock from '@/blocks/text';

export default defineComponent({
  name: 'page-stub',
  components: {
    TextBlock,
  },
});
