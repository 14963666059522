import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "beverages-block-inv__content content-width" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_input_holder = _resolveComponent("input-holder")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "beverages-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "beverages-block-inv__title" }, _ctx.computedOptions.titleStyles, {
          size: [60, 30],
          "line-height": 1
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.title), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "beverages-block-inv__text" }, _ctx.computedOptions.textStyles, { size: [24, 14] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.text), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _createVNode(_component_input_holder, {
          class: "beverages-block-inv__beverages-container",
          "custom-errors": _ctx.errors,
          "errors-position": "absolute"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.beverages, (beverageName, beverageIndex) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: beverageIndex,
                class: "beverages-block-inv__beverage"
              }, [
                _createElementVNode("div", {
                  class: "beverages-block-inv__beverage-count-holder",
                  style: _normalizeStyle({
              '--active-icon-color': _ctx.computedOptions.activeIconColor,
              '--disabled-icon-color': _ctx.computedOptions.disabledIconColor,
            })
                }, [
                  (_ctx.getBeverageCount(beverageIndex) > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("button", {
                          class: _normalizeClass(_ctx.bem('beverages-block-inv__beverage-count-button-holder', { remove: true })),
                          type: "button",
                          onClick: ($event: any) => (_ctx.decreaseBeverageCount(beverageIndex))
                        }, [
                          _createVNode(_component_svg_icon, {
                            class: "beverages-block-inv__beverage-count-button",
                            name: "arrow-bottom-short-wide-tailless"
                          })
                        ], 10, _hoisted_2),
                        _createVNode(_component_text_block, _mergeProps({ class: "beverages-block-inv__beverage-count" }, _ctx.computedOptions.beverageCountStyles, {
                          size: [30, 18],
                          "line-height": 1
                        }), {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getBeverageCount(beverageIndex)), 1)
                          ]),
                          _: 2
                        }, 1040),
                        _createElementVNode("button", {
                          class: _normalizeClass(_ctx.bem('beverages-block-inv__beverage-count-button-holder', { add: true })),
                          type: "button",
                          disabled: _ctx.isIncreaseButtonDisabled(beverageIndex),
                          onClick: ($event: any) => (_ctx.increaseBeverageCount(beverageIndex))
                        }, [
                          _createVNode(_component_svg_icon, {
                            class: "beverages-block-inv__beverage-count-button",
                            name: "arrow-bottom-short-wide-tailless"
                          })
                        ], 10, _hoisted_3)
                      ], 64))
                    : (_openBlock(), _createBlock(_component_svg_icon, {
                        key: 1,
                        class: "beverages-block-inv__beverage-add-button",
                        name: "plus",
                        onClick: ($event: any) => (_ctx.increaseBeverageCount(beverageIndex))
                      }, null, 8, ["onClick"]))
                ], 4),
                _createVNode(_component_text_block, _mergeProps({ class: "beverages-block-inv__beverage-name" }, _ctx.computedOptions.beverageNameStyles, {
                  size: [30, 18],
                  "line-height": 1
                }), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(beverageName), 1)
                  ]),
                  _: 2
                }, 1040)
              ])), [
                [_directive_element_intersection_animation_directive, { tact: 3 + beverageIndex }]
              ])
            }), 128))
          ]),
          _: 1
        }, 8, ["custom-errors"])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}