
import { defineComponent, PropType } from 'vue';
import TextBlock from '@/blocks/text';
import ERRORS_CODES from '@/data/constants/error-codes';
import TextStyles from '@/data/types/text-styles';

export default defineComponent({
  name: 'validation-errors',
  components: {
    TextBlock,
  },
  props: {
    errorCodes: {
      type: Array as PropType<ERRORS_CODES[]>,
      default: () => [],
    },
    customErrors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    errorBind(): TextStyles {
      return {
        font: 'century-gothic',
        weight: 400,
        color: 'var(--color-red)',
        lineHeight: 1,
        size: [12, 10],
      };
    },
  },
  methods: {
    getErrorText(code: ERRORS_CODES): string {
      const errorsMapping: Record<ERRORS_CODES, string> = {
        [ERRORS_CODES.REQUIRED]: 'Заполните поле',
      };
      return errorsMapping[code];
    },
  },
});
