
import { defineComponent, PropType } from 'vue';
import ButtonBlockInterfaceOptions from '@/data/e-invitations/block-options/button-block';
import defaultOptions from '@/data/e-invitations/block-default-options/button-block';
import BlockWithBackground from '@/blocks/block-with-background';
import ButtonBlock from '@/blocks/button';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'button-block-inv',
  components: {
    BlockWithBackground,
    ButtonBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<ButtonBlockInterfaceOptions>,
      required: true,
    },
    feedbackSending: Boolean,
    successfullySent: Boolean,
  },
  emits: ['submit'],
  computed: {
    computedOptions(): ButtonBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
    buttonText(): string {
      const { type, buttonText, buttonSuccessText } = this.computedOptions;
      return !this.successfullySent || type !== 'submit' ? buttonText : buttonSuccessText;
    },
  },
  methods: {
    onClick() {
      const { type } = this.computedOptions;
      if (type === 'submit') {
        this.$emit('submit');
      } else if (type === 'link' && this.options.link) {
        window.open(this.options.link, '_blank');
      }
    },
  },
});
