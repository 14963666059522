import { Blocks } from '@/data/e-invitations/blocks';
import {
  Color,
  ImageUrls,
  Names,
  Url,
} from '@/data/e-invitations/types';
import { FIRST_BLOCK_CODES } from '@/data/e-invitations/block-codes';
import firstBlockAlphaDefaultOptions from '@/data/e-invitations/block-default-options/first-block-alpha';
import { RequireAtLeastOne } from '@/tools/types';

export type FullSize = 'height' | 'width';

export interface SideBackground {
  imageUrls: ImageUrls;
  fullSize?: FullSize;
  offset?: {
    desktop: number;
    mobile: number;
  };
}

export interface Page {
  meta: {
    title: string;
    description: string;
    ogImageUrl: Url;
  };
  names: Names;
  personalization?: boolean;
  date: string;
  timezone?: number;
  background?: RequireAtLeastOne<{
    left?: SideBackground;
    right?: SideBackground;
    color?: Color;
  }, 'left' | 'right' | 'color'>;
  blocks: Blocks;
}

export const defaultPage: Page = {
  meta: {
    title: 'Приглашение на свадьбу',
    description: 'Описание свадьбы',
    ogImageUrl: '',
  },
  names: [
    {
      firstName: '',
      surName: '',
    },
    {
      firstName: '',
      surName: '',
    },
  ],
  personalization: false,
  date: '',
  timezone: 3,
  background: {
    color: 'transparent',
  },
  blocks: [
    {
      code: FIRST_BLOCK_CODES.ALPHA,
      options: firstBlockAlphaDefaultOptions,
    },
  ],
};
