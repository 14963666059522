
import { defineComponent, PropType } from 'vue';
import { Names } from '@/data/e-invitations/types';
import FirstBlockAlphaInterfaceOptions from '@/data/e-invitations/block-options/first-block-alpha';
import defaultOptions from '@/data/e-invitations/block-default-options/first-block-alpha';
import BlockWithBackground from '@/blocks/block-with-background';
import NamesWithAmpersand from '@/blocks/names-with-ampersand';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon';
import { formatDate } from '@/tools/datetime';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'first-block-alpha',
  components: {
    BlockWithBackground,
    NamesWithAmpersand,
    TextBlock,
    SvgIcon,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    names: {
      type: Array as unknown as PropType<Names>,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<FirstBlockAlphaInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): FirstBlockAlphaInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
  methods: {
    formatDate,
    onArrowClick(): void {
      window.scrollTo({ behavior: 'smooth', top: this.$el.offsetTop + this.$el.offsetHeight });
    },
  },
});
