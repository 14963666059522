
import { defineComponent, PropType } from 'vue';
import PhotoBlockInterfaceOptions from '@/data/e-invitations/block-options/photo-block';
import defaultOptions from '@/data/e-invitations/block-default-options/photo-block';
import BlockWithBackground from '@/blocks/block-with-background';
import ImgBlock from '@/blocks/img';

export default defineComponent({
  inheritAttrs: false,
  name: 'photo-block-inv',
  components: {
    BlockWithBackground,
    ImgBlock,
  },
  props: {
    options: {
      type: Object as PropType<PhotoBlockInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): PhotoBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
});
