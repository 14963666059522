import { HttpService } from '@/api/http-service';

// DTO
import type { PersonalizationGetByEInvIdAndSlugResponseDto } from './dto/get-by-e-inv-id-and-slug/response.dto';

class PersonalizationService extends HttpService {
  getByEInvIdAndSlug(eInvId: string, slug: string) {
    return this.get<PersonalizationGetByEInvIdAndSlugResponseDto>(`/personalization/e-inv-id/${eInvId}/slug/${slug}`);
  }
}

export const personalizationService = new PersonalizationService();
