
import { defineComponent, PropType } from 'vue';
import ProgramBlock3InterfaceOptions from '@/data/e-invitations/block-options/program-block-3';
import defaultOptions from '@/data/e-invitations/block-default-options/program-block-3';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon';
import ProgramItem from '@/blocks/program-item';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'program-block-3-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    SvgIcon,
    ProgramItem,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<ProgramBlock3InterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): ProgramBlock3InterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
});
