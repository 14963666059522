import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "choice-block-inv__block-fields-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_checkbox_block = _resolveComponent("checkbox-block")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_input_holder = _resolveComponent("input-holder")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "choice-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["choice-block-inv__content", _ctx.bem('content-width', { desktopOnly: true })])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.blocks, (block, blockIndex) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: block.title,
            class: "choice-block-inv__block"
          }, [
            _createVNode(_component_text_block, _mergeProps({ class: "choice-block-inv__block-title" }, _ctx.computedOptions.titleStyles, {
              size: [60, 30],
              "line-height": 1
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(block.title), 1)
              ]),
              _: 2
            }, 1040),
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.fields, (field, fieldIndex) => {
                return (_openBlock(), _createBlock(_component_input_holder, {
                  key: fieldIndex,
                  class: _normalizeClass(_ctx.bem(
              'choice-block-inv__block-field',
              { checkboxes: field.type === _ctx.CHOICE_BLOCK_TYPES.CHECKBOX },
            )),
                  "error-codes": _ctx.getErrorsCodes({ blockIndex, fieldIndex })
                }, {
                  default: _withCtx(() => [
                    (field.type === _ctx.CHOICE_BLOCK_TYPES.CHECKBOX)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(field.checkboxes, (label) => {
                          return (_openBlock(), _createBlock(_component_checkbox_block, _mergeProps({
                            key: label,
                            class: "choice-block-inv__checkbox"
                          }, _ctx.computedOptions.checkboxesStyles, {
                            "has-error": !!_ctx.getErrorsCodes({ blockIndex, fieldIndex }),
                            value: _ctx.isChecked({ blockIndex, fieldIndex, label }),
                            "onUpdate:value": ($event: any) => (_ctx.toggleCheckbox({ blockIndex, fieldIndex, label }))
                          }), {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(label), 1)
                            ]),
                            _: 2
                          }, 1040, ["has-error", "value", "onUpdate:value"]))
                        }), 128))
                      : (_openBlock(), _createBlock(_component_input_block, {
                          key: 1,
                          class: "choice-block-inv__input",
                          styles: _ctx.computedOptions.inputStyles,
                          type: _ctx.computedOptions.inputType,
                          placeholder: field.inputPlaceholder,
                          "has-error": !!_ctx.getErrorsCodes({ blockIndex, fieldIndex }),
                          value: _ctx.getInputValue({ blockIndex, fieldIndex }),
                          "onUpdate:value": ($event: any) => (_ctx.setInputValue({ blockIndex, fieldIndex, value: $event }))
                        }, null, 8, ["styles", "type", "placeholder", "has-error", "value", "onUpdate:value"]))
                  ]),
                  _: 2
                }, 1032, ["class", "error-codes"]))
              }), 128))
            ])
          ])), [
            [_directive_element_intersection_animation_directive, { tact: blockIndex + 1 }]
          ])
        }), 128))
      ], 2)
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}