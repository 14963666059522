import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "place-block-inv__blocks-container content-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_img_block = _resolveComponent("img-block")!
  const _component_map_block = _resolveComponent("map-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "place-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.bem('place-block-inv__content', { noPaddingBottom: !!_ctx.computedOptions.placeImage }))
      }, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "place-block-inv__title" }, _ctx.computedOptions.titleStyles, { size: [72, 36] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.blocksText, (block, blockIndex) => {
            return _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
              key: blockIndex,
              class: _ctx.bem('place-block-inv__text-block', { text: true }),
              style: { '--desktop-width': block.desktopWidth }
            }, _ctx.computedOptions.textBlocksStyles, { size: [30, 18] }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(block.text), 1)
              ]),
              _: 2
            }, 1040, ["class", "style"])), [
              [_directive_element_intersection_animation_directive, { tact: blockIndex + 2 }]
            ])
          }), 128))
        ]),
        (_ctx.imageUrls)
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.bem('place-block-inv__image-holder', {
          map: !!_ctx.computedOptions.mapImage,
          place: !!_ctx.computedOptions.placeImage,
        }))
            }, [
              _createVNode(_component_img_block, {
                class: "place-block-inv__image",
                src: _ctx.imageUrls.x1,
                src2x: _ctx.imageUrls.x2,
                "mobile-src": _ctx.imageUrls.x1Mobile,
                "mobile-src2x": _ctx.imageUrls.x2Mobile
              }, null, 8, ["src", "src2x", "mobile-src", "mobile-src2x"])
            ], 2)), [
              [_directive_element_intersection_animation_directive, {
          tact: _ctx.computedOptions.blocksText.length + 2,
        }]
            ])
          : _createCommentVNode("", true),
        (_ctx.computedOptions.mapSettings)
          ? _withDirectives((_openBlock(), _createBlock(_component_map_block, {
              key: 1,
              class: "place-block-inv__map",
              coords: _ctx.computedOptions.mapSettings.coords
            }, null, 8, ["coords"])), [
              [_directive_element_intersection_animation_directive, {
          tact: _ctx.computedOptions.blocksText.length + 2,
        }]
            ])
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}