import { UNION_BLOCK_CODES } from '@/data/e-invitations/block-codes';
import { EditorConfigFieldType } from '@/data/e-invitations/editor-types';
import AcceptBlock1EditorFields from '@/data/e-invitations/block-editors-configs/accept-block-1';
import AcceptBlock2EditorFields from '@/data/e-invitations/block-editors-configs/accept-block-2';
import ButtonBlockEditorFields from '@/data/e-invitations/block-editors-configs/button-block';
import ChoiceBlockEditorFields from '@/data/e-invitations/block-editors-configs/choice-block';
import BeveragesBlockEditorFields from '@/data/e-invitations/block-editors-configs/beverages-block';
import CountdownBlockEditorFields from '@/data/e-invitations/block-editors-configs/countdown-block';
import DresscodeBlockEditorFields from '@/data/e-invitations/block-editors-configs/dresscode-block';
import EndBlockEditorFields from '@/data/e-invitations/block-editors-configs/end-block';
import FirstBlockAlphaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-alpha';
import FirstBlockBettaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-betta';
import FirstBlockDeltaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-delta';
import FirstBlockEpsilonEditorFields from '@/data/e-invitations/block-editors-configs/first-block-epsilon';
import FirstBlockGammaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-gamma';
import FirstBlockZetaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-zeta';
import FirstBlockEtaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-eta';
import FirstBlockTetaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-teta';
import FirstBlockIotaEditorFields from '@/data/e-invitations/block-editors-configs/first-block-iota';
import FirstBlockNuEditorFields from '@/data/e-invitations/block-editors-configs/first-block-nu';
import HistoryBlockEditorFields from '@/data/e-invitations/block-editors-configs/history-block';
import PhotoBlockEditorFields from '@/data/e-invitations/block-editors-configs/photo-block';
import PlaceBlockEditorFields from '@/data/e-invitations/block-editors-configs/place-block';
import PresenceBlockEditorFields from '@/data/e-invitations/block-editors-configs/presence-block';
import ProgramBlock1EditorFields from '@/data/e-invitations/block-editors-configs/program-block-1';
import ProgramBlock2EditorFields from '@/data/e-invitations/block-editors-configs/program-block-2';
import ProgramBlock3EditorFields from '@/data/e-invitations/block-editors-configs/program-block-3';
import TextBlockEditorFields from '@/data/e-invitations/block-editors-configs/text-block';

type BlockEditorFields = Record<string, EditorConfigFieldType>;

export default function getBlockEditorFields(code: UNION_BLOCK_CODES): BlockEditorFields {
  const componentMap: Record<UNION_BLOCK_CODES, BlockEditorFields> = {
    FIRST_BLOCK_ALPHA: FirstBlockAlphaEditorFields,
    FIRST_BLOCK_BETTA: FirstBlockBettaEditorFields,
    FIRST_BLOCK_GAMMA: FirstBlockGammaEditorFields,
    FIRST_BLOCK_DELTA: FirstBlockDeltaEditorFields,
    FIRST_BLOCK_EPSILON: FirstBlockEpsilonEditorFields,
    FIRST_BLOCK_ZETA: FirstBlockZetaEditorFields,
    FIRST_BLOCK_ETA: FirstBlockEtaEditorFields,
    FIRST_BLOCK_TETA: FirstBlockTetaEditorFields,
    FIRST_BLOCK_IOTA: FirstBlockIotaEditorFields,
    FIRST_BLOCK_NU: FirstBlockNuEditorFields as unknown as BlockEditorFields,
    ACCEPT_BLOCK_1: AcceptBlock1EditorFields as unknown as BlockEditorFields,
    ACCEPT_BLOCK_2: AcceptBlock2EditorFields as unknown as BlockEditorFields,
    BUTTON_BLOCK: ButtonBlockEditorFields,
    CHOICE_BLOCK: ChoiceBlockEditorFields as unknown as BlockEditorFields,
    BEVERAGES_BLOCK: BeveragesBlockEditorFields,
    TIMER_BLOCK: CountdownBlockEditorFields,
    DRESSCODE_BLOCK: DresscodeBlockEditorFields as unknown as BlockEditorFields,
    END_BLOCK: EndBlockEditorFields,
    HISTORY_BLOCK: HistoryBlockEditorFields as unknown as BlockEditorFields,
    PHOTO_BLOCK: PhotoBlockEditorFields,
    PLACE_BLOCK: PlaceBlockEditorFields as unknown as BlockEditorFields,
    PRESENCE_BLOCK: PresenceBlockEditorFields as unknown as BlockEditorFields,
    PROGRAM_BLOCK_1: ProgramBlock1EditorFields as unknown as BlockEditorFields,
    PROGRAM_BLOCK_2: ProgramBlock2EditorFields as unknown as BlockEditorFields,
    PROGRAM_BLOCK_3: ProgramBlock3EditorFields as unknown as BlockEditorFields,
    TEXT_BLOCK: TextBlockEditorFields as unknown as BlockEditorFields,
  };
  return componentMap[code];
}
