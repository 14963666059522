import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "dynamic-fields-editor" }
const _hoisted_2 = {
  key: 1,
  class: "dynamic-fields-editor__content-blocks-wrapper"
}
const _hoisted_3 = { class: "dynamic-fields-editor__content-blocks-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_invitations_constructor_field = _resolveComponent("invitations-constructor-field")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_input_holder = _resolveComponent("input-holder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, fieldCode) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (field.isVisible ? field.isVisible(_ctx.value) : true)
          ? (_openBlock(), _createBlock(_component_invitations_constructor_field, {
              key: fieldCode,
              class: "dynamic-fields-editor__item",
              optional: field.optional
            }, _createSlots({
              label: _withCtx(() => [
                _createTextVNode(_toDisplayString(field.label), 1)
              ]),
              value: _withCtx(() => [
                _createVNode(_component_input_holder, {
                  "error-codes": _ctx.errors[fieldCode]
                }, {
                  default: _withCtx(() => [
                    (field.editorElement !== _ctx.EDITOR_ELEMENTS.CONTENT_BLOCKS)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName(field.editorElement)), {
                          key: 0,
                          ref_for: true,
                          ref: _ctx.getEditorElementRefCode(fieldCode),
                          required: !field.optional,
                          fields: _ctx.fields,
                          "fields-value": _ctx.value,
                          options: field.options,
                          value: _ctx.value[fieldCode],
                          "onUpdate:value": ($event: any) => (_ctx.onInput(fieldCode, $event))
                        }, null, 40, ["required", "fields", "fields-value", "options", "value", "onUpdate:value"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value[fieldCode], (contentBlock, contentBlockIndex) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: contentBlockIndex,
                                class: "dynamic-fields-editor__content-block-holder"
                              }, [
                                _createVNode(_component_invitations_constructor_field, { class: "dynamic-fields-editor__content-block" }, {
                                  value: _withCtx(() => [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(
                          _ctx.fields[fieldCode].contentFields
                            ? 'dynamic-fields-editor'
                            : _ctx.getComponentName(_ctx.fields[fieldCode].contentComponent)
                        ), {
                                      ref_for: true,
                                      ref: _ctx.getEditorElementRefCode(fieldCode),
                                      fields: _ctx.fields[fieldCode].contentFields,
                                      "fields-value": _ctx.value,
                                      value: contentBlock,
                                      "onUpdate:value": ($event: any) => (_ctx.onContentBlockInput({
                          fieldCode,
                          index: contentBlockIndex,
                          value: $event,
                        }))
                                    }, null, 40, ["fields", "fields-value", "value", "onUpdate:value"]))
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_svg_icon, {
                                  class: _normalizeClass(_ctx.bem(
                      'dynamic-fields-editor__content-block-delete-button',
                      { hidden: _ctx.value[fieldCode].length === 1 },
                    )),
                                  name: "cross",
                                  onClick: ($event: any) => (_ctx.removeContentBlock(fieldCode, contentBlockIndex))
                                }, null, 8, ["class", "onClick"])
                              ]))
                            }), 128))
                          ]),
                          _createVNode(_component_svg_icon, {
                            class: "dynamic-fields-editor__contents-blocks-add-button",
                            name: "plus",
                            onClick: ($event: any) => (_ctx.addContentBlock(fieldCode, field.contentFieldsDefaultValue))
                          }, null, 8, ["onClick"])
                        ]))
                  ]),
                  _: 2
                }, 1032, ["error-codes"])
              ]),
              _: 2
            }, [
              (field.description)
                ? {
                    name: "description",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString(field.description), 1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["optional"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}