import store2 from 'store2';
import { Page } from '@/data/e-invitations/page';

const storeKey = 'demo-invitation-storage';

export function getDemoInvitation(): Page {
  return store2.get(storeKey);
}

export function setDemoInvitation(page: Page): void {
  store2.set(storeKey, page);
}

export function removeDemoInvitation(): void {
  store2.remove(storeKey);
}
