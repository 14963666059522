import PlaceBlockOptions from '@/data/e-invitations/block-options/place-block';

const defaultOptions: PlaceBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  textBlocksStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  titleText: 'Место проведения',
  blocksText: [
    {
      text: 'Наше торжество будет проходить в чудесном ресторане “Сад”, где есть место как внутри зала, так и территория снаружи',
      desktopWidth: '565px',
    },
    {
      text: 'Расположен по адресу: ул. Васильева, 150',
      desktopWidth: '395px',
    },
  ],
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
