import FirstBlockEpsilonOptions from '@/data/e-invitations/block-options/first-block-epsilon';

const defaultOptions: FirstBlockEpsilonOptions = {
  titleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  namesStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  namesSeparatorColor: 'rgba(255, 255, 255, 1)',
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  background: {
    imageUrls: {
      x1: 'https://i.imgur.com/FiMNnER.jpg',
    },
  },
};

export default defaultOptions;
