import FirstBlockEtaOptions from '@/data/e-invitations/block-options/first-block-eta';

const defaultOptions: FirstBlockEtaOptions = {
  namesStyles: {
    font: 'crystal-sky',
    weight: 400,
    color: 'rgba(143, 36, 24, 1)',
  },
  dateStyles: {
    font: 'dm',
    weight: 400,
    color: 'rgba(143, 36, 24, 1)',
  },
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
