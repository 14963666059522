import { INPUT_TYPE } from '@/data/types/input';
import { EDITOR_ELEMENTS } from '@/data/e-invitations/editor-types';

// eslint-disable-next-line import/prefer-default-export
export const inputTypeEditorConfig = {
  editorElement: EDITOR_ELEMENTS.SELECT,
  label: 'Тип инпута',
  options: [
    {
      label: 'Залитый',
      value: INPUT_TYPE.TYPE_1,
    },
    {
      label: 'С бордером внизу',
      value: INPUT_TYPE.TYPE_2,
    },
  ],
};
