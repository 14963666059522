
import { defineComponent } from 'vue';
import TextBlock from '@/blocks/text';
import TextStyles from '@/data/types/text-styles';

export default defineComponent({
  name: 'table__mobile-pair',
  components: {
    TextBlock,
  },
  data: () => ({
    styles: {
      size: [14, 14],
      font: 'century-gothic',
      weight: 400,
    } as TextStyles,
  }),
});
