import AcceptBlock1Options, { TextBlock } from '@/data/e-invitations/block-options/accept-block-1';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';
import { SIZE_STRING_DESCRIPTION } from '@/data/constants/editor-fields-texts';

interface LocalEditorConfigType extends EditorConfigType<AcceptBlock1Options> {
  blocksText: EditorConfigFieldType & {
    contentFields: EditorConfigType<TextBlock>;
    contentFieldsDefaultValue: Required<TextBlock>;
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  textBlocksStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текстовых блоков',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  blocksText: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Контент блоки',
    contentFields: {
      text: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст',
      },
      highlightedTextInTheEnd: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Выделенный текст в конце абзаца',
        optional: true,
      },
      desktopWidth: {
        editorElement: EDITOR_ELEMENTS.INPUT,
        label: 'Ширина абзаца на декстопе',
        description: SIZE_STRING_DESCRIPTION,
        optional: true,
      },
    },
    contentFieldsDefaultValue: {
      text: '',
      highlightedTextInTheEnd: '',
      desktopWidth: '',
    },
  },
  imageUrls: {
    editorElement: EDITOR_ELEMENTS.IMAGE_URLS,
    label: 'Картинка',
    optional: true,
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
