
import { defineComponent, PropType } from 'vue';
import SpriteSvgIcon from '@/data/types/sprite-svg-icon';
import ICONS_NAMES from '@/data/constants/icons-names.json';
import SvgIcon from '@/blocks/svg-icon';

export default defineComponent({
  name: 'icon-picker-editor',
  components: {
    SvgIcon,
  },
  props: {
    value: String as PropType<SpriteSvgIcon>,
    required: Boolean,
  },
  emits: ['update:value'],
  data: () => ({
    ICONS_NAMES,
  }),
  methods: {
    onInput(name: SpriteSvgIcon) {
      if (this.required) {
        this.$emit('update:value', name);
        return;
      }
      this.$emit('update:value', this.value === name ? null : name);
    },
  },
});
