import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-block-inv__content content-width" }
const _hoisted_2 = { class: "text-block-inv__blocks-container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "text-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "text-block-inv__title text-align-center" }, _ctx.computedOptions.titleStyles, {
          size: [72, 48],
          "line-height": 1
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.blocks, (block, blockIndex) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: blockIndex,
              class: "text-block-inv__block",
              style: _normalizeStyle({ '--desktop-width': block.desktopWidth })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.items, (item, itemIndex) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: itemIndex }, [
                  (item.type === 'link')
                    ? (_openBlock(), _createBlock(_component_text_block, _mergeProps({
                        key: 0,
                        class: _ctx.bem('text-block-inv__text-block', { link: true })
                      }, _ctx.computedOptions.linkStyles, {
                        size: [60, 25],
                        "line-height": 1
                      }), {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            href: item.href,
                            style: _normalizeStyle({ borderColor: _ctx.computedOptions.linkStyles.color })
                          }, _toDisplayString(item.text), 13, _hoisted_3)
                        ]),
                        _: 2
                      }, 1040, ["class"]))
                    : (item.type === 'date')
                      ? (_openBlock(), _createBlock(_component_text_block, _mergeProps({
                          key: 1,
                          class: _ctx.bem('text-block-inv__text-block', { date: true })
                        }, _ctx.computedOptions.dateStyles, {
                          size: [60, 36],
                          "line-height": 1
                        }), {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
                          ]),
                          _: 1
                        }, 16, ["class"]))
                      : (item.type === 'contacts')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass(_ctx.bem('text-block-inv__text-block', { contacts: true }))
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.contacts, ({ type, value, label }) => {
                              return (_openBlock(), _createElementBlock("a", {
                                key: type + value,
                                class: "text-block-inv__contact",
                                href: _ctx.getContactHref({ type, value }),
                                target: _ctx.getContactTarget(type)
                              }, [
                                _createVNode(_component_svg_icon, {
                                  class: _normalizeClass(_ctx.bem('text-block-inv__contact-icon', { type })),
                                  fill: _ctx.computedOptions.contactsSvgColor,
                                  name: _ctx.getContactIcon(type)
                                }, null, 8, ["class", "fill", "name"]),
                                _createVNode(_component_text_block, _mergeProps(_ctx.computedOptions.contactsLabelStyles, { size: [30, 20] }), {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(label), 1)
                                  ]),
                                  _: 2
                                }, 1040)
                              ], 8, _hoisted_4))
                            }), 128))
                          ], 2))
                        : (_openBlock(), _createBlock(_component_text_block, _mergeProps({
                            key: 3,
                            class: _ctx.bem('text-block-inv__text-block', { text: true })
                          }, _ctx.computedOptions.textBlocksStyles, { size: [30, 18] }), {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.text), 1)
                            ]),
                            _: 2
                          }, 1040, ["class"]))
                ], 64))
              }), 128))
            ], 4)), [
              [_directive_element_intersection_animation_directive, { tact: blockIndex + 2 }]
            ])
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}