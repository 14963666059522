import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ROUTES_NAMES from '@/data/constants/routes-names';
import PageEInvitations from '@/pages/e-invitations';
import PageDemoEInvitations from '@/pages/demo-e-invitations';
import PageInvitationsFeedback from '@/pages/invitations-feedback';
import PageStub from '@/pages/stub';
import PageInvitationsConstructor from '@/pages/invitations-constructor';

const routes: Array<RouteRecordRaw> = [
  {
    component: PageInvitationsFeedback,
    name: ROUTES_NAMES.INVITATIONS_FEEDBACK,
    path: '/e/:path/feedback',
    props: true,
  },
  {
    component: PageEInvitations,
    name: ROUTES_NAMES.E_INVITATIONS,
    path: '/e/:path/:personPath?',
    alias: '/e-invitations/:path/:personPath?',
    props: true,
  },
  {
    component: PageDemoEInvitations,
    name: ROUTES_NAMES.DEMO_E_INVITATIONS,
    path: '/demo-e-invitations/',
  },
  {
    component: PageInvitationsConstructor,
    name: ROUTES_NAMES.INVITATIONS_CONSTRUCTOR,
    path: '/constructor',
  },
  {
    component: PageStub,
    name: ROUTES_NAMES.STUB,
    path: '',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
