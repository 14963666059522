import FirstBlockIotaOptions from '@/data/e-invitations/block-options/first-block-iota';

const defaultOptions: FirstBlockIotaOptions = {
  namesStyles: {
    font: 'cormorant',
    weight: 300,
    color: 'rgba(51, 46, 43, 1)',
  },
  ampersandStyles: {
    font: 'cormorant',
    weight: 300,
    color: 'rgba(51, 46, 43, 1)',
  },
  dateStyles: {
    font: 'felixty',
    weight: 400,
    color: 'rgba(51, 46, 43, 1)',
  },
  iconStyles: {
    name: 'heart-filled',
    fill: 'rgba(51, 46, 43, 1)',
  },
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
