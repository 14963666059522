import { Name } from '@/data/e-invitations/types';

export function camelToKebab(string: string): string {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export function bem(name: string, mods: Record<string, number | string | boolean> = {}): string {
  const result = [name];

  Object.entries(mods).forEach(([mod, value]) => {
    if (value === true) {
      result.push(`${name}_${camelToKebab(mod)}`);
    } else if (value) {
      result.push(`${name}_${camelToKebab(mod)}_${camelToKebab(String(value))}`);
    }
  });
  return result.join(' ');
}

export function getStaticUrl(path: string, isCssUrl = false): string {
  const result = `${process.env.BASE_URL}${path}`;
  return isCssUrl ? `url(${result})` : result;
}

export function clone<T>(content: T): T {
  return JSON.parse(JSON.stringify(content));
}

export function sleep(duration = 0): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, duration * 1000));
}

export function uniqueId(): string {
  const rand = Math.random();
  return String(Date.now() + (rand === 0 ? 0.1 : rand)).replace('.', '');
}

export function maxMinLimitedValue({
  value,
  min = -Infinity,
  max = Infinity,
}: { value: number, min?: number, max?: number }): number {
  return Math.max(Math.min(value, max), min);
}

export function cssUrl(value: string): string {
  return `url(${value})`;
}

export function download({ url, name, ext }: { url: string, name: string, ext: string }): void {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = `${name}.${ext}`;
  a.target = '_blank';
  a.click();
  a.remove();
}

export function getFullName({ firstName, surName }: Name): string {
  return [firstName, surName].filter(Boolean).join(' ');
}

export function commaToPoint(value: string): string {
  return value.replace(/,/g, '.');
}

export function pointToComma(value: string): string {
  return value.replace(/\./g, ',');
}
