import FirstBlockEpsilonOptions from '@/data/e-invitations/block-options/first-block-epsilon';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<FirstBlockEpsilonOptions> = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  namesSeparatorColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет раздилителя имен',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
