import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "history-block-inv__content" }
const _hoisted_2 = { class: "history-block-inv__periods-container" }
const _hoisted_3 = { class: "history-block-inv__separator-holder" }
const _hoisted_4 = { class: "history-block-inv__text-blocks-container" }
const _hoisted_5 = { class: "history-block-inv__separator-holder" }
const _hoisted_6 = { class: "history-block-inv__text-blocks-container" }
const _hoisted_7 = { class: "history-block-inv__separator-holder" }
const _hoisted_8 = { class: "history-block-inv__text-blocks-container" }
const _hoisted_9 = { class: "history-block-inv__separator-holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_img_block = _resolveComponent("img-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "history-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "history-block-inv__title" }, _ctx.computedOptions.titleStyles, { size: [60, 40] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "history-block-inv__subtitle" }, _ctx.computedOptions.subtitleStyles || _ctx.computedOptions.textStyles, { size: [30, 18] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.subtitleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.periods, (period, periodIndex) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: period.name,
              class: "history-block-inv__period"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "history-block-inv__separator-line",
                  style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
                }, null, 4),
                _createVNode(_component_text_block, _mergeProps({ class: "history-block-inv__period-name" }, _ctx.computedOptions.periodNameStyles, { size: [30, 30] }), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(period.name), 1)
                  ]),
                  _: 2
                }, 1040),
                _createElementVNode("div", {
                  class: "history-block-inv__separator-line",
                  style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
                }, null, 4)
              ]),
              (period.image)
                ? (_openBlock(), _createBlock(_component_img_block, {
                    key: 0,
                    class: _normalizeClass(_ctx.bem(
              'history-block-inv__img',
              { fullWidth: period.image.desktopSize === 'full-width' },
            )),
                    style: _normalizeStyle(_ctx.getImageStyle(period.image.desktopSize)),
                    src: period.image.urls.x1,
                    src2x: period.image.urls.x2,
                    "mobile-src": period.image.urls.x1Mobile,
                    "mobile-src2x": period.image.urls.x2Mobile,
                    alt: period.image.alt
                  }, null, 8, ["class", "style", "src", "src2x", "mobile-src", "mobile-src2x", "alt"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(period.blocks, (block, blockIndex) => {
                  return (_openBlock(), _createBlock(_component_text_block, {
                    key: blockIndex,
                    class: "history-block-inv__text-block",
                    style: _normalizeStyle({ '--desktop-width': block.desktopWidth })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(block.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["style"]))
                }), 128))
              ]),
              (period.firstPersonBlocks)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        class: "history-block-inv__separator-line",
                        style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
                      }, null, 4),
                      _createVNode(_component_img_block, {
                        class: "history-block-inv__person-avatar",
                        src: _ctx.computedOptions.firstPersonAvatarImageUrls.x1,
                        src2x: _ctx.computedOptions.firstPersonAvatarImageUrls.x2,
                        "mobile-src": _ctx.computedOptions.firstPersonAvatarImageUrls.x1Mobile,
                        "mobile-src2x": _ctx.computedOptions.firstPersonAvatarImageUrls.x2Mobile
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(period.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["src", "src2x", "mobile-src", "mobile-src2x"]),
                      _createElementVNode("div", {
                        class: "history-block-inv__separator-line",
                        style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
                      }, null, 4)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(period.firstPersonBlocks, (block, blockIndex) => {
                        return (_openBlock(), _createBlock(_component_text_block, {
                          key: blockIndex,
                          class: "history-block-inv__text-block",
                          style: _normalizeStyle({ '--desktop-width': block.desktopWidth })
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(block.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["style"]))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (period.secondPersonBlocks)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: "history-block-inv__separator-line",
                        style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
                      }, null, 4),
                      _createVNode(_component_img_block, {
                        class: "history-block-inv__person-avatar",
                        src: _ctx.computedOptions.secondPersonAvatarImageUrls.x1,
                        src2x: _ctx.computedOptions.secondPersonAvatarImageUrls.x2,
                        "mobile-src": _ctx.computedOptions.secondPersonAvatarImageUrls.x1Mobile,
                        "mobile-src2x": _ctx.computedOptions.secondPersonAvatarImageUrls.x2Mobile
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(period.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["src", "src2x", "mobile-src", "mobile-src2x"]),
                      _createElementVNode("div", {
                        class: "history-block-inv__separator-line",
                        style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
                      }, null, 4)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(period.secondPersonBlocks, (block, blockIndex) => {
                        return (_openBlock(), _createBlock(_component_text_block, {
                          key: blockIndex,
                          class: "history-block-inv__text-block",
                          style: _normalizeStyle({ '--desktop-width': block.desktopWidth })
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(block.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["style"]))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ])), [
              [_directive_element_intersection_animation_directive, { tact: periodIndex + 3 }]
            ])
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", {
            class: "history-block-inv__separator-line",
            style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.separatorColor })
          }, null, 4)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive, { config: { threshold: 0.1 } }]
  ])
}