import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-alpha__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_names_with_ampersand = _resolveComponent("names-with-ampersand")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-alpha",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_names_with_ampersand, {
          class: "first-block-alpha__names",
          names: _ctx.names,
          "names-styles": _ctx.computedOptions.namesStyles,
          "ampersand-color": _ctx.computedOptions.ampersandColor
        }, null, 8, ["names", "names-styles", "ampersand-color"]), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-alpha__date" }, _ctx.computedOptions.dateStyles, { size: [72, 36] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _withDirectives(_createVNode(_component_svg_icon, {
          class: "first-block-alpha__arrow-bottom",
          name: "arrow-bottom-short-wide-tailless",
          style: _normalizeStyle({ stroke: _ctx.computedOptions.arrowColor }),
          onClick: _ctx.onArrowClick
        }, null, 8, ["style", "onClick"]), [
          [_directive_element_intersection_animation_directive, { tact: 3 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}