import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: "checkbox",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', !_ctx.value)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.bem('checkbox__circle', { error: _ctx.hasError })),
      style: _normalizeStyle({ borderColor: !_ctx.hasError ? _ctx.circleColor : null })
    }, [
      (_ctx.value)
        ? (_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
            key: 0,
            class: "checkbox__icon"
          }, _ctx.iconStyles), null, 16))
        : _createCommentVNode("", true)
    ], 6),
    _createVNode(_component_text_block, _mergeProps(_ctx.labelStyles, {
      size: [30, 18],
      "line-height": 1
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 16)
  ]))
}