
import { defineComponent, PropType } from 'vue';
import { clone } from '@/tools';
import getDeepDiff from '@/tools/deep-diff';
import { EDITOR_ELEMENTS, EditorConfigFieldType } from '@/data/e-invitations/editor-types';
import ERRORS_CODES from '@/data/constants/error-codes';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import InputHolder from '@/blocks/input-holder';
import InputBlock from '@/blocks/input';
import TextareaBlock from '@/blocks/textarea';
import SelectBlock from '@/blocks/select';
import CheckboxBlock from '@/blocks/checkbox';
import IconPickerEditor from '@/blocks/icon-picker-editor';
import ColorPicker from '@/blocks/color-picker';
import BlockBackgroundEditor from '@/blocks/block-background-editor';
import ImageUrlsEditor from '@/blocks/image-urls-editor';
import TextStylesEditor from '@/blocks/text-styles-editor';
import IconStylesEditor from '@/blocks/icon-styles-editor';
import ButtonStylesEditor from '@/blocks/button-styles-editor';
import CheckboxStylesEditor from '@/blocks/checkbox-styles-editor';
import InputStylesEditor from '@/blocks/input-styles-editor';
import ChoiceFieldsEditor from '@/blocks/choice-fields-editor';
import DresscodeColorsIconsEditor from '@/blocks/dresscode-colors-icons-editor';
import HistoryPeriodImageEditor from '@/blocks/history-period-image-editor';
import MapSettingsEditor from '@/blocks/map-settings-editor';
import SvgIcon from '@/blocks/svg-icon';

type ComponentsMapKeys = keyof Omit<Record<EDITOR_ELEMENTS, string>, 'content-blocks'>;
type Value = Record<string, unknown>;

export default defineComponent({
  name: 'dynamic-fields-editor',
  components: {
    InvitationsConstructorField,
    InputHolder,
    InputBlock,
    TextareaBlock,
    SelectBlock,
    CheckboxBlock,
    IconPickerEditor,
    ColorPicker,
    BlockBackgroundEditor,
    ImageUrlsEditor,
    TextStylesEditor,
    IconStylesEditor,
    ButtonStylesEditor,
    CheckboxStylesEditor,
    InputStylesEditor,
    ChoiceFieldsEditor,
    DresscodeColorsIconsEditor,
    HistoryPeriodImageEditor,
    MapSettingsEditor,
    SvgIcon,
  },
  props: {
    fields: {
      type: Object as PropType<Record<string, EditorConfigFieldType>>,
      required: true,
    },
    value: {
      type: Object as PropType<Value>,
      required: true,
    },
    fieldsValue: {
      type: Object as PropType<Value>,
      required: true,
    },
  },
  emits: ['update:value'],
  expose: ['validateFields'],
  data: () => ({
    EDITOR_ELEMENTS,
    errors: {} as Record<string, Set<ERRORS_CODES>>,
  }),
  methods: {
    getComponentName(editorElement: ComponentsMapKeys): string {
      const componentsMap: Record<ComponentsMapKeys, string> = {
        [EDITOR_ELEMENTS.INPUT]: 'InputBlock',
        [EDITOR_ELEMENTS.TEXTAREA]: 'TextareaBlock',
        [EDITOR_ELEMENTS.SELECT]: 'SelectBlock',
        [EDITOR_ELEMENTS.CHECKBOX]: 'CheckboxBlock',
        [EDITOR_ELEMENTS.ICON]: 'IconPickerEditor',
        [EDITOR_ELEMENTS.COLOR_PICKER]: 'ColorPicker',
        [EDITOR_ELEMENTS.BACKGROUND]: 'BlockBackgroundEditor',
        [EDITOR_ELEMENTS.IMAGE_URLS]: 'ImageUrlsEditor',
        [EDITOR_ELEMENTS.TEXT_STYLES]: 'TextStylesEditor',
        [EDITOR_ELEMENTS.ICON_STYLES]: 'IconStylesEditor',
        [EDITOR_ELEMENTS.BUTTON_STYLES]: 'ButtonStylesEditor',
        [EDITOR_ELEMENTS.CHECKBOX_STYLES]: 'CheckboxStylesEditor',
        [EDITOR_ELEMENTS.INPUT_STYLES]: 'InputStylesEditor',
        [EDITOR_ELEMENTS.CHOICE_FIELDS]: 'ChoiceFieldsEditor',
        [EDITOR_ELEMENTS.DRESSCODE_COLORS_ICONS]: 'DresscodeColorsIconsEditor',
        [EDITOR_ELEMENTS.HISTORY_PERIOD_IMAGE]: 'HistoryPeriodImageEditor',
        [EDITOR_ELEMENTS.MAP_SETTINGS]: 'MapSettingsEditor',
      };
      return componentsMap[editorElement];
    },
    onInput(fieldCode: string, value: unknown) {
      this.$emit('update:value', {
        ...this.value,
        [fieldCode]: value,
      });
    },
    removeContentBlock(fieldCode: string, index: number) {
      const valueCopy = clone(this.value[fieldCode]) as unknown[];
      valueCopy.splice(index, 1);
      this.onInput(fieldCode, valueCopy);
    },
    addContentBlock(fieldCode: string, value: unknown) {
      const currentValue = this.value[fieldCode];
      if (Array.isArray(currentValue)) {
        this.onInput(fieldCode, [...currentValue, value]);
      } else {
        this.onInput(fieldCode, [value]);
      }
    },
    onContentBlockInput(
      { fieldCode, index, value }: { fieldCode: string, index: number, value: unknown },
    ) {
      const valueCopy = clone(this.value[fieldCode]) as unknown[];
      valueCopy.splice(index, 1, value);
      this.onInput(fieldCode, valueCopy);
    },
    setError(fieldCode: string, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        this.errors[fieldCode] = new Set<ERRORS_CODES>();
      }
      this.errors[fieldCode].add(errorCode);
    },
    removeError(fieldCode: string, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        return;
      }
      this.errors[fieldCode].delete(errorCode);
    },
    validateFields(): boolean {
      const validateField = (code: string, value: unknown): boolean => {
        const field = this.fields[code];
        if (field.optional) {
          return true;
        }
        type RefElement = { validateFields: () => boolean };
        const {
          INPUT,
          TEXTAREA,
          SELECT,
          CHECKBOX,
          ICON,
          COLOR_PICKER,
          IMAGE_URLS,
          CONTENT_BLOCKS,
          CHOICE_FIELDS,
          MAP_SETTINGS,
        } = EDITOR_ELEMENTS;
        if (field.editorElement === CHECKBOX) {
          return true;
        }
        if ([
          IMAGE_URLS,
          CHOICE_FIELDS,
          MAP_SETTINGS,
        ].includes(field.editorElement)) {
          const refsElements = this.$refs[this.getEditorElementRefCode(code)] as RefElement[];
          return refsElements.every((refElement) => refElement.validateFields());
        }
        if ([
          INPUT,
          TEXTAREA,
          SELECT,
          ICON,
          COLOR_PICKER,
        ].includes(field.editorElement)) {
          return !!value;
        }
        if (field.editorElement === CONTENT_BLOCKS) {
          if (!Array.isArray(value) || value.length === 0) {
            return false;
          }
          if (field.contentComponent === EDITOR_ELEMENTS.INPUT) {
            return !value.includes('');
          }
          const refsElements = this.$refs[this.getEditorElementRefCode(code)] as RefElement[];
          return refsElements.every((refElement) => refElement.validateFields());
        }
        return true;
      };
      let result = true;
      Object.entries(this.value).forEach(([code, value]) => {
        const fieldValidateSuccess = validateField(code, value);
        result = result && fieldValidateSuccess;
        if (!fieldValidateSuccess) {
          this.setError(code, ERRORS_CODES.REQUIRED);
        }
      });
      return result;
    },
    getEditorElementRefCode(code: string) {
      return `editorElement:${code}`;
    },
  },
  watch: {
    value(next: Value, prev: Value) {
      const diff = getDeepDiff(prev, next);
      if (!diff) {
        return;
      }
      Object.keys(diff).forEach((code: string) => this.removeError(code, ERRORS_CODES.REQUIRED));
    },
  },
});
