import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkbox-styles-editor" }
const _hoisted_2 = { class: "checkbox-styles-editor__settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_styles_editor = _resolveComponent("icon-styles-editor")!
  const _component_invitations_constructor_field = _resolveComponent("invitations-constructor-field")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_text_styles_editor = _resolveComponent("text-styles-editor")!
  const _component_checkbox_block = _resolveComponent("checkbox-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_invitations_constructor_field, { class: "checkbox-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Настройки иконки внутри чекбокса")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_icon_styles_editor, {
            required: "",
            value: _ctx.value.iconStyles,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('iconStyles', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "checkbox-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Цвет круга")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_color_picker, {
            value: _ctx.value.circleColor,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('circleColor', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_invitations_constructor_field, { class: "checkbox-styles-editor__item" }, {
        label: _withCtx(() => [
          _createTextVNode("Стили подписи около чекбокса")
        ]),
        value: _withCtx(() => [
          _createVNode(_component_text_styles_editor, {
            value: _ctx.value.labelStyles,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('labelStyles', $event)))
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_checkbox_block, _mergeProps({ class: "checkbox-styles-editor__preview" }, _ctx.value, {
      value: _ctx.localCheckboxValue,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localCheckboxValue) = $event))
    }), {
      default: _withCtx(() => [
        _createTextVNode("Превью")
      ]),
      _: 1
    }, 16, ["value"])
  ]))
}