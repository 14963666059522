// eslint-disable-next-line import/prefer-default-export
export function goToFirstError(container: HTMLElement): void {
  const inputHolder = (container || document).querySelector('.input-holder_error');
  if (!inputHolder) {
    return;
  }
  inputHolder.scrollIntoView({ behavior: 'smooth', block: 'center' });
  const inputElement = inputHolder.querySelector('input');
  if (inputElement) {
    inputElement.focus({ preventScroll: true });
  }
}
