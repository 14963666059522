import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "end-block-inv__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: _normalizeClass(_ctx.bem('end-block-inv', { fullHeight: _ctx.computedOptions.fullScreen })),
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "end-block-inv__title" }, _ctx.computedOptions.titleStyles, { size: [50, 30] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.title), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives(_createVNode(_component_svg_icon, _mergeProps({ class: "end-block-inv__icon" }, _ctx.computedOptions.iconStyles), null, 16), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["class", "background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}