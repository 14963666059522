
import { defineComponent, PropType } from 'vue';
import DresscodeBlockInterfaceOptions from '@/data/e-invitations/block-options/dresscode-block';
import defaultOptions from '@/data/e-invitations/block-default-options/dresscode-block';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

const MAX_COLORS_IN_ROW = 5;

export default defineComponent({
  inheritAttrs: false,
  name: 'dresscode-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    SvgIcon,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<DresscodeBlockInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): DresscodeBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
  methods: {
    getColorsRows(colors: string[]): string[][] {
      if (colors.length <= MAX_COLORS_IN_ROW) {
        return [colors];
      }
      const colorsCountInFirstRow = Math.ceil(colors.length / 2);
      return [
        colors.slice(0, colorsCountInFirstRow),
        colors.slice(colorsCountInFirstRow, colors.length),
      ];
    },
    getIconsSize(colors: string[]): string {
      return colors.length >= 5 ? 'm' : 'l';
    },
  },
});
