import FirstBlockGammaOptions from '@/data/e-invitations/block-options/first-block-gamma';

const defaultOptions: FirstBlockGammaOptions = {
  namesStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  ampersandColor: 'rgba(255, 255, 255, 0.3)',
  textBlock1Styles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  timeoutTitleText: 'До торжества осталось',
  timeoutTitleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  timeoutUnitsStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(255, 255, 255, 1)',
  },
  timeoutUnitsLabelsStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(255, 255, 255, 1)',
  },
  background: {
    imageUrls: {
      x1: 'https://i.imgur.com/FiMNnER.jpg',
    },
  },
};

export default defaultOptions;
