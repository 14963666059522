
import { defineComponent, PropType } from 'vue';
import { Names } from '@/data/e-invitations/types';
import FirstBlockAlphaInterfaceOptions from '@/data/e-invitations/block-options/first-block-epsilon';
import defaultOptions from '@/data/e-invitations/block-default-options/first-block-epsilon';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import { formatDate } from '@/tools/datetime';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'first-block-epsilon',
  components: {
    BlockWithBackground,
    TextBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    names: {
      type: Array as unknown as PropType<Names>,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<FirstBlockAlphaInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): FirstBlockAlphaInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
    firstNameBind() {
      return {
        ...this.computedOptions.namesStyles,
        size: [200, 96],
        lineHeight: 1,
      };
    },
    surNameBind() {
      return {
        ...this.computedOptions.namesStyles,
        size: [60, 24],
        lineHeight: 1,
      };
    },
  },
  methods: {
    formatDate,
  },
});
