
import { defineComponent, PropType } from 'vue';
import SvgIconType from '@/data/types/sprite-svg-icon';
import { Color } from '@/data/e-invitations/types';
import spriteManager from '@/tools/sprite-manager';

export default defineComponent({
  name: 'svg-icon',
  props: {
    name: {
      type: String as PropType<SvgIconType>,
      required: true,
    },
    fill: String as PropType<Color>,
    stroke: String as PropType<Color>,
    strokeWidth: Number,
    size: {
      type: Array as unknown as PropType<[number, number]>,
      default: () => [],
    },
  },
  watch: {
    name: {
      immediate: true,
      handler(name) {
        spriteManager.addIcon(name);
      },
    },
  },
});
