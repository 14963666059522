import FirstBlockNuOptions, { Position } from '@/data/e-invitations/block-options/first-block-nu';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';
import SelectOption from '@/data/types/select-option';

interface TypedBlockPosition extends SelectOption {
  value: Position;
}

interface LocalEditorConfigType extends EditorConfigType<FirstBlockNuOptions> {
  blockPosition: EditorConfigFieldType & {
    options: TypedBlockPosition[];
  };
}

const editorConfig: LocalEditorConfigType = {
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  lineColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет рамки',
    optional: true,
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Выбор символа',
  },
  topText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст сверху',
  },
  topTextStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили верхнего текста',
  },
  blockPosition: {
    editorElement: EDITOR_ELEMENTS.SELECT,
    label: 'Позиция блока',
    options: [
      {
        label: 'Слева',
        value: 'left',
      },
      {
        label: 'Центр',
        value: 'center',
      },
      {
        label: 'Справа',
        value: 'right',
      },
    ],
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
