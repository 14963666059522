import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "accept-block-2-inv__content" }
const _hoisted_2 = { class: "accept-block-2-inv__blocks-container content-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "accept-block-2-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "accept-block-2-inv__title" }, _ctx.computedOptions.titleStyles, { size: [60, 40] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.blocksText, (block, blockIndex) => {
            return _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
              key: blockIndex,
              class: "accept-block-2-inv__text-block",
              style: { '--desktop-width': block.desktopWidth }
            }, _ctx.computedOptions.textBlocksStyles, { size: _ctx.textSize }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(block.text), 1)
              ]),
              _: 2
            }, 1040, ["style", "size"])), [
              [_directive_element_intersection_animation_directive, { tact: blockIndex + 2 }]
            ])
          }), 128))
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "accept-block-2-inv__date text-align-center" }, _ctx.computedOptions.textBlocksStyles, { size: _ctx.textSize }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.textBeforeDate) + " ", 1),
            _createVNode(_component_text_block, _mergeProps({
              class: "font-size-inherit",
              tag: "span"
            }, _ctx.computedOptions.dateStyles), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.computedOptions.dateText), 1)
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }, 16, ["size"])), [
          [_directive_element_intersection_animation_directive, { tact: _ctx.computedOptions.blocksText.length + 2 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}