import SelectOption from '@/data/types/select-option';

export enum EDITOR_ELEMENTS {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  ICON = 'icon',
  COLOR_PICKER = 'color-picker',
  BACKGROUND = 'background',
  IMAGE_URLS = 'image-urls',
  TEXT_STYLES = 'text-styles',
  ICON_STYLES = 'icon-styles',
  BUTTON_STYLES = 'button-styles',
  CHECKBOX_STYLES = 'checkboxes-styles',
  INPUT_STYLES = 'input-styles',
  CONTENT_BLOCKS = 'content-blocks',
  CHOICE_FIELDS = 'choice-fields',
  DRESSCODE_COLORS_ICONS = 'dresscode-colors-icons',
  HISTORY_PERIOD_IMAGE = 'history-period-image',
  MAP_SETTINGS = 'map-settings',
}

export interface EditorConfigFieldType {
  editorElement: EDITOR_ELEMENTS;
  options?: SelectOption[];
  label: string;
  description?: string;
  contentFields?: unknown;
  contentComponent?: EDITOR_ELEMENTS;
  contentFieldsDefaultValue?: unknown;
  optional?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isVisible?: (block: any) => boolean;
}

export type EditorConfigType<T> = {
  [K in keyof T]-?: EditorConfigFieldType;
};
