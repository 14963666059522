import { createApp, App as AppInstance } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import mixins from './mixins/global';
import { dnsUrl, tracePropagationTargets, tracesSampleRate } from '@/data/constants/sentry';

import '@/styles/main.less';

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    tracesSampleRate,
    dsn: dnsUrl,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets,
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  });
}

const MyPlugin = {
  install: (Vue: AppInstance) => {
    Vue.mixin(mixins);
  },
};

app.use(createPinia()).use(router).use(MyPlugin).mount('#app');
