import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_img_block = _resolveComponent("img-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page-demo-e-invitations",
    style: _normalizeStyle({ backgroundColor: _ctx.background?.color })
  }, [
    (_ctx.background)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.background.left)
            ? (_openBlock(), _createBlock(_component_img_block, {
                key: 0,
                class: _normalizeClass(_ctx.bem(
          'page-demo-e-invitations__background',
          { left: true, fullSize: _ctx.background.left.fullSize || 'height' },
        )),
                style: _normalizeStyle({ ..._ctx.getStyleVarsBackgroundOffsets(_ctx.background.left.offset) }),
                src: _ctx.background.left.imageUrls.x1,
                src2x: _ctx.background.left.imageUrls.x2,
                "mobile-src": _ctx.background.left.imageUrls.x1Mobile,
                "mobile-src2x": _ctx.background.left.imageUrls.x2Mobile
              }, null, 8, ["class", "style", "src", "src2x", "mobile-src", "mobile-src2x"]))
            : _createCommentVNode("", true),
          (_ctx.background.right)
            ? (_openBlock(), _createBlock(_component_img_block, {
                key: 1,
                class: _normalizeClass(_ctx.bem(
          'page-demo-e-invitations__background',
          { right: true, fullSize: _ctx.background.right.fullSize || 'height' },
        )),
                style: _normalizeStyle({ ..._ctx.getStyleVarsBackgroundOffsets(_ctx.background.right.offset) }),
                src: _ctx.background.right.imageUrls.x1,
                src2x: _ctx.background.right.imageUrls.x2,
                "mobile-src": _ctx.background.right.imageUrls.x1Mobile,
                "mobile-src2x": _ctx.background.right.imageUrls.x2Mobile
              }, null, 8, ["class", "style", "src", "src2x", "mobile-src", "mobile-src2x"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBlocks, (block, blockIndex) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName(block.code)), _mergeProps({
        key: blockIndex,
        options: block.options
      }, _ctx.pageFieldsWithoutBlocks, {
        guests: _ctx.guests,
        "feedback-sending": _ctx.isSending,
        "successfully-sent": _ctx.successfullySent,
        "presence-status": _ctx.presenceStatus,
        "onUpdate:presenceStatus": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.presenceStatus) = $event)),
        onPublishMethods: _cache[1] || (_cache[1] = ($event: any) => (_ctx.blocksMethods.push($event))),
        onWithdrawMethods: _ctx.deleteBlockMethods,
        onAddGuest: _ctx.addGuest,
        onUpdateGuest: _ctx.updateGuest,
        onDeleteGuest: _ctx.deleteGuest
      }), null, 16, ["options", "guests", "feedback-sending", "successfully-sent", "presence-status", "onWithdrawMethods", "onAddGuest", "onUpdateGuest", "onDeleteGuest"]))
    }), 128))
  ], 4))
}