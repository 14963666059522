import FirstBlockDeltaOptions from '@/data/e-invitations/block-options/first-block-delta';

const defaultOptions: FirstBlockDeltaOptions = {
  namesStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(39, 39, 39, 1)',
  },
  ampersandColor: 'rgba(175, 175, 175, 0.3)',
  textStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(39, 39, 39, 1)',
  },
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(39, 39, 39, 1)',
  },
  lineColor: null,
  text: null,
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
