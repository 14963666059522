
import { defineComponent, PropType } from 'vue';
import { ColorsIcons } from '@/data/e-invitations/block-options/dresscode-block';
import IconStylesEditor from '@/blocks/icon-styles-editor';
import ListEditor from '@/blocks/list-editor';

export default defineComponent({
  name: 'dresscode-colors-icons-editor',
  components: {
    IconStylesEditor,
    ListEditor,
  },
  props: {
    value: Object as PropType<ColorsIcons>,
  },
  emits: ['update:value'],
  methods: {
    onInput<T extends keyof ColorsIcons>(code: T, value: ColorsIcons[T]) {
      const newValue: ColorsIcons = {
        ...((this.value || {}) as ColorsIcons),
        [code]: value,
      };
      this.$emit('update:value', newValue);
    },
  },
});
