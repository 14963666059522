
import { defineComponent, PropType } from 'vue';
import AcceptBlock1InterfaceOptions from '@/data/e-invitations/block-options/accept-block-1';
import defaultOptions from '@/data/e-invitations/block-default-options/accept-block-1';
import BlockWithBackground from '@/blocks/block-with-background';
import ImgBlock from '@/blocks/img';
import TextBlock from '@/blocks/text';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'accept-block-1-inv',
  components: {
    BlockWithBackground,
    ImgBlock,
    TextBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<AcceptBlock1InterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): AcceptBlock1InterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
});
