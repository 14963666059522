import FirstBlockGammaOptions from '@/data/e-invitations/block-options/first-block-gamma';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<FirstBlockGammaOptions> = {
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  ampersandColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет амперсанда на заднем фоне имен',
  },
  textBlock1Styles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили первого блока текста',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  timeoutTitleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Надпись около таймера',
  },
  timeoutTitleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили надписи около таймера',
  },
  timeoutUnitsStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили цифр таймера',
  },
  timeoutUnitsLabelsStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили единиц времени таймера',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
