import FirstBlockZetaOptions from '@/data/e-invitations/block-options/first-block-zeta';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<FirstBlockZetaOptions> = {
  iconStyles: {
    editorElement: EDITOR_ELEMENTS.ICON_STYLES,
    label: 'Стили иконки',
  },
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  namesSeparatorColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет разделителя имен',
  },
  contentStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста контента',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  contentText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст контента',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
