import ButtonBlockOptions from '@/data/e-invitations/block-options/button-block';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<ButtonBlockOptions> = {
  type: {
    editorElement: EDITOR_ELEMENTS.SELECT,
    label: 'Тип кнопки',
    options: [
      {
        label: 'Разместить',
        value: 'submit',
      },
      {
        label: 'Ссылка',
        value: 'link',
      },
    ],
  },
  link: {
    editorElement: EDITOR_ELEMENTS.INPUT,
    label: 'Ссылка',
    optional: true,
    isVisible: (block: ButtonBlockOptions) => block.type === 'link',
  },
  buttonStyles: {
    editorElement: EDITOR_ELEMENTS.BUTTON_STYLES,
    label: 'Стили кнопки',
  },
  buttonText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст кнопки',
  },
  buttonSuccessText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст кнопки после отправки',
    description: 'Отображается когда форма успешно отправлена',
    isVisible: (block: ButtonBlockOptions) => block.type === 'submit',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
