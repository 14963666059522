import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "presence-block-inv__checkboxes-container" }
const _hoisted_2 = { class: "presence-block-inv__names-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_checkbox_block = _resolveComponent("checkbox-block")!
  const _component_presence_block_inv__name = _resolveComponent("presence-block-inv__name")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_button_block = _resolveComponent("button-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "presence-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["presence-block-inv__content", _ctx.bem('content-width', { desktopOnly: true })])
      }, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "presence-block-inv__title" }, _ctx.computedOptions.titleStyles, { size: [60, 35] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_checkbox_block, _mergeProps({ class: "presence-block-inv__checkbox" }, _ctx.computedOptions.checkboxStyles, {
            value: _ctx.presenceStatus === _ctx.PRESENCE_STATUSES.ACCEPT,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPresenceStatus(_ctx.PRESENCE_STATUSES.ACCEPT)))
          }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.computedOptions.acceptCheckboxLabel), 1)
            ]),
            _: 1
          }, 16, ["value"]),
          _createVNode(_component_checkbox_block, _mergeProps({ class: "presence-block-inv__checkbox" }, _ctx.computedOptions.checkboxStyles, {
            value: _ctx.presenceStatus === _ctx.PRESENCE_STATUSES.DECLINE,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPresenceStatus(_ctx.PRESENCE_STATUSES.DECLINE)))
          }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.computedOptions.declineCheckboxLabel), 1)
            ]),
            _: 1
          }, 16, ["value"]),
          (_ctx.computedOptions.allowPostponeStatus)
            ? (_openBlock(), _createBlock(_component_checkbox_block, _mergeProps({
                key: 0,
                class: "presence-block-inv__checkbox"
              }, _ctx.computedOptions.checkboxStyles, {
                value: _ctx.presenceStatus === _ctx.PRESENCE_STATUSES.POSTPONE,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPresenceStatus(_ctx.PRESENCE_STATUSES.POSTPONE)))
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.computedOptions.postponeCheckboxLabel), 1)
                ]),
                _: 1
              }, 16, ["value"]))
            : _createCommentVNode("", true)
        ])), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "presence-block-inv__subtitle" }, _ctx.computedOptions.subtitleStyles, { size: [23, 14] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.subtitleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 3 }]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guests, (guest, guestIndex) => {
            return (_openBlock(), _createBlock(_component_presence_block_inv__name, {
              key: guestIndex,
              "first-name": guest.firstName,
              "sur-name": guest.surName,
              "input-type": _ctx.computedOptions.inputType,
              "input-styles": _ctx.computedOptions.inputStyles,
              "first-name-placeholder": _ctx.computedOptions.firstNamePlaceholder,
              "sur-name-placeholder": _ctx.computedOptions.surNamePlaceholder,
              "sur-name-required": guestIndex === 0,
              "can-delete": guestIndex !== 0,
              "delete-button-weight": _ctx.computedOptions.nameDeleteButtonWeight,
              "delete-button-color": _ctx.computedOptions.nameDeleteButtonColor,
              onPublishMethods: _cache[3] || (_cache[3] = ($event: any) => (_ctx.nameBlocksMethods.push($event))),
              onUpdateName: ($event: any) => (_ctx.editName({ ...$event, index: guestIndex })),
              onDelete: ($event: any) => (_ctx.deleteName(guestIndex)),
              onUnmount: _ctx.deleteBlockMethods
            }, null, 8, ["first-name", "sur-name", "input-type", "input-styles", "first-name-placeholder", "sur-name-placeholder", "sur-name-required", "can-delete", "delete-button-weight", "delete-button-color", "onUpdateName", "onDelete", "onUnmount"]))
          }), 128))
        ])), [
          [_directive_element_intersection_animation_directive, { tact: 4 }]
        ]),
        (_ctx.computedOptions.addButtonType === 'plus')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createVNode(_component_svg_icon, {
                class: "presence-block-inv__plus",
                name: "plus",
                stroke: _ctx.computedOptions.plusColor,
                onClick: _ctx.addName
              }, null, 8, ["stroke", "onClick"]), [
                [_directive_element_intersection_animation_directive, { tact: 5 }]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "presence-block-inv__note" }, _ctx.computedOptions.noteStyles, { size: [20, 14] }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.computedOptions.noteText), 1)
                ]),
                _: 1
              }, 16)), [
                [_directive_element_intersection_animation_directive, { tact: 6 }]
              ])
            ], 64))
          : _withDirectives((_openBlock(), _createBlock(_component_button_block, {
              key: 1,
              class: "presence-block-inv__add-button",
              styles: _ctx.computedOptions.buttonStyles,
              onClick: _ctx.addName
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.computedOptions.addButtonText), 1)
              ]),
              _: 1
            }, 8, ["styles", "onClick"])), [
              [_directive_element_intersection_animation_directive, { tact: 5 }]
            ])
      ], 2)
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}