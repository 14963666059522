import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "countdown" }
const _hoisted_2 = { class: "countdown__items-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_text_block, _mergeProps({ class: "text-align-center relative" }, _ctx.titleStyles, { size: [30, 18] }), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.titleText), 1)
      ]),
      _: 1
    }, 16),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ value, unit }, itemIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: unit,
          class: _normalizeClass(_ctx.bem('countdown__item', { colonSeparator: !_ctx.separatorIconStyles }))
        }, [
          (_ctx.separatorIconStyles && itemIndex !== 0)
            ? (_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
                key: 0,
                class: "countdown__item-separator"
              }, _ctx.separatorIconStyles), null, 16))
            : _createCommentVNode("", true),
          _createVNode(_component_text_block, _mergeProps({ class: "countdown__item-value" }, _ctx.countdownUnitsStyles, {
            size: [90, 55],
            "line-height": 1
          }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatValue(value)), 1)
            ]),
            _: 2
          }, 1040),
          _createVNode(_component_text_block, _mergeProps({ size: [25, 16] }, _ctx.countdownUnitsLabelsStyles), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(unit), 1)
            ]),
            _: 2
          }, 1040)
        ], 2))
      }), 128))
    ])
  ]))
}