
import { defineComponent, PropType } from 'vue';
import { Field as ChoiceField } from '@/data/e-invitations/block-options/choice-block';
import { CHOICE_BLOCK_TYPES } from '@/data/e-invitations/types';
import SelectOption from '@/data/types/select-option';
import ERRORS_CODES from '@/data/constants/error-codes';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import CheckboxBlock from '@/blocks/checkbox';
import SelectBlock from '@/blocks/select';
import InputHolder from '@/blocks/input-holder';
import ListEditor from '@/blocks/list-editor';
import InputBlock from '@/blocks/input';

interface TypeOption extends SelectOption {
  value: CHOICE_BLOCK_TYPES;
}

export default defineComponent({
  name: 'choice-fields-editor',
  components: {
    InvitationsConstructorField,
    CheckboxBlock,
    SelectBlock,
    InputHolder,
    ListEditor,
    InputBlock,
  },
  props: {
    value: {
      type: Object as PropType<ChoiceField>,
      required: true,
    },
  },
  emits: ['update:value'],
  expose: ['validateFields'],
  data: () => ({
    CHOICE_BLOCK_TYPES,
    errors: {} as Record<keyof ChoiceField, Set<ERRORS_CODES>>,
  }),
  computed: {
    typeOptions(): TypeOption[] {
      return [
        {
          label: 'Чекбокс',
          value: CHOICE_BLOCK_TYPES.CHECKBOX,
        },
        {
          label: 'Инпут',
          value: CHOICE_BLOCK_TYPES.INPUT,
        },
      ];
    },
  },
  methods: {
    onInput<T extends keyof ChoiceField>(code: T, value: ChoiceField[T]): void {
      this.$emit('update:value', {
        ...this.value,
        [code]: value,
      });
    },
    setError(fieldCode: keyof ChoiceField, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        this.errors[fieldCode] = new Set<ERRORS_CODES>();
      }
      this.errors[fieldCode].add(errorCode);
    },
    removeError(fieldCode: keyof ChoiceField, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        return;
      }
      this.errors[fieldCode].delete(errorCode);
    },
    validateFields(): boolean {
      const { type, checkboxes } = this.value;
      const checkboxesHasError = type === CHOICE_BLOCK_TYPES.CHECKBOX && checkboxes?.length === 0;
      if (checkboxesHasError) {
        this.setError('checkboxes', ERRORS_CODES.REQUIRED);
      }
      return !checkboxesHasError;
    },
  },
  watch: {
    'value.checkboxes': function handler() {
      this.removeError('checkboxes', ERRORS_CODES.REQUIRED);
    },
    'value.type': function handler() {
      this.removeError('checkboxes', ERRORS_CODES.REQUIRED);
    },
  },
});
