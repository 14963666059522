import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = {
  key: 0,
  class: "table__content"
}
const _hoisted_3 = ["data-code"]
const _hoisted_4 = { class: "table__body" }
const _hoisted_5 = ["data-code"]
const _hoisted_6 = {
  key: 1,
  class: "table__dropdown-list"
}
const _hoisted_7 = { class: "table__dropdown-body" }
const _hoisted_8 = {
  key: 2,
  class: "table__no-rows-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_table__mobile_pair = _resolveComponent("table__mobile-pair")!
  const _component_dropdown_block = _resolveComponent("dropdown-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.bem('table__row', { head: true }))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("div", {
                key: column.code,
                class: _normalizeClass(_ctx.bem('table__cell-holder', { type: column.cellType, head: true })),
                style: _normalizeStyle({ width: _ctx.getCellWidth(column) }),
                "data-code": column.code
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.bem('table__cell', { head: true }))
                }, [
                  _createVNode(_component_text_block, { tag: "span" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(column.label), 1)
                    ]),
                    _: 2
                  }, 1024)
                ], 2)
              ], 14, _hoisted_3))
            }), 128))
          ], 2),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: rowIndex,
                class: _normalizeClass(_ctx.bem('table__row', { body: true }))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: column.code,
                    class: _normalizeClass(_ctx.bem('table__cell-holder', { type: column.cellType, body: true })),
                    style: _normalizeStyle({ width: _ctx.getCellWidth(column) }),
                    "data-code": column.code
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.bem('table__cell', { body: true }))
                    }, [
                      _renderSlot(_ctx.$slots, 'cell:' + column.code, { row: row }, () => [
                        _createVNode(_component_text_block, { tag: "span" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(row[column.code]), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ], 2)
                  ], 14, _hoisted_5))
                }), 128))
              ], 2))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
            return (_openBlock(), _createBlock(_component_dropdown_block, {
              key: rowIndex,
              class: "table__dropdown"
            }, {
              head: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobileHeadColumns, (column, columnIndex) => {
                  return (_openBlock(), _createBlock(_component_table__mobile_pair, {
                    key: columnIndex,
                    class: "table__dropdown-pair"
                  }, _createSlots({
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(column.label), 1)
                    ]),
                    _: 2
                  }, [
                    (_ctx.$slots[`cell:${column.code}`])
                      ? {
                          name: "value",
                          fn: _withCtx(() => [
                            _renderSlot(_ctx.$slots, 'cell:' + column.code, { row: row })
                          ]),
                          key: "0"
                        }
                      : {
                          name: "value-text",
                          fn: _withCtx(() => [
                            _createTextVNode(_toDisplayString(row[column.code] || '-'), 1)
                          ]),
                          key: "1"
                        }
                  ]), 1024))
                }), 128))
              ]),
              body: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobileBodyColumns, (column, columnIndex) => {
                    return (_openBlock(), _createBlock(_component_table__mobile_pair, {
                      key: columnIndex,
                      class: "table__dropdown-pair"
                    }, _createSlots({
                      label: _withCtx(() => [
                        _createTextVNode(_toDisplayString(column.label), 1)
                      ]),
                      _: 2
                    }, [
                      (_ctx.$slots[`cell:${column.code}`])
                        ? {
                            name: "value",
                            fn: _withCtx(() => [
                              _renderSlot(_ctx.$slots, 'cell:' + column.code, { row: row })
                            ]),
                            key: "0"
                          }
                        : {
                            name: "value-text",
                            fn: _withCtx(() => [
                              _createTextVNode(_toDisplayString(row[column.code] || '-'), 1)
                            ]),
                            key: "1"
                          }
                    ]), 1024))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ])),
    (_ctx.rows.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Нет данных "))
      : _createCommentVNode("", true)
  ]))
}