import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-delta__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_names_with_ampersand = _resolveComponent("names-with-ampersand")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-delta",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_names_with_ampersand, {
          class: "first-block-delta__names",
          size: "l",
          names: _ctx.names,
          "names-styles": _ctx.computedOptions.namesStyles,
          "ampersand-color": _ctx.computedOptions.ampersandColor
        }, null, 8, ["names", "names-styles", "ampersand-color"]), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        (_ctx.computedOptions.text)
          ? _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
              key: 0,
              class: "first-block-delta__text"
            }, _ctx.computedOptions.textStyles, { size: [30, 18] }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.computedOptions.text), 1)
              ]),
              _: 1
            }, 16)), [
              [_directive_element_intersection_animation_directive, { tact: 2 }]
            ])
          : _createCommentVNode("", true),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-delta__date" }, _ctx.computedOptions.dateStyles, { size: [60, 36] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 3 }]
        ]),
        (_ctx.computedOptions.lineColor)
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "first-block-delta__line",
              style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.lineColor })
            }, null, 4)), [
              [_directive_element_intersection_animation_directive, { tact: 4 }]
            ])
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}