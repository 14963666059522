import DresscodeBlockOptions, { Block } from '@/data/e-invitations/block-options/dresscode-block';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';
import { SIZE_STRING_DESCRIPTION } from '@/data/constants/editor-fields-texts';

interface LocalEditorConfigType extends EditorConfigType<DresscodeBlockOptions> {
  blocks: EditorConfigFieldType & {
    contentFields: EditorConfigType<Block>;
    contentFieldsDefaultValue: Required<Block>;
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  subtitleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили подзаголовков',
  },
  textStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  blocks: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Блоки',
    contentFields: {
      subtitle: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Подзаголовок',
      },
      text: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Текст',
      },
      desktopWidth: {
        editorElement: EDITOR_ELEMENTS.INPUT,
        label: 'Ширина блока на десктопе',
        description: SIZE_STRING_DESCRIPTION,
        optional: true,
      },
      colorsIcons: {
        editorElement: EDITOR_ELEMENTS.DRESSCODE_COLORS_ICONS,
        label: 'Настройка цветов',
        optional: true,
      },
    },
    contentFieldsDefaultValue: {
      subtitle: '',
      text: '',
      desktopWidth: '',
      colorsIcons: {
        colors: [''],
        iconStyles: {
          name: 'heart-filled',
        },
      },
    },
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
