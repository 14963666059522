import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-zeta__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-zeta",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_svg_icon, _mergeProps({ class: "first-block-zeta__icon" }, _ctx.computedOptions.iconStyles), null, 16), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-zeta__title" }, _ctx.computedOptions.titleStyles, { size: [30, 18] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 2 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
          class: _ctx.bem('first-block-zeta__name', { first: true })
        }, _ctx.computedOptions.namesStyles, {
          size: [50, 24],
          "line-height": 1
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getFullName(_ctx.names[0])), 1)
          ]),
          _: 1
        }, 16, ["class"])), [
          [_directive_element_intersection_animation_directive, { tact: 3 }]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "first-block-zeta__names-separator",
          style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.namesSeparatorColor })
        }, null, 4), [
          [_directive_element_intersection_animation_directive, { tact: 4 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
          class: _ctx.bem('first-block-zeta__name', { second: true })
        }, _ctx.computedOptions.namesStyles, {
          size: [50, 24],
          "line-height": 1
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getFullName(_ctx.names[1])), 1)
          ]),
          _: 1
        }, 16, ["class"])), [
          [_directive_element_intersection_animation_directive, { tact: 5 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-zeta__text-content" }, _ctx.computedOptions.contentStyles, { size: [30, 18] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.contentText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 6 }]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-zeta__date" }, _ctx.computedOptions.dateStyles, { size: [60, 36] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 7 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}