import ChoiceBlockOptions from '@/data/e-invitations/block-options/choice-block';
import { INPUT_TYPE } from '@/data/types/input';
import { CHOICE_BLOCK_TYPES } from '@/data/e-invitations/types';

const defaultOptions: ChoiceBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  checkboxesStyles: {
    iconStyles: {
      name: 'leaf-filled',
      fill: 'rgba(0, 0, 0, 1)',
    },
    circleColor: 'rgba(0, 0, 0, 1)',
    labelStyles: {
      font: 'century-gothic',
      weight: 400,
      color: 'rgba(31, 31, 31, 1)',
    },
  },
  inputType: INPUT_TYPE.TYPE_1,
  inputStyles: {
    backgroundColor: 'rgba(233, 233, 233, 1)',
    borderColor: 'rgba(0, 0, 0, 1)',
    textStyles: {
      font: 'century-gothic',
      weight: 400,
      color: 'rgba(0, 0, 0, 1)',
    },
    placeholderStyles: {
      font: 'century-gothic',
      weight: 400,
      color: 'rgba(100, 100, 100, 1)',
    },
  },
  blocks: [
    {
      title: 'Предпочтения по напиткам',
      fields: [
        {
          required: true,
          type: CHOICE_BLOCK_TYPES.CHECKBOX,
          multiple: true,
          checkboxes: [
            'Коньяк',
            'Виски',
            'Вино белое',
            'Вино красное',
            'Шампанское',
            'Водка',
            'Безалкогольные напитки',
          ],
        },
      ],
    },
    {
      title: 'Дополнительные пожелания',
      fields: [
        {
          type: CHOICE_BLOCK_TYPES.INPUT,
          inputPlaceholder: 'Аллергии, пост и др.',
        },
      ],
    },
  ],
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
