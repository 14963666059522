import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-eta__content" }
const _hoisted_2 = { class: "first-block-eta__names-holder" }
const _hoisted_3 = { class: "first-block-eta__date-holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-eta",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.namesItems, (nameItem, nameItemIndex) => {
            return _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
              key: nameItemIndex,
              class: "first-block-eta__names-item"
            }, _ctx.computedOptions.namesStyles, { size: [170, 85] }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(nameItem), 1)
              ]),
              _: 2
            }, 1040)), [
              [_directive_element_intersection_animation_directive, { tact: 1 + nameItemIndex }]
            ])
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splittedDate, (dateItem, dateItemIndex) => {
            return _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
              key: dateItemIndex,
              class: "first-block-eta__date-item"
            }, _ctx.computedOptions.dateStyles, { size: [220, 140] }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(dateItem), 1)
              ]),
              _: 2
            }, 1040)), [
              [_directive_element_intersection_animation_directive, {
            tact: 1 + _ctx.namesItems.length + dateItemIndex,
          }]
            ])
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}