import HistoryBlockOptions, { TextBlock, Period } from '@/data/e-invitations/block-options/history-block';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';
import { SIZE_STRING_DESCRIPTION } from '@/data/constants/editor-fields-texts';

type TextBlockEditorConfig = EditorConfigFieldType & {
  contentFields: EditorConfigType<TextBlock>;
  contentFieldsDefaultValue: Required<TextBlock>;
};

interface LocalEditorConfigType extends EditorConfigType<HistoryBlockOptions> {
  periods: EditorConfigFieldType & {
    contentFields: EditorConfigType<Period> & {
      blocks: TextBlockEditorConfig;
      firstPersonBlocks: TextBlockEditorConfig;
      secondPersonBlocks: TextBlockEditorConfig;
    };
    contentFieldsDefaultValue: Required<Period>;
  };
}

const textBlocksEditorConfig: Omit<TextBlockEditorConfig, 'label'> = {
  editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
  contentFields: {
    text: {
      editorElement: EDITOR_ELEMENTS.TEXTAREA,
      label: 'Текст',
    },
    desktopWidth: {
      editorElement: EDITOR_ELEMENTS.INPUT,
      label: 'Ширина блока на десктопе',
      description: SIZE_STRING_DESCRIPTION,
      optional: true,
    },
  },
  contentFieldsDefaultValue: {
    text: '',
    desktopWidth: '',
  },
};

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  subtitleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили подзаголовка',
  },
  textStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста',
  },
  periodNameStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили номеров периодов',
  },
  firstPersonAvatarImageUrls: {
    editorElement: EDITOR_ELEMENTS.IMAGE_URLS,
    label: 'Ссылки на аватарку первого человека',
    optional: true,
  },
  secondPersonAvatarImageUrls: {
    editorElement: EDITOR_ELEMENTS.IMAGE_URLS,
    label: 'Ссылки на аватарку второго человека',
    optional: true,
  },
  separatorColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет раздилительной линии',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  subtitleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст подзаголовка',
  },
  periods: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Периоды',
    contentFields: {
      name: {
        editorElement: EDITOR_ELEMENTS.TEXTAREA,
        label: 'Имя',
      },
      image: {
        editorElement: EDITOR_ELEMENTS.HISTORY_PERIOD_IMAGE,
        label: 'Картинка',
        optional: true,
      },
      blocks: {
        ...textBlocksEditorConfig,
        label: 'Блоки',
      },
      firstPersonBlocks: {
        ...textBlocksEditorConfig,
        label: 'Блоки первого человека',
        optional: true,
      },
      secondPersonBlocks: {
        ...textBlocksEditorConfig,
        label: 'Блоки первого человека',
        optional: true,
      },
    },
    contentFieldsDefaultValue: {
      name: '',
      image: {
        desktopSize: 'full-width',
        urls: {
          x1: '',
        },
      },
      blocks: [
        textBlocksEditorConfig.contentFieldsDefaultValue,
      ],
      firstPersonBlocks: [
        textBlocksEditorConfig.contentFieldsDefaultValue,
      ],
      secondPersonBlocks: [
        textBlocksEditorConfig.contentFieldsDefaultValue,
      ],
    },
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
