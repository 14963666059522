import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "image-urls-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_block = _resolveComponent("input-block")!
  const _component_input_holder = _resolveComponent("input-holder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_holder, {
      class: "image-urls-editor__url",
      "error-codes": _ctx.errors.x1
    }, {
      default: _withCtx(() => [
        _createVNode(_component_input_block, {
          placeholder: "Ссылка на картинку десктоп х1",
          value: _ctx.value?.x1,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('x1', $event)))
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["error-codes"]),
    _createVNode(_component_input_block, {
      class: "image-urls-editor__url",
      placeholder: "Ссылка на картинку десктоп х2",
      value: _ctx.value?.x2,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('x2', $event)))
    }, null, 8, ["value"]),
    _createVNode(_component_input_block, {
      class: "image-urls-editor__url",
      placeholder: "Ссылка на картинку мобильную х1",
      value: _ctx.value?.x1Mobile,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('x1Mobile', $event)))
    }, null, 8, ["value"]),
    _createVNode(_component_input_block, {
      class: "image-urls-editor__url",
      placeholder: "Ссылка на картинку мобильную х2",
      value: _ctx.value?.x2Mobile,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('x2Mobile', $event)))
    }, null, 8, ["value"])
  ]))
}