
import { defineComponent, PropType } from 'vue';
import ButtonStyles from '@/data/types/button-styles';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import TextStylesEditor from '@/blocks/text-styles-editor';
import ColorPicker from '@/blocks/color-picker';
import ButtonBlock from '@/blocks/button';

export default defineComponent({
  name: 'button-styles-editor',
  components: {
    InvitationsConstructorField,
    TextStylesEditor,
    ColorPicker,
    ButtonBlock,
  },
  props: {
    value: {
      type: Object as PropType<ButtonStyles>,
      required: true,
    },
  },
  emits: ['update:value'],
  methods: {
    onInput<T extends keyof ButtonStyles>(code: T, value: ButtonStyles[T]): void {
      const newValue: ButtonStyles = {
        ...this.value,
        [code]: value,
      };
      this.$emit('update:value', newValue);
    },
  },
});
