import TextBlockOptions, {
  Block,
  Item,
  Contact,
  ContactType,
} from '@/data/e-invitations/block-options/text-block';
import {
  EditorConfigType,
  EditorConfigFieldType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';
import SelectOption from '@/data/types/select-option';
import { SIZE_STRING_DESCRIPTION } from '@/data/constants/editor-fields-texts';

interface ItemTypeOption extends SelectOption {
  value: Item['type'];
}

interface ContactTypeOption extends SelectOption {
  value: ContactType;
}

interface LocalEditorConfigType extends EditorConfigType<TextBlockOptions> {
  blocks: EditorConfigFieldType & {
    contentFields: EditorConfigType<Block> & {
      items: EditorConfigFieldType & {
        contentFields: EditorConfigType<Item> & {
          type: EditorConfigFieldType & {
            options: ItemTypeOption[];
          };
          contacts: EditorConfigFieldType & {
            contentFields: EditorConfigType<Contact> & {
              type: EditorConfigFieldType & {
                options: ContactTypeOption[];
              };
            };
            contentFieldsDefaultValue: Required<Contact>;
          };
        };
        contentFieldsDefaultValue: Required<Item>;
      };
    };
    contentFieldsDefaultValue: Required<Block>;
  };
}

const editorConfig: LocalEditorConfigType = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  textBlocksStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текстовых блоков',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили дат',
  },
  linkStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили ссылки',
  },
  contactsLabelStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текстов контактов',
  },
  contactsSvgColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет иконок контактов',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  blocks: {
    editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
    label: 'Блоки',
    contentFields: {
      desktopWidth: {
        editorElement: EDITOR_ELEMENTS.INPUT,
        label: 'Ширина блока на десктопе',
        description: SIZE_STRING_DESCRIPTION,
        optional: true,
      },
      items: {
        editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
        label: 'Элементы',
        contentFields: {
          type: {
            editorElement: EDITOR_ELEMENTS.SELECT,
            label: 'Тип элемента',
            options: [
              {
                label: 'Ссылка',
                value: 'link',
              },
              {
                label: 'Текст',
                value: 'text',
              },
              {
                label: 'Дата',
                value: 'date',
              },
              {
                label: 'Контакты',
                value: 'contacts',
              },
            ],
          },
          text: {
            editorElement: EDITOR_ELEMENTS.TEXTAREA,
            label: 'Текст',
            optional: true,
            isVisible: (item: Item) => (['link', 'text'] as Item['type'][]).includes(item.type),
          },
          href: {
            editorElement: EDITOR_ELEMENTS.TEXTAREA,
            label: 'Ссылка',
            optional: true,
            isVisible: (item: Item) => item.type === 'link',
          },
          contacts: {
            editorElement: EDITOR_ELEMENTS.CONTENT_BLOCKS,
            label: 'Контакты',
            optional: true,
            isVisible: (item: Item) => item.type === 'contacts',
            contentFields: {
              type: {
                editorElement: EDITOR_ELEMENTS.SELECT,
                label: 'Тип контакта',
                options: [
                  {
                    label: 'Инста',
                    value: 'inst',
                  },
                  {
                    label: 'Телефон',
                    value: 'phone',
                  },
                  {
                    label: 'Телеграм',
                    value: 'tg',
                  },
                  {
                    label: 'Электронная почта',
                    value: 'email',
                  },
                ],
              },
              value: {
                editorElement: EDITOR_ELEMENTS.TEXTAREA,
                label: 'Внутреннее значение контакта',
                description: 'Должно соответствовать своему типу. \n'
                  + 'Инста: кусочек ссылки после домена. Пример: https://www.instagram.com/dafner__/,  в поле должно быть записано dafner__\n'
                  + 'Телефон: Телефон без пробелов\n'
                  + 'Телеграм: Username без @. Пример: для @tuchinads в поле должно быть записано tuchinads\n'
                  + 'Электронная почта: Просто почта',
              },
              label: {
                editorElement: EDITOR_ELEMENTS.TEXTAREA,
                label: 'Отображаемое значение контакта',
                description: 'Может быть любым',
              },
            },
            contentFieldsDefaultValue: {
              type: 'inst',
              value: '',
              label: '',
            },
          },
        },
        contentFieldsDefaultValue: {
          type: 'text',
          text: '',
          href: '',
          contacts: [],
        },
      },
    },
    contentFieldsDefaultValue: {
      desktopWidth: '',
      items: [
        {
          type: 'text',
          text: '',
        },
      ],
    },
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
