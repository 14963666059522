import PresenceBlockOptions from '@/data/e-invitations/block-options/presence-block';
import { INPUT_TYPE } from '@/data/types/input';

const defaultOptions: PresenceBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  checkboxStyles: {
    iconStyles: {
      name: 'circle-filled',
      fill: 'rgba(0, 0, 0, 1)',
    },
    circleColor: 'rgba(0, 0, 0, 1)',
    labelStyles: {
      font: 'century-gothic',
      weight: 400,
      color: 'rgba(31, 31, 31, 1)',
    },
  },
  subtitleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  inputType: INPUT_TYPE.TYPE_2,
  inputStyles: {
    backgroundColor: 'rgba(233, 233, 233, 1)',
    borderColor: 'rgba(0, 0, 0, 1)',
    textStyles: {
      font: 'century-gothic',
      weight: 400,
      color: 'rgba(0, 0, 0, 1)',
    },
    placeholderStyles: {
      font: 'century-gothic',
      weight: 400,
      color: 'rgba(100, 100, 100, 1)',
    },
  },
  nameDeleteButtonWeight: 'regular',
  nameDeleteButtonColor: 'rgba(235, 193, 193, 1)',
  addButtonType: 'button',
  plusColor: 'rgba(235, 193, 193, 1)',
  buttonStyles: {
    textStyles: {
      font: 'century-gothic',
      weight: 700,
      color: 'rgba(31, 31, 31, 1)',
    },
    borderColor: 'rgba(0, 0, 0, 1)',
    hoverBackgroundColor: 'rgba(0, 0, 0, 1)',
    hoverTextColor: 'rgba(255, 255, 255, 1)',
  },
  noteStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(173, 173, 173, 1)',
  },
  allowPostponeStatus: false,
  titleText: 'Присутствие',
  acceptCheckboxLabel: 'Я приду / мы придем',
  declineCheckboxLabel: 'Не приду',
  postponeCheckboxLabel: 'Отвечу позже',
  subtitleText: 'Пожалуйста внесите ниже имена, кто с Вами придет',
  firstNamePlaceholder: 'Имя',
  surNamePlaceholder: 'Фамилия',
  noteText: 'Нажмите на плюсик, чтобы добавить человека',
  addButtonText: 'Добавить человека',
  background: {
    color: 'rgba(255, 255, 255, 0)',
  },
};

export default defaultOptions;
