import { RequireAtLeastOne } from '@/tools/types';

export type Url = string;

export type Color = 'transparent' | string;

export type Name = {
  firstName: string;
  surName?: string;
};

export type Names = [Name, Name];

export interface ImageUrls {
  x1?: Url;
  x2?: Url;
  x1Mobile?: Url;
  x2Mobile?: Url;
}

export type ImagePosition = 'center' | 'top-left' | 'top-center' | 'top-right' | 'center-left' | 'center-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export type ImageSize = 'contain' | 'cover';

export interface ImageSettings {
  position?: ImagePosition;
  size?: ImageSize;
}

export interface BackgroundInterface {
  color?: Color;
  imageUrls?: ImageUrls;
  imageSettings?: {
    desktop?: ImageSettings;
    mobile?: ImageSettings;
  };
}

export type Background = RequireAtLeastOne<BackgroundInterface, 'imageUrls' | 'color'>;

export interface FormPublishMethods {
  id: string;
  validateFields: () => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFields: () => any;
  resetFields?: () => void;
}

export interface FormWidthdrowMethods {
  id: string;
}

export interface FormResultField {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: (string | string[])[];
}

export enum PRESENCE_STATUSES {
  ACCEPT = 'accept',
  DECLINE = 'decline',
  POSTPONE = 'postpone',
}

export interface BeverageItem {
  name: string;
  count: number;
}

export interface FormResult {
  presenceStatus: PRESENCE_STATUSES;
  guests: Name[];
  guestPath?: string;
  createdDate: string;
  beverages?: BeverageItem[];
  fields: FormResultField[];
}

export enum CHOICE_BLOCK_TYPES {
  CHECKBOX = 'checkbox',
  INPUT = 'input',
}

export interface WeddingDictionaryRecord {
  path: string;
  label: string;
  withPersonalization: boolean;
}

export interface WeddingDictionary {
  [key: string]: {
    label: string;
    withPersonalization: boolean;
  };
}

export interface InvitationPersonalizationItem {
  text: string;
  names: Name[];
  form: 'he' | 'she' | 'they' | 'he-formal' | 'she-formal';
}
