
import { defineComponent, PropType } from 'vue';
import ChoiceBlockInterfaceOptions, {
  Block as ChoiceContentBlock,
  Field as ChoiceContentField,
} from '@/data/e-invitations/block-options/choice-block';
import defaultOptions from '@/data/e-invitations/block-default-options/choice-block';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import InputHolder from '@/blocks/input-holder';
import CheckboxBlock from '@/blocks/checkbox';
import InputBlock from '@/blocks/input';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';
import { CHOICE_BLOCK_TYPES, FormResult, PRESENCE_STATUSES } from '@/data/e-invitations/types';
import ERRORS_CODES from '@/data/constants/error-codes';
import { uniqueId } from '@/tools';

type FieldValue = string | string[];
type BlockFieldsValues = FieldValue[];
type Values = BlockFieldsValues[];

interface Indexes {
  blockIndex: number;
  fieldIndex: number;
}

interface CheckboxParams extends Indexes {
  label: string;
}

interface InputParams extends Indexes {
  value?: string;
}

export default defineComponent({
  inheritAttrs: false,
  name: 'choice-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    InputHolder,
    CheckboxBlock,
    InputBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<ChoiceBlockInterfaceOptions>,
      required: true,
    },
    presenceStatus: String as PropType<PRESENCE_STATUSES>,
  },
  emits: ['publish-methods', 'withdraw-methods'],
  data: () => ({
    CHOICE_BLOCK_TYPES,
    id: uniqueId(),
    values: [] as Values,
    errorsIds: [] as string[],
  }),
  created() {
    this.$emit('publish-methods', {
      validateFields: this.validateFields,
      getFields: this.getFields,
      resetFields: this.resetFields,
      id: this.id,
    });
    this.setInitValues();
  },
  unmounted() {
    this.$emit('withdraw-methods', { id: this.id });
  },
  computed: {
    computedOptions(): ChoiceBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
  methods: {
    setInitValues(): void {
      this.values = this.computedOptions.blocks.map((block: ChoiceContentBlock) => block
        .fields.map((field: ChoiceContentField) => {
          if (field.type === CHOICE_BLOCK_TYPES.CHECKBOX && field.multiple) {
            return [];
          }
          return '';
        }));
    },
    isChecked({ blockIndex, fieldIndex, label }: CheckboxParams): boolean {
      return this.values[blockIndex][fieldIndex].includes(label);
    },
    toggleCheckbox({ blockIndex, fieldIndex, label }: CheckboxParams): void {
      this.deleteError({ blockIndex, fieldIndex });
      const { multiple } = this.computedOptions.blocks[blockIndex].fields[fieldIndex];
      if (!multiple) {
        this.values[blockIndex][fieldIndex] = label;
        return;
      }
      const isChecked = this.isChecked({ blockIndex, fieldIndex, label });
      if (isChecked) {
        const index = this.values[blockIndex][fieldIndex].indexOf(label);
        (this.values[blockIndex][fieldIndex] as string[]).splice(index, 1);
      } else {
        (this.values[blockIndex][fieldIndex] as string[]).push(label);
      }
    },
    getInputValue({ blockIndex, fieldIndex }: InputParams): string {
      return this.values[blockIndex][fieldIndex] as string;
    },
    setInputValue({ blockIndex, fieldIndex, value }: InputParams): void {
      this.deleteError({ blockIndex, fieldIndex });
      this.values[blockIndex].splice(fieldIndex, 1, value as string);
    },
    getBlockId({ blockIndex, fieldIndex }: Indexes) : string {
      return `${blockIndex}-${fieldIndex}`;
    },
    getErrorsCodes({ blockIndex, fieldIndex }: Indexes): [ERRORS_CODES] | undefined {
      const blockId = this.getBlockId({ blockIndex, fieldIndex });
      return this.errorsIds.includes(blockId) ? [ERRORS_CODES.REQUIRED] : undefined;
    },
    deleteError({ blockIndex, fieldIndex }: Indexes): void {
      const blockId = this.getBlockId({ blockIndex, fieldIndex });
      const index = this.errorsIds.indexOf(blockId);
      if (index !== -1) {
        this.errorsIds.splice(index, 1);
      }
    },
    validateFields(): boolean {
      let result = true;
      if (!this.presenceStatus) {
        return result;
      }
      this.computedOptions.blocks.forEach((block: ChoiceContentBlock, blockIndex: number) => {
        block.fields.forEach((field: ChoiceContentField, fieldIndex) => {
          const value = this.values[blockIndex][fieldIndex];
          if (field.required && (!value || value.length === 0)) {
            result = false;
            this.errorsIds.push(this.getBlockId({ blockIndex, fieldIndex }));
          }
        });
      });
      return result;
    },
    getFields(): { fields: FormResult['fields'] } {
      const result: FormResult['fields'] = [];
      this.computedOptions.blocks.forEach((block: ChoiceContentBlock, blockIndex: number) => {
        result.push({
          label: block.title,
          value: this.values[blockIndex],
        });
      });
      return { fields: result };
    },
    resetFields() {
      this.setInitValues();
      this.errorsIds = [];
    },
  },
  watch: {
    presenceStatus() {
      this.errorsIds = [];
    },
  },
});
