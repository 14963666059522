
import { defineComponent, PropType } from 'vue';
import PresenceBlockOptions from '@/data/e-invitations/block-options/presence-block';
import ChoiceBlockOptions from '@/data/e-invitations/block-options/choice-block';
import { InputStyles } from '@/data/types/input';
import InvitationsConstructorField from '@/blocks/invitations-constructor-field';
import ColorPicker from '@/blocks/color-picker';
import TextStylesEditor from '@/blocks/text-styles-editor';
import InputBlock from '@/blocks/input';

export default defineComponent({
  name: 'input-styles-editor',
  components: {
    InvitationsConstructorField,
    ColorPicker,
    TextStylesEditor,
    InputBlock,
  },
  props: {
    fieldsValue: {
      type: Object as PropType<PresenceBlockOptions | ChoiceBlockOptions>,
      required: true,
    },
    value: Object as PropType<InputStyles>,
  },
  emits: ['update:value'],
  data: () => ({
    localCheckboxValue: true,
  }),
  methods: {
    onInput<T extends keyof InputStyles>(code: T, value: InputStyles[T]): void {
      this.$emit('update:value', {
        ...this.value,
        [code]: value,
      });
    },
  },
});
