import DresscodeBlockOptions from '@/data/e-invitations/block-options/dresscode-block';

const defaultOptions: DresscodeBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  subtitleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  textStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  titleText: 'Дресс-код',
  blocks: [
    {
      text: 'Мы будем рады, если Вы поддержите цветовую гамму торжества',
      desktopWidth: '433px',
    },
    {
      subtitle: 'Мужчины',
      text: 'Классический темный костюм',
    },
    {
      subtitle: 'Дамы',
      text: 'Вечерние или коктейльные платья',
    },
    {
      colorsIcons: {
        iconStyles: {
          name: 'heart-filled',
        },
        colors: [
          'rgba(94, 109, 98, 1)',
          'rgba(160, 180, 171, 1)',
          'rgba(155, 120, 101, 1)',
          'rgba(207, 182, 163, 1)',
          'rgba(220, 173, 152, 1)',
        ],
      },
    },
  ],
  background: {
    color: 'transparent',
  },
};

export default defaultOptions;
