
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';
import useMediaQueriesStore from '@/store/media-queries';
import loadPromise from '@/tools/load-promise';
import { Column } from '@/data/types/table';
import TextBlock from '@/blocks/text';
import DropdownBlock from '@/blocks/dropdown';
// eslint-disable-next-line camelcase
import Table__mobilePair from './table__mobile-pair.vue';

type CellsWidthsMap = Record<string, number>;

export default defineComponent({
  name: 'table-block',
  components: {
    TextBlock,
    DropdownBlock,
    // eslint-disable-next-line vue/no-unused-components
    Table__mobilePair,
  },
  props: {
    columns: {
      type: Array as PropType<Column[]>,
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    contentCellsWidths: {} as CellsWidthsMap,
  }),
  mounted() {
    loadPromise.then(() => {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState(useMediaQueriesStore, ['isMobile']),
    mobileHeadColumns() {
      const result = this.columns.filter((column) => column.initialExpandedOnMobile);
      if (result.length === 0) {
        result.push(this.columns[0]);
      }
      return result;
    },
    mobileBodyColumns() {
      const result = this.columns.filter((column) => !column.initialExpandedOnMobile);
      if (result.length === this.columns.length) {
        result.splice(0, 1);
      }
      return result;
    },
  },
  methods: {
    async updateContentCellsWidth() {
      if (this.isMobile) {
        return;
      }
      this.contentCellsWidths = {};
      const result: CellsWidthsMap = {};
      await this.$nextTick();
      this.columns.forEach((column) => {
        if (column.cellType !== 'content') {
          return;
        }
        const cells: HTMLElement[] = Array.from(this.$el.querySelectorAll(`[data-code="${column.code}"]`));
        const cellsWidth: number[] = cells.map((cell: HTMLElement) => cell.offsetWidth);
        const maxWidth = Math.max(...cellsWidth);
        result[column.code] = maxWidth + 1;
      });
      this.contentCellsWidths = result;
    },
    onResize() {
      this.updateContentCellsWidth();
    },
    getCellWidth(column: Column): string | null {
      if (column.width) {
        return column.width;
      }
      if (column.cellType === 'content') {
        const width = this.contentCellsWidths[column.code];
        return width ? `${width}px` : null;
      }
      return null;
    },
  },
  watch: {
    async rows() {
      await this.$nextTick();
      this.updateContentCellsWidth();
    },
  },
});
