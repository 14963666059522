import { defineStore } from 'pinia';

const useTimeStore = defineStore('time', {
  state: () => ({
    subscribersCount: 0,
    currentTimestamp: 0,
    timestampUpdateIntervalId: undefined as undefined | number,
  }),
  actions: {
    subscribe(): void {
      if (this.subscribersCount === 0) {
        this.initTimeCounting();
      }
      this.subscribersCount += 1;
    },
    unsubscribe(): void {
      this.subscribersCount -= 1;
      if (this.subscribersCount === 0) {
        this.cancelTimeCounting();
      }
    },
    initTimeCounting(): void {
      this.timestampUpdateIntervalId = setInterval(() => {
        this.currentTimestamp = Date.now() / 1000;
      }, 100);
    },
    cancelTimeCounting(): void {
      clearInterval(this.timestampUpdateIntervalId);
    },
  },
});

export default useTimeStore;
