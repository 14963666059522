
import { defineComponent, PropType } from 'vue';
import InputHolder from '@/blocks/input-holder';
import InputBlock from '@/blocks/input';
import SvgIcon from '@/blocks/svg-icon';
import { INPUT_TYPE, InputStyles } from '@/data/types/input';
import ERRORS_CODES from '@/data/constants/error-codes';
import { Color, Name } from '@/data/e-invitations/types';
import { NameDeleteButtonWeight } from '@/data/e-invitations/block-options/presence-block';
import { uniqueId } from '@/tools';

export default defineComponent({
  inheritAttrs: false,
  name: 'presence-block-inv__name',
  components: {
    InputHolder,
    InputBlock,
    SvgIcon,
  },
  props: {
    inputType: String as PropType<INPUT_TYPE>,
    inputStyles: Object as PropType<InputStyles>,
    firstNamePlaceholder: String,
    surNamePlaceholder: String,
    firstName: String,
    surName: String,
    surNameRequired: Boolean,
    canDelete: Boolean,
    deleteButtonWeight: String as PropType<NameDeleteButtonWeight>,
    deleteButtonColor: String as PropType<Color>,
  },
  emits: ['publish-methods', 'update-name', 'delete', 'unmount'],
  data: () => ({
    firstNameErrorsCodes: new Set<ERRORS_CODES>(),
    surNameErrorsCodes: new Set<ERRORS_CODES>(),
    id: uniqueId(),
  }),
  created() {
    this.$emit('publish-methods', {
      id: this.id,
      validateFields: this.validateFields,
      getFields: this.getFields,
      resetFields: this.resetErrors,
    });
  },
  beforeUnmount() {
    this.$emit('unmount', { id: this.id });
  },
  methods: {
    emitNameEditing({ isSurName = false, value }: { isSurName?: boolean, value: string }) {
      this.$emit('update-name', { isSurName, value });
    },
    validateFields(): boolean {
      const { firstName, surName, surNameRequired } = this;
      let result = true;
      if (!firstName) {
        result = false;
        this.firstNameErrorsCodes.add(ERRORS_CODES.REQUIRED);
      }
      if (surNameRequired && !surName) {
        result = false;
        this.surNameErrorsCodes.add(ERRORS_CODES.REQUIRED);
      }
      return result;
    },
    getFields(): Name {
      const { firstName, surName } = this;
      return { firstName: firstName as string, surName };
    },
    resetErrors(): void {
      this.firstNameErrorsCodes.clear();
      this.surNameErrorsCodes.clear();
    },
  },
  watch: {
    firstName() {
      this.firstNameErrorsCodes.delete(ERRORS_CODES.REQUIRED);
    },
    surName() {
      this.surNameErrorsCodes.delete(ERRORS_CODES.REQUIRED);
    },
  },
});
