import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dresscode-block-inv__content content-width" }
const _hoisted_2 = { class: "dresscode-block-inv__blocks-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "dresscode-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "dresscode-block-inv__title" }, _ctx.computedOptions.titleStyles, { size: [60, 40] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions.blocks, (block, blockIndex) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: blockIndex,
              class: "dresscode-block-inv__block"
            }, [
              (block.subtitle)
                ? (_openBlock(), _createBlock(_component_text_block, _mergeProps({
                    key: 0,
                    class: "dresscode-block-inv__block-subtitle",
                    style: { '--desktop-width': block.desktopWidth }
                  }, _ctx.computedOptions.subtitleStyles, { size: [50, 30] }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(block.subtitle), 1)
                    ]),
                    _: 2
                  }, 1040, ["style"]))
                : _createCommentVNode("", true),
              (block.text)
                ? (_openBlock(), _createBlock(_component_text_block, _mergeProps({
                    key: 1,
                    class: "dresscode-block-inv__block-text",
                    style: { '--desktop-width': block.desktopWidth }
                  }, _ctx.computedOptions.textStyles, { size: [30, 18] }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(block.text), 1)
                    ]),
                    _: 2
                  }, 1040, ["style"]))
                : _createCommentVNode("", true),
              (block.colorsIcons)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.getColorsRows(block.colorsIcons.colors), (colorsRow, colorsRowIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: colorsRowIndex,
                      class: "dresscode-block-inv__block-icons-container"
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(colorsRow, (color, colorIndex) => {
                        return (_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
                          key: colorIndex,
                          class: _ctx.bem('dresscode-block-inv__block-icon', {
                  size: _ctx.getIconsSize(colorsRow),
                })
                        }, block.colorsIcons.iconStyles, {
                          style: { fill: color }
                        }), null, 16, ["class", "style"]))
                      }), 128))
                    ]))
                  }), 128))
                : _createCommentVNode("", true)
            ])), [
              [_directive_element_intersection_animation_directive, { tact: blockIndex + 2 }]
            ])
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}