
import { defineComponent, PropType } from 'vue';
import TextStyles from '@/data/types/text-styles';
import SelectOption from '@/data/types/select-option';
import Font from '@/data/types/font';
import fontsList from '@/data/constants/fonts.json';
import InputBlock from '@/blocks/input';
import SelectBlock from '@/blocks/select';
import ColorPicker from '@/blocks/color-picker';
import TextBlock from '@/blocks/text';

interface FontOption extends SelectOption {
  value: Font;
}

export default defineComponent({
  name: 'text-styles-editor',
  components: {
    InputBlock,
    SelectBlock,
    ColorPicker,
    TextBlock,
  },
  props: {
    value: Object as PropType<TextStyles>,
  },
  emits: ['update:value'],
  computed: {
    fontsOptions(): FontOption[] {
      return (fontsList as Font[]).map((fontName) => ({ label: fontName, value: fontName }));
    },
    fontWeightOptions(): SelectOption[] {
      return [
        300,
        400,
        500,
        600,
        700,
      ].map((weight) => ({ label: String(weight), value: String(weight) }));
    },
  },
  methods: {
    onInput<T extends keyof TextStyles>(code: T, value: TextStyles[T]): void {
      this.$emit('update:value', {
        ...this.value,
        [code]: value,
      });
    },
  },
});
