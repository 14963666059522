import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_block = _resolveComponent("button-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "button-block-inv",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_button_block, {
        class: "button-block-inv__button",
        styles: _ctx.computedOptions.buttonStyles,
        loading: _ctx.feedbackSending,
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["styles", "loading", "onClick"])), [
        [_directive_element_intersection_animation_directive, { tact: 1 }]
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}