
import { defineComponent, PropType } from 'vue';
import ValidationErrors from '@/blocks/validation-errors';
import ERRORS_CODES from '@/data/constants/error-codes';

export default defineComponent({
  name: 'input-holder',
  components: {
    ValidationErrors,
  },
  props: {
    errorCodes: {
      type: [Array, Set] as PropType<ERRORS_CODES[] | Set<ERRORS_CODES>>,
      default: () => [],
    },
    customErrors: {
      type: [Array, Set] as PropType<string[] | Set<string>>,
      default: () => [],
    },
    errorsPosition: {
      type: String as PropType<'absolute' | 'relative'>,
      default: 'relative',
    },
  },
  computed: {
    hasErrors(): boolean {
      return this.errorCodesArray.length > 0 || this.customErrorsArray.length > 0;
    },
    errorCodesArray(): ERRORS_CODES[] {
      return this.getArray(this.errorCodes);
    },
    customErrorsArray(): string[] {
      return this.getArray(this.customErrors);
    },
  },
  methods: {
    getArray<T>(data: T[] | Set<T>): T[] {
      if (Array.isArray(data)) {
        return data;
      }
      return Array.from(data);
    },
  },
});
