import FirstBlockBettaOptions from '@/data/e-invitations/block-options/first-block-betta';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<FirstBlockBettaOptions> = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  block1TextStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили первого текстового блока',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  block2TextStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили первого текстового блока',
  },
  withLoveTextStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили надписи "С любовью"',
  },
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
