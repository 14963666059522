
import { defineComponent } from 'vue';
import inputMixin from '@/mixins/input';

export default defineComponent({
  name: 'input-block',
  mixins: [inputMixin],
  expose: ['focus'],
  props: {
    number: Boolean,
  },
  methods: {
    onInput(value: string) {
      let newValue = value;
      if (this.number) {
        newValue = newValue.replace(/\D/g, '');
        this.$el.value = newValue;
      }
      this.$emit('update:value', newValue);
    },
  },
});
