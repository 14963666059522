import Font from '@/data/types/font';

export default function (font: Font): string {
  const fontsMap: Record<Font, { name: string, generic: 'serif' | 'sans-serif' }> = {
    'century-gothic': {
      name: 'Century Gothic',
      generic: 'sans-serif',
    },
    cormorant: {
      name: 'Cormorant',
      generic: 'serif',
    },
    gogol: {
      name: 'Gogol',
      generic: 'sans-serif',
    },
    'rosa-marena': {
      name: 'Rosa Marena',
      generic: 'sans-serif',
    },
    alsschlangesans: {
      name: 'ALSSchlangesans',
      generic: 'sans-serif',
    },
    adventure: {
      name: 'Adventure',
      generic: 'sans-serif',
    },
    butler: {
      name: 'Butler',
      generic: 'serif',
    },
    montserrat: {
      name: 'Montserrat',
      generic: 'sans-serif',
    },
    'crystal-sky': {
      name: 'Crystal Sky',
      generic: 'sans-serif',
    },
    dm: {
      name: 'DM',
      generic: 'serif',
    },
    felixty: {
      name: 'Felixty',
      generic: 'serif',
    },
    'shelley-volante': {
      name: 'Shelley Volante',
      generic: 'sans-serif',
    },
    'saint-amour': {
      name: 'Saint Amour',
      generic: 'serif',
    },
    'dance-partner': {
      name: 'Dance Partner',
      generic: 'serif',
    },
    allegretto: {
      name: 'Allegretto',
      generic: 'serif',
    },
  };
  return `${fontsMap[font].name}, ${fontsMap[font].generic}`;
}
