import FirstBlockZetaOptions from '@/data/e-invitations/block-options/first-block-zeta';

const defaultOptions: FirstBlockZetaOptions = {
  iconStyles: {
    fill: 'rgba(31, 31, 31, 1)',
    name: 'rings',
  },
  titleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  namesStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(28, 28, 28, 1)',
  },
  namesSeparatorColor: 'rgba(0, 0, 0, 1)',
  contentStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(28, 28, 28, 1)',
  },
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(32, 32, 32, 1)',
  },
  titleText: 'Приглашаем Вас на свадьбу',
  contentText: 'С радостью сообщаем, что мы собираемся пожениться. И этот важный для нас день мы хотим разделить с Вами.',
  background: {
    color: 'rgba(255, 255, 255, 1)',
  },
};

export default defaultOptions;
