import ProgramBlock3Options from '@/data/e-invitations/block-options/program-block-3';

const defaultOptions: ProgramBlock3Options = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  itemIconColor: 'rgba(31, 31, 31, 1)',
  itemTimeStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(31, 31, 31, 1)',
  },
  itemTitleStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  itemAddressStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(173, 173, 173, 1)',
  },
  separatorColor: 'rgba(0, 0, 0, 1)',
  titleText: 'Программа дня',
  items: [
    {
      icon: 'rings',
      time: '12:00',
      title: 'Регистрация брака',
      address: 'ул. Ленина, 300',
    },
    {
      icon: 'slides',
      time: '14:00',
      title: 'Фотосессия и прогулка',
      address: 'Центральный сквер',
    },
    {
      icon: 'stemware',
      time: '17:00',
      title: 'Начало банкета',
      address: 'ул. Васильева, 150',
    },
    {
      icon: 'table-clock-heart',
      time: '00:00',
      title: 'Окончание банкета',
      address: 'ул. Васильева, 150',
    },
  ],
};

export default defaultOptions;
