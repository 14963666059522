import { defineStore } from 'pinia';
import LessVars from '@/data/types/less-vars';

const useMediaQueriesStore = defineStore('media-queries', {
  state: () => ({
    lessVars: {} as LessVars,
    isMobile: false,
    isDesktop: true,
  }),
  actions: {
    setLessVars(lessVars: LessVars) {
      this.lessVars = lessVars;
      this.isMobile = window.matchMedia(lessVars.mobile).matches;
      this.isDesktop = !this.isMobile;
    },
  },
});

export default useMediaQueriesStore;
