
import { defineComponent, PropType } from 'vue';
import SvgIcon from '@/blocks/svg-icon';
import TextBlock from '@/blocks/text';
import LoaderBlock from '@/blocks/loader';
import ButtonStyles from '@/data/types/button-styles';
import SvgStyles from '@/data/types/svg-styles';
import { Color } from '@/data/e-invitations/types';

interface StylesVars {
  '--background-color'?: Color;
  '--background-color-hover'?: Color;
  '--border-color'?: Color;
  '--border-color-hover'?: Color;
  '--text-color'?: Color;
  '--text-color-hover'?: Color;
}

export default defineComponent({
  name: 'button-block',
  components: {
    SvgIcon,
    TextBlock,
    LoaderBlock,
  },
  props: {
    submit: Boolean,
    disabled: Boolean,
    loading: Boolean,
    styles: {
      type: Object as PropType<ButtonStyles>,
      default: () => ({
        textStyles: {},
      }),
    },
    icon: Object as PropType<SvgStyles>,
  },
  computed: {
    style(): StylesVars {
      const {
        backgroundColor,
        hoverBackgroundColor,
        borderColor,
        hoverBorderColor,
        textStyles,
        hoverTextColor,
      } = this.styles || {};
      const result: StylesVars = {};
      if (backgroundColor) {
        result['--background-color'] = backgroundColor;
      }
      if (hoverBackgroundColor) {
        result['--background-color-hover'] = hoverBackgroundColor;
      }
      if (borderColor) {
        result['--border-color'] = borderColor;
      }
      if (hoverBorderColor) {
        result['--border-color-hover'] = hoverBorderColor;
      }
      if (textStyles?.color) {
        result['--text-color'] = textStyles.color;
      }
      if (hoverTextColor) {
        result['--text-color-hover'] = hoverTextColor;
      }
      return result;
    },
  },
});
