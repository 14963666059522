import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "side-background-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_images_urls_editor = _resolveComponent("images-urls-editor")!
  const _component_select_block = _resolveComponent("select-block")!
  const _component_input_block = _resolveComponent("input-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_images_urls_editor, {
      class: "side-background-editor__item",
      value: _ctx.value?.imageUrls || {},
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('imageUrls', $event)))
    }, null, 8, ["value"]),
    (_ctx.value?.imageUrls)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_select_block, {
            class: "side-background-editor__item",
            options: _ctx.fullSizeOptions,
            value: _ctx.value.fullSize,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('fullSize', $event)))
          }, null, 8, ["options", "value"]),
          _createVNode(_component_input_block, {
            class: "side-background-editor__item",
            number: "",
            placeholder: "Десктоп: отступ картинки от края",
            value: String(_ctx.offset.desktop || 0),
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('offset', { mobile: _ctx.offset.mobile || 0, desktop: $event })))
          }, null, 8, ["value"]),
          _createVNode(_component_input_block, {
            class: "side-background-editor__item",
            number: "",
            placeholder: "Мобила: отступ картинки от края",
            value: String(_ctx.offset.mobile || 0),
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('offset', { mobile: $event, desktop: _ctx.offset.desktop || 0 })))
          }, null, 8, ["value"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}