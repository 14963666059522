
import { defineComponent, PropType, ComponentPublicInstance } from 'vue';
import { Names, Color } from '@/data/e-invitations/types';
import TextStyles from '@/data/types/text-styles';
import FontWeight from '@/data/types/font-weight';
import Font from '@/data/types/font';
import TextBlock from '@/blocks/text';

export default defineComponent({
  name: 'names-with-and',
  components: {
    TextBlock,
  },
  props: {
    names: {
      type: Array as unknown as PropType<Names>,
      required: true,
    },
    styles: Object as PropType<TextStyles>,
  },
  data: () => ({
    fullStringWidth: 0,
  }),
  mounted() {
    this.recalculateFullStringWidth();
    window.addEventListener('resize', this.recalculateFullStringWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.recalculateFullStringWidth);
  },
  computed: {
    fullString(): string {
      return this.names.map(({ firstName }) => firstName).join(' и ');
    },
    textBind(): {
      font: Font,
      weight: FontWeight,
      color: Color,
      size: [number, number],
      lineHeight: number,
      } {
      return {
        font: 'gogol',
        weight: 400,
        color: 'rgba(255, 255, 255, 1)',
        ...this.styles,
        size: [35, 25],
        lineHeight: 1,
      };
    },
  },
  methods: {
    recalculateFullStringWidth(): void {
      const fullStringInstance = this.$refs.fullString as ComponentPublicInstance;
      if (fullStringInstance) {
        this.fullStringWidth = fullStringInstance.$el.offsetWidth;
      }
    },
  },
});
