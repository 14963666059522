import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.bem('program-item', { align: _ctx.align }))
  }, [
    _createVNode(_component_text_block, _mergeProps(_ctx.timeStyles, {
      size: [50, 40],
      "line-height": 1
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "time")
      ]),
      _: 3
    }, 16),
    _createVNode(_component_text_block, _mergeProps({
      class: _ctx.bem('program-item__title', { bigMarginTop: _ctx.bigMarginFromTitle, marginFromAddress: _ctx.marginFromAddress })
    }, _ctx.titleStyles, { size: [20, 18] }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _: 3
    }, 16, ["class"]),
    (_ctx.$slots.address)
      ? (_openBlock(), _createBlock(_component_text_block, _mergeProps({ key: 0 }, _ctx.addressStyles, { size: [18, 14] }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "address")
          ]),
          _: 3
        }, 16))
      : _createCommentVNode("", true)
  ], 2))
}