import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ymap_marker = _resolveComponent("ymap-marker")!
  const _component_yandex_map = _resolveComponent("yandex-map")!

  return (_openBlock(), _createBlock(_component_yandex_map, {
    class: "map",
    coords: _ctx.coords,
    controls: ['geolocationControl', 'zoomControl']
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ymap_marker, {
        coords: _ctx.coords,
        "marker-id": "id"
      }, null, 8, ["coords"])
    ]),
    _: 1
  }, 8, ["coords"]))
}