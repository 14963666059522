enum ROUTER_NAMES {
  MAIN = 'stub',
  E_INVITATIONS = 'e-invitations',
  DEMO_E_INVITATIONS = 'demo-e-invitations',
  STUB = 'stub',
  INVITATIONS_FEEDBACK = 'INVITATIONS_FEEDBACK',
  INVITATIONS_CONSTRUCTOR = 'INVITATIONS_CONSTRUCTOR',
}

export default ROUTER_NAMES;
