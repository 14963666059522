import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "first-block-nu__content" }
const _hoisted_2 = { class: "first-block-nu__date-holder" }
const _hoisted_3 = { class: "first-block-nu__names-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-nu",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.computedOptions.lineColor)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.bem('first-block-nu__border', { position: _ctx.computedOptions.blockPosition })),
              style: _normalizeStyle({ borderColor: _ctx.computedOptions.lineColor })
            }, [
              _createVNode(_component_text_block, _mergeProps({ class: "first-block-nu__top-text" }, _ctx.topTextBind), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.computedOptions.topText), 1)
                ]),
                _: 1
              }, 16),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splittedDate, (dateItem, dateItemIndex) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({
                    key: dateItemIndex,
                    class: "first-block-nu__date-item"
                  }, _ctx.computedOptions.dateStyles, { size: [220, 140] }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(dateItem), 1)
                    ]),
                    _: 2
                  }, 1040)), [
                    [_directive_element_intersection_animation_directive, {
              tact: 1 + dateItemIndex,
            }]
                  ])
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_text_block, _mergeProps({ class: "first-block-nu__first-name" }, _ctx.firstNameBind), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.names[0].firstName[0]), 1)
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_text_block, _mergeProps({ class: "first-block-nu__symbol" }, _ctx.firstNameBind), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_text_block, _mergeProps({ class: "first-block-nu__first-name" }, _ctx.firstNameBind), {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.names[1].firstName[0]), 1)
                  ]),
                  _: 1
                }, 16)
              ]),
              (_ctx.computedOptions.lineColor)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.bem('first-block-nu__line', { left: true })),
                    style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.lineColor })
                  }, null, 6))
                : _createCommentVNode("", true),
              (_ctx.computedOptions.lineColor)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.bem('first-block-nu__line', { right: true })),
                    style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.lineColor })
                  }, null, 6))
                : _createCommentVNode("", true)
            ], 6))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}