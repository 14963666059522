import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_errors = _resolveComponent("validation-errors")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.bem('input-holder', {
    errorsPosition: _ctx.errorsPosition,
    error: _ctx.hasErrors,
  }))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.hasErrors)
      ? (_openBlock(), _createBlock(_component_validation_errors, {
          key: 0,
          class: _normalizeClass(_ctx.bem('input-holder__errors', { errorsPosition: _ctx.errorsPosition })),
          "error-codes": _ctx.errorCodesArray,
          "custom-errors": _ctx.customErrorsArray
        }, null, 8, ["class", "error-codes", "custom-errors"]))
      : _createCommentVNode("", true)
  ], 2))
}