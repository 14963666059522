import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropdown__content" }
const _hoisted_3 = { class: "dropdown__head" }
const _hoisted_4 = { class: "dropdown__head-content" }
const _hoisted_5 = {
  key: 0,
  class: "dropdown__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_svg_icon, {
      class: _normalizeClass(_ctx.bem('dropdown__open-button', { opened: _ctx.isOpened })),
      name: "triangle-bottom-filled",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = !_ctx.isOpened))
    }, null, 8, ["class"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "head")
        ])
      ]),
      (_ctx.isOpened)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "body")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}