import TextBlockOptions from '@/data/e-invitations/block-options/text-block';

const defaultOptions: TextBlockOptions = {
  titleStyles: {
    font: 'cormorant',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  textBlocksStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(31, 31, 31, 1)',
  },
  dateStyles: {
    font: 'cormorant',
    weight: 700,
    color: 'rgba(238, 186, 186, 1)',
  },
  contactsLabelStyles: {
    font: 'century-gothic',
    weight: 400,
    color: 'rgba(228, 176, 176, 1)',
  },
  contactsSvgColor: 'rgba(228, 176, 176, 1)',
  titleText: 'Дорогие гости!',
  blocks: [
    {
      desktopWidth: '613px',
      items: [
        {
          type: 'text',
          text: 'С радостью сообщаем, что мы собираемся пожениться. И этот важный для нас день мы хотим разделить с Вами.',
        },
      ],
    },
    {
      items: [
        {
          type: 'date',
        },
      ],
    },
    {
      desktopWidth: '495px',
      items: [
        {
          type: 'text',
          text: 'Мы будем рады, если Вы сможете присоединиться к нам!',
        },
      ],
    },
    {
      desktopWidth: '495px',
      items: [
        {
          type: 'text',
          text: 'Мы будем рады, если Вы сможете присоединиться к нам!',
        },
      ],
    },
    {
      items: [
        {
          type: 'contacts',
          contacts: [
            {
              type: 'phone',
              value: '79064643218',
              label: '+7 906 464 32 18',
            },
            {
              type: 'inst',
              value: '@dafner__',
              label: '@dafner__',
            },
          ],
        },
        {
          type: 'text',
          text: 'Организатор Алёна',
        },
      ],
    },
  ],
};

export default defaultOptions;
