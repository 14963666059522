
import { defineComponent, PropType } from 'vue';
import HistoryBlockInterfaceOptions, { ImageDesktopSize } from '@/data/e-invitations/block-options/history-block';
import defaultOptions from '@/data/e-invitations/block-default-options/history-block';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import ImgBlock from '@/blocks/img';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';

export default defineComponent({
  inheritAttrs: false,
  name: 'history-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    ImgBlock,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<HistoryBlockInterfaceOptions>,
      required: true,
    },
  },
  computed: {
    computedOptions(): HistoryBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
  },
  methods: {
    getImageStyle(size: ImageDesktopSize): null | {
      '--desktop-width': string,
      '--desktop-height': string,
    } {
      return size === 'full-width'
        ? null
        : {
          '--desktop-width': size.width,
          '--desktop-height': size.height,
        };
    },
  },
});
