import { HttpService } from '@/api/http-service';

// DTO
import type { EInvGetBySlugResponseDto } from '@/api/modules/e-inv/dto/get-by-slug/response.dto';

class EInvService extends HttpService {
  getBySlug(slug: string) {
    return this.get<EInvGetBySlugResponseDto>(`/e-inv/slug/${slug}`);
  }
}

export const eInvService = new EInvService();
