import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table__mobile-pair" }
const _hoisted_2 = { class: "table__mobile-pair-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_text_block, _mergeProps({ class: "table__mobile-pair-label" }, _ctx.styles, { color: "#A1A1A1" }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "label")
      ]),
      _: 3
    }, 16),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "value", {}, () => [
        _createVNode(_component_text_block, _mergeProps(_ctx.styles, { color: "#1F1F1F" }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "value-text")
          ]),
          _: 3
        }, 16)
      ])
    ])
  ]))
}