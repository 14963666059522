
import { defineComponent, PropType } from 'vue';
import { ImageUrls } from '@/data/e-invitations/types';
import { uniqueId } from '@/tools';
import ERRORS_CODES from '@/data/constants/error-codes';
import InputHolder from '@/blocks/input-holder';
import InputBlock from '@/blocks/input';

export default defineComponent({
  name: 'image-urls-editor',
  components: {
    InputHolder,
    InputBlock,
  },
  props: {
    value: Object as PropType<ImageUrls>,
  },
  emits: ['update:value', 'publish-methods', 'unmount'],
  expose: ['validateFields'],
  data: () => ({
    id: uniqueId(),
    errors: {} as Record<keyof ImageUrls, Set<ERRORS_CODES>>,
  }),
  created() {
    this.$emit('publish-methods', {
      id: this.id,
      validateFields: this.validateFields,
    });
  },
  beforeUnmount() {
    this.$emit('unmount', { id: this.id });
  },
  methods: {
    onInput(code: keyof ImageUrls, value: string) {
      const newValue = {
        ...this.value,
        [code]: value,
      };
      const hasAnyUrl = Object.values(newValue).some(Boolean);
      this.$emit('update:value', hasAnyUrl ? newValue : null);
    },
    setError(fieldCode: keyof ImageUrls, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        this.errors[fieldCode] = new Set<ERRORS_CODES>();
      }
      this.errors[fieldCode].add(errorCode);
    },
    removeError(fieldCode: keyof ImageUrls, errorCode: ERRORS_CODES) {
      if (!this.errors[fieldCode]) {
        return;
      }
      this.errors[fieldCode].delete(errorCode);
    },
    validateFields(): boolean {
      const { value } = this;
      if (!value) {
        return true;
      }
      const countEnteredUrls = Object.values(value).filter(Boolean).length;
      const x1UrlHasError = countEnteredUrls > 0 && !value.x1;
      if (x1UrlHasError) {
        this.setError('x1', ERRORS_CODES.REQUIRED);
      }
      return !x1UrlHasError;
    },
  },
  watch: {
    'value.x1': function handler() {
      this.removeError('x1', ERRORS_CODES.REQUIRED);
    },
  },
});
