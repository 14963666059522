import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["fill", "stroke", "stroke-width", "width", "height"]
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "svg-icon",
    fill: _ctx.fill,
    stroke: _ctx.stroke,
    "stroke-width": _ctx.strokeWidth,
    width: _ctx.size[0],
    height: _ctx.size[1]
  }, [
    _createElementVNode("use", {
      "xlink:href": `#icon-${_ctx.name}`
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}