
import { defineComponent, PropType } from 'vue';
import { mapState, mapActions } from 'pinia';
import TextBlock from '@/blocks/text';
import SvgIcon from '@/blocks/svg-icon/svg-icon.vue';
import useTimeStore from '@/store/time';
import TextStyles from '@/data/types/text-styles';
import SvgStyles from '@/data/types/svg-styles';

export default defineComponent({
  name: 'countdown-block',
  components: {
    TextBlock,
    SvgIcon,
  },
  props: {
    titleText: String,
    titleStyles: Object as PropType<TextStyles>,
    countdownUnitsStyles: Object as PropType<TextStyles>,
    countdownUnitsLabelsStyles: Object as PropType<TextStyles>,
    separatorIconStyles: Object as PropType<SvgStyles>,
    date: {
      type: String,
      required: true,
    },
    timezone: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.subscribe();
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  computed: {
    ...mapState(useTimeStore, ['currentTimestamp']),
    timeUnits() {
      const SECONDS_IN_MINUTE = 60;
      const MINUTES_IN_HOUR = 60;
      const HOURS_IN_DAY = 24;
      const dateTimestamp = new Date(this.date as unknown as Date).getTime() / 1000;
      const timeZoneOffset = this.timezone + new Date().getTimezoneOffset() / 60;
      const dateTimestampWithTimezoneOffset = dateTimestamp
        + timeZoneOffset * SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
      const difference = Math.max(dateTimestampWithTimezoneOffset - this.currentTimestamp, 0);
      const days = Math.floor(difference / (SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY));
      const hours = Math.floor(difference / (SECONDS_IN_MINUTE * MINUTES_IN_HOUR)) % HOURS_IN_DAY;
      const minutes = Math.floor(difference / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR;
      const seconds = Math.min(SECONDS_IN_MINUTE, Math.round(difference) % SECONDS_IN_MINUTE);
      return {
        days,
        hours,
        minutes,
        seconds,
      };
    },
    items() {
      const {
        days, hours, minutes, seconds,
      } = this.timeUnits;
      return [
        { value: days, unit: 'дней' },
        { value: hours, unit: 'часов' },
        { value: minutes, unit: 'минут' },
        { value: seconds, unit: 'секунд' },
      ];
    },
  },
  methods: {
    ...mapActions(useTimeStore, ['subscribe', 'unsubscribe']),
    formatValue(value: number): string {
      return String(value).length >= 2 ? String(value) : `0${value}`;
    },
  },
});
