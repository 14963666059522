
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import useMediaQueriesStore from '@/store/media-queries';
import lessVars from '@/styles/less-vars.less';

export default defineComponent({
  created() {
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    ...mapActions(useMediaQueriesStore, ['setLessVars']),
    onWindowResize() {
      // fire recalculate
      this.setLessVars(lessVars);
    },
  },
});
