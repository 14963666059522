
import { defineComponent, PropType } from 'vue';

enum FILE_EXTS {
  json = '.json',
}

export default defineComponent({
  name: 'file-input',
  props: {
    fileExts: {
      type: Array as PropType<(keyof typeof FILE_EXTS)[]>,
      default: () => [],
    },
    multiple: Boolean,
  },
  emits: ['update:value'],
  computed: {
    accept(): FILE_EXTS[] {
      return this.fileExts.map((ext) => FILE_EXTS[ext]);
    },
  },
  methods: {
    onInput() {
      const inputElement = this.$refs.input as HTMLInputElement;
      const { files } = inputElement;
      const filesArray = files ? Array.from(files) : [];
      if (this.multiple) {
        this.$emit('update:value', filesArray);
        return;
      }
      this.$emit('update:value', filesArray[0]);
    },
  },
});
