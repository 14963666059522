
import { defineComponent } from 'vue';
import SvgIcon from '@/blocks/svg-icon';

export default defineComponent({
  name: 'dropdown-block',
  components: {
    SvgIcon,
  },
  data: () => ({
    isOpened: false,
  }),
});
