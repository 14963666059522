import CountdownBlockOptions from '@/data/e-invitations/block-options/countdown-block';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<CountdownBlockOptions> = {
  titleStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили заголовка',
  },
  countdownUnitStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили цифр таймера',
  },
  countdownUnitLabelsStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили единиц времени таймера',
  },
  titleText: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст заголовка',
  },
  separatorIconStyles: {
    editorElement: EDITOR_ELEMENTS.ICON_STYLES,
    label: 'Иконка раздилителя единиц времени',
    optional: true,
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
