import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "list-editor" }
const _hoisted_2 = { class: "list-editor__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: rowIndex,
          class: "list-editor__row"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
            class: "list-editor__row-input",
            value: row,
            "onUpdate:value": ($event: any) => (_ctx.editRow(rowIndex, $event))
          }, null, 40, ["value", "onUpdate:value"])),
          _createVNode(_component_svg_icon, {
            class: "list-editor__row-cross",
            name: "cross",
            onClick: ($event: any) => (_ctx.deleteRow(rowIndex))
          }, null, 8, ["onClick"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_svg_icon, {
      class: "list-editor__add-button",
      name: "plus",
      onClick: _ctx.addRow
    }, null, 8, ["onClick"])
  ]))
}