import FirstBlockDeltaOptions from '@/data/e-invitations/block-options/first-block-delta';
import {
  EditorConfigType,
  EDITOR_ELEMENTS,
} from '@/data/e-invitations/editor-types';

const editorConfig: EditorConfigType<FirstBlockDeltaOptions> = {
  namesStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили имен',
  },
  ampersandColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет амперсанда на заднем фоне имен',
  },
  textStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили текста',
  },
  dateStyles: {
    editorElement: EDITOR_ELEMENTS.TEXT_STYLES,
    label: 'Стили даты',
  },
  lineColor: {
    editorElement: EDITOR_ELEMENTS.COLOR_PICKER,
    label: 'Цвет линии внизу',
    optional: true,
  },
  text: {
    editorElement: EDITOR_ELEMENTS.TEXTAREA,
    label: 'Текст',
    optional: true,
  },
  background: {
    editorElement: EDITOR_ELEMENTS.BACKGROUND,
    label: 'Задний фон блока',
  },
};

export default editorConfig;
