import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "icon-picker-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ICONS_NAMES, (name) => {
      return (_openBlock(), _createBlock(_component_svg_icon, {
        key: name,
        class: _normalizeClass(_ctx.bem('icon-picker-editor__item', { active: name === _ctx.value })),
        name: name,
        onClick: ($event: any) => (_ctx.onInput(name))
      }, null, 8, ["class", "name", "onClick"]))
    }), 128))
  ]))
}