export default async function exportExcel({ content, name }: {
  content: unknown[][];
  name: string;
}): Promise<void> {
  const xlsx = await import('xlsx');
  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.aoa_to_sheet(content, {});
  Object.entries(worksheet).forEach(([key, value]) => {
    if (key === '!ref') {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    value.s = { alignment: { wrapText: true } };
  });
  xlsx.utils.book_append_sheet(workbook, worksheet, name);
  xlsx.writeFile(workbook, `${name}.xlsx`);
}
