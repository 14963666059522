import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-styles-editor" }
const _hoisted_2 = { class: "text-styles-editor__settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_block = _resolveComponent("select-block")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_text_block = _resolveComponent("text-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_select_block, {
        class: "text-styles-editor__item",
        options: _ctx.fontsOptions,
        value: _ctx.value?.font,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput('font', $event)))
      }, null, 8, ["options", "value"]),
      _createVNode(_component_select_block, {
        class: "text-styles-editor__item",
        options: _ctx.fontWeightOptions,
        value: String(_ctx.value?.weight || ''),
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput('weight', Number($event))))
      }, null, 8, ["options", "value"]),
      _createVNode(_component_color_picker, {
        class: "text-styles-editor__item",
        value: _ctx.value?.color,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('color', $event)))
      }, null, 8, ["value"]),
      _createVNode(_component_input_block, {
        class: "text-styles-editor__item",
        number: "",
        placeholder: "Необязательный: Высота текста",
        value: String(_ctx.value?.lineHeight || ''),
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput('lineHeight', Number($event))))
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_text_block, _mergeProps({ class: "text-styles-editor__preview" }, _ctx.value), {
      default: _withCtx(() => [
        _createTextVNode("Превью")
      ]),
      _: 1
    }, 16)
  ]))
}