import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_loader_block = _resolveComponent("loader-block")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.bem('button', { loading: _ctx.loading })),
    style: _normalizeStyle(_ctx.style),
    type: _ctx.submit ? 'submit' : 'button',
    disabled: _ctx.disabled
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.bem('button__content', { transparent: _ctx.loading }))
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
            key: 0,
            class: "button__icon"
          }, _ctx.icon), null, 16))
        : _createCommentVNode("", true),
      _createVNode(_component_text_block, {
        tag: "span",
        class: "button__label",
        font: _ctx.styles.textStyles.font,
        weight: _ctx.styles.textStyles.weight,
        size: [18, 14]
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["font", "weight"])
    ], 2),
    _createVNode(_Transition, { name: "fade-transition" }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_loader_block, {
              key: 0,
              class: "button__loader"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 14, _hoisted_1))
}